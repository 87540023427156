import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { DynamicConfigService } from '../../services/dynamic-config.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ContextService } from 'src/app/utils/context.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent extends FieldType<FieldTypeConfig>{


  @BlockUI() blockUI!: NgBlockUI;

  constructor(private dynamicService: DynamicConfigService, public context: ContextService) { 
    super();
   }


  fileUploadFlag= false;
  uploadDisabled=true;

  uploadFile($event:any){
    this.blockUI.start("Uploading.... Please wait...");
    this.fileUploadFlag = false;
    let payload : any = {'model':this.model, 'context':this.context.claimContext };

    if($event.files.length>0){
      $event.files.forEach((file:any, i:any) => { 
         file.fileName = this.renameFileWithNumber(file.name,"_"+i+""+file.lastModified);
      });
    }

    payload[this.field.key+''] = $event.files;

    this.dynamicService.processFile(payload, this.props.fileUploadAPI,'addFile')
    .subscribe({next:response => {
      //console.log(response);
      if(!this.formControl.value){
        this.formControl.setValue(response.main_response);
      }else {
        this.formControl.setValue( [...this.formControl.value, ...response.main_response]);
      }
      this.blockUI.stop();
    }
    ,error:error=>{
      console.log(error);
      this.blockUI.stop();
    }
  })

    console.log("fileupload event", this.model);
  }

  cancel(){
    this.fileUploadFlag = false;
  }

  showFileUpload(){
    this.fileUploadFlag = true;
  }

  download(idx:number){
    this.blockUI.start("downloading.... Please wait...");
    let payload = this.model[this.field.key+''][idx];
    this.dynamicService.processFile(payload, this.props.fileUploadAPI,'viewFile')
    .subscribe({next:success =>{
      
      let url = success.main_response.attachments[0].preSignedUrl;
      console.log(url);
      window.open(url,"_blank")
      this.blockUI.stop();
    },
    error:error => {
      console.log(error);
      this.blockUI.stop();
    }
  }

    )

  }

  remove(idx:number){
    this.blockUI.start(" Please wait...");
    let payload = this.model[this.field.key+''][idx];
    this.dynamicService.processFile(payload, this.props.fileUploadAPI,'deleteFile')
    .subscribe({next:success =>{
      this.blockUI.stop();
      //TODO if the same file name is uploaded, we overwrite the file, so in that case if user uploaded same file more than once,
      //find all the files with the same file name and delete them .
      console.log(success);
      this.formControl.setValue( this.formControl.value.toSpliced(idx , 1));
    },
    error:error=>{
      console.log(error);
      this.blockUI.stop();
    }
  }
    )
  }


  formatSize(bytes:number) {
    if (bytes == 0) {
        return '0 B';
    }
    let k = 1000,
        dm = 3,
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


renameFileWithNumber(filename:string, index:any) {
  const regex = /(\.[^.]+)$/;
  const parts = filename.split(regex);
  if (parts.length === 3) {
    const newName = parts[0] + index + parts[1] + parts[2];
    return newName;
  } else if (parts.length === 2) {
    const newName = parts[0] + index + parts[1];
    return newName;
  } else {
    return filename;
  }
}



}
