
    <div class="grid " *ngIf="appData">
        <div class="col-12 blue-bar"></div>
        <div class="col-12 p-0" style="background-color: #fff;">
            <div class="logo-img">
                <img [src]="'./assets/svg/'+appData.efnolLogoSrcNm">
            </div>
        </div>
        <div class="col-12 p-0" *ngIf="appData.claimType == 'HOME'" style="background-color: #fff;">
            <p class="policyBanner pl-6 mt-3">
                Home Policy #: {{appData.policyNumber}}
            </p>
        </div>
        <div class="col-12 p-0" *ngIf="appData.claimType != 'HOME'" style="background-color: #fff;">
            <p class="policyBanner pl-6 mt-3">
                Auto Policy #: {{appData.policyNumber}}
            </p>
        </div>        
    </div>
