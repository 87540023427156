import { Injectable } from '@angular/core';
import { EMPTY, EmptyError, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataEventService {

    // public _callInfo = "callInfo";
    // public _saveDisposition = "saveDisposition";
    
    private subjectMap : Map<string,Subject<any>> = new Map();
    
    //this.dataEventService.sendData("saveCurrentDisposition", this.dataEventService._saveDisposition);
    sendData(data: any, action: string) {
        if(!this.subjectMap.get(action)){
            this.subjectMap.set(action, new Subject());
        }
        this.subjectMap.get(action)?.next(data);
    }

    //dataEventService.onData(dataEventService._callInfo).subscribe(data=>{console.log(data)});
    onData(action:string): Observable<any> {
        if(!this.subjectMap.get(action)){
            this.subjectMap.set(action, new Subject());
        }
       return this.subjectMap.get(action)?.asObservable()!;
    }
}