export const environment = {
  production: false,
  envName: 'test',
  efnolBaseUrl:'https://apiccomtest.plymouthrock.com/test/claims/op/efnol/',
  fnolBaseUrl:'https://apiccomtest.plymouthrock.com/test/claims/op/fnol/',
  profileBaseUrl:'https://apiccomtest.plymouthrock.com/test/claims/op/profile/',
  configBaseUrl: 'https://apiccomtest.plymouthrock.com/test/claims/op/dynamicUi/',
  efnol1URL: 'http://efnoltest.prcins.net:81/#/',
  KidTimeMin:60,
  IdleTimeMin:30,
  WarningTimeoutMin:2,
  GOOGLE_ANALYTICS_4_ID:	"G-603PL3XW61",
};