import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ContextService } from 'src/app/utils/context.service';
import { DynamicConfigService } from '../../services/dynamic-config.service';
import jsonata from 'jsonata';
import { from, map, mergeMap, of, toArray } from 'rxjs';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent extends FieldType<FieldTypeConfig> implements OnInit{


  constructor(private dynamicService: DynamicConfigService, public context: ContextService) { 
    super();
   }
  
  ngOnInit() {

    let payload = this.context.claimContext;

    if(this.props['buttons']){
      this.replaceLink(this.props['buttons'])
      .subscribe( buttons =>{
        this.props['buttons'] = buttons
      });
    }
    
    if(this.props['messageParts']){
      this.replaceLink(this.props['messageParts'])
      .subscribe( buttons =>{
        this.props['messageParts'] = buttons.reverse();
      });
    }

   

  }


  replaceLink(items:any){
    let payload = this.context.claimContext;
    let js = jsonata;
    return from(items).pipe(
      mergeMap( (button:any) =>{
        return (button.link && button.link.$path) ?
          from(
            js(button.link.$path).evaluate(payload)
           ).pipe(
            map(res =>{
              button.link = res ;
              return button;
            })
            
           ) : of(button);
         }
      ),
      toArray()
    );
  }

  getResolvedMessage(message:any){
    const resolved = this.parseStringWithVariables(message, this.context.claimContext);
    return resolved;
  }


  parseStringWithVariables(inputString: string, variables: { [key: string]: string }): string {
    const regex = /{{\s*([\w\s]+)\s*}}/g;
    return inputString.replace(regex, (match, variable) => variables[variable.trim()] || match);
 }


}
