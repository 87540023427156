import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent extends FieldType<FieldTypeConfig>{

  constructor(private confirmationService: ConfirmationService) { 
    super();
   }

  showInfo(event: any,title:any,message:any){
    this.confirmationService.confirm({
      target: event.target,
      key:'info',
      
      message: message,
      icon: 'pi pi-info-circle',
      acceptLabel:"ok",
      rejectVisible:false,
      //acceptButtonStyleClass:"orangebutton",
      accept: () => {
          
      }
  });
  }

  getItemSize(){
    return (Math.round(this.props['radios'].length/2) > 10 ? Math.round(this.props['radios'].length/2) : 10 );
  }


}
