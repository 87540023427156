import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-yes-no',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.scss']
})
export class YesNoComponent extends FieldType<FieldTypeConfig>{



  constructor(private confirmationService: ConfirmationService) { 
    super();
   }

  showInfo(event: any,title:any,message:any){
    this.confirmationService.confirm({
      target: event.target,
      key:'info',
      
      message: message,
      icon: 'pi pi-info-circle',
      acceptLabel:"ok",
      rejectVisible:false,
      //acceptButtonStyleClass:"orangebutton",
      accept: () => {
          
      }
  });
  }

 

}
