
    <div class="home-col home-left-col">
        <h1 class="page-title">
            Hello, we hope everyone is OK.
        </h1>
        <div class="grid  p-fluid">
            <div class="col-8 md:col-4 ">
                <label for="incidentDate" class="form-label required">Date of Incident</label>
                  <p-calendar  [(ngModel)]="incidentData.incidentDate" [showIcon]="true" id="incidentDate" inputId="icon"></p-calendar>
              </div>
              <div class="col-8 md:col-4 " >
                <label for="incidentTime" class="form-label">Time of Incident</label>
                <div class="p-inputgroup ">
                    <p-inputMask [(ngModel)]="incidentData.incidentTime" [style]="{ width: '100%' }" slotChar="HH:MM" placeholder="HH:MM" mask="99:99" name="incidentTime" id="incidentTime" ></p-inputMask>
                    <p-selectButton [options]="stateOptions" [(ngModel)]="value1" optionLabel="label" optionValue="value"></p-selectButton> 
                </div>
              </div>
              <div class=" col-12 mt-4">
                <span><button type="button" class="btn btn-outline-secondary mr-2 ">Cancel</button></span>
                <span><button type="button" class="btn btn-primary" (click)="submitData()" >Save & Continue</button></span>
              </div>
              <div class="col-12">
                <div class="mobile-message">
                    <label><b>Auto and Home Claims</b></label>
                        <div >
                            <label class="lbl-call ">
                                <a ng-show="showTelLink" class="link-success" href="tel:+1844-346-1225" >844-346-1225</a> 
                                
                            </label>
                        </div>
                    
            
                </div>
              </div>

        </div>
    </div>
    <div class="home-col home-right-col ">
        <div class="sidebar-title" >
            <span class="">Auto and Home Claims
                <div class="sidebar-box-blue">
                    <label class="lbl-call ">
                        <a ng-show="showTelLink" class="link-success" href="tel:+1844-346-1225" >844-346-1225</a> 
                        
                    </label>
                </div>
            </span>
        </div>
    </div>

