<!-- <div [class]="field.className" > -->
    <!-- This section is used in Damage Page Injury person input  -->
 <ng-container *ngIf="!props.inputType">
    <label [for]="field.props.label" class="form-label ">{{field.props.label}}</label>
    <input type="text" pInputText [placeholder]="props.placeholder" [name]="field.props.label" [formControl]="formControl" [formlyAttributes]="field" >
</ng-container>     
<!-- </div> -->

 <div class="grid mb-2" *ngIf="props.inputType">
    <div class="col-12 md:col-8  pb-0">
        <label > <strong>{{props.label}}</strong></label>
    </div>
    <div class="col-12 md:col-8 pt-0 " [ngSwitch]="props.inputType">
        <input type="text" *ngSwitchCase="'text'" pInputText [placeholder]="props.placeholder" [name]="field.props.label" [formControl]="formControl" [formlyAttributes]="field" >
        <p-inputMask *ngSwitchCase="'mask'" [mask]="''+props.mask" [placeholder]="''+props.placeholder" [name]="''+field.props.label" [formControl]="formControl" [formlyAttributes]="field"></p-inputMask>
        <p-inputNumber *ngSwitchCase="'number'" [mode]="''+props.mode" currency="USD" locale="en-US" [min]="props.min ? props.min : 0" [max]="props.max ? props.max : 999999"  [placeholder]="''+props.placeholder" [name]="''+field.props.label" [formControl]="formControl" [formlyAttributes]="field" > </p-inputNumber>
    </div>
</div> 
