import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete';
import { Component, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'claims-google-place',
  templateUrl: './google-place.component.html',
  styleUrls: ['./google-place.component.scss']
})
export class GooglePlaceComponent extends FieldType<FieldTypeConfig> {

  @ViewChild("placesRef") placesRef! : NgxGpAutocompleteDirective;

  handleAddressChange(place: google.maps.places.PlaceResult){
    console.log(place);
    console.log(this.formControl)
    console.log(this.field);
    this.model[this.field.key+''] = place.formatted_address;

    let additionalInfo:any ={};
    if(place.address_components && place.address_components.length > 0){
      place.address_components.forEach(comp =>{
        if(comp.types.includes("street_number")){
          additionalInfo["streetNumber"] = comp.long_name;
        }else if(comp.types.includes("route")){
          additionalInfo["streetName"] = comp.short_name;
        }else if(comp.types.includes("locality")){
          additionalInfo["city"] = comp.short_name;
        }else if(comp.types.includes("administrative_area_level_1")){
          additionalInfo["state"] = comp.short_name;
        }else if(comp.types.includes("country")){
          additionalInfo["country"] = comp.short_name;
        }else if(comp.types.includes("postal_code")){
          additionalInfo["zip"] = comp.short_name;
        }
      });
      additionalInfo["address1"] = additionalInfo["streetNumber"] + " " + additionalInfo["streetName"];
      additionalInfo["formatted_address"] = place.formatted_address;
      this.model[this.field.key+'_info'] = additionalInfo;
    }

    

  }
}
