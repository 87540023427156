import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot,  CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';


export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;
}


@Injectable({
  providedIn: 'root'
})

export class CanDeactivateGuard  implements CanDeactivate<CanComponentDeactivate> {
  
  canDeactivate(component: CanComponentDeactivate, 
    currentRoute: ActivatedRouteSnapshot, currentState: 
    RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

      if(nextState && (nextState.url == '/error' || nextState.url == '/wrapup' || nextState.url == '/ho-estimate')){
        return true;
      }

      return component.canDeactivate ? component.canDeactivate() : true;
      
  }
 



}
