import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContextService {

  
   claimNumber!:string;
   internalUser:boolean = false;
   #claimContext!: any;
   #policyContext!:any;
   public sessionVariable!:any;

   constructor(private cookieService: CookieService, private router:Router) { }

   public get claimContext(){
    if(!this.#claimContext){
      this.#claimContext = {};
    }
    return this.#claimContext;
   }

   public set claimContext(value:any){
    this.#claimContext = value;
   }

   public get policyContext(){
    if(!this.#policyContext){
      this.#policyContext = {};
    }
    return this.#policyContext;
   }

   public set policyContext(value:any){
    this.#policyContext = value;
   }

  isClaimSubmitted(){
    return this.#claimContext?.claimSubmitInd == "Y"
  }

}
