import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'formly-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent extends FieldType<FieldTypeConfig> {

  constructor(private confirmationService: ConfirmationService) { 
    super();
   }

   ngOnInit(): void {
    this.showConfirmation();
  }

  showConfirmation(){
    this.confirmationService.confirm({
      key: 'confirmPopup',
      message: this.props['confirmPopup'].message,
      header:this.props.label,
      acceptVisible: false,
      rejectVisible: false,
      acceptLabel:"Yes, Continue",
      acceptButtonStyleClass:"orangebutton",
     
      accept: () => {
        this.field.formControl.setValue('accept'); 
      },

      reject:()=>{
        this.field.formControl.setValue('accept'); 
      }
  })
}

}
