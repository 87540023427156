import { Component, OnInit } from '@angular/core';
import { ContextService } from 'src/app/utils/context.service';
import { DataEventService } from 'src/app/utils/data-event.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{

  constructor(private dataEvent:DataEventService, private contextService : ContextService){

  }
  
  appData?:any;

  ngOnInit(): void {
    this.dataEvent.onData('app-initialized')
    .subscribe(
      {
        next:  data=>{
          this.appData = data;
        }
      }
     
    )
  }

  

}
