import { Component, OnInit } from '@angular/core';
import { DynamicConfigService } from '../services/dynamic-config.service';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import jsonata from 'jsonata';
import { concatMap, from, map, mergeMap, of, reduce, tap } from 'rxjs';
import { ContextService } from 'src/app/utils/context.service';
import { DynamicContextService } from '../services/dynamic-context.service';
import { Router } from '@angular/router';
import { PageConfig } from '../model/AppConfig';
import { BlockUI, NgBlockUI } from 'ng-block-ui';


@Component({
  selector: 'app-dynamic-base-page',
  templateUrl: './dynamic-base-page.component.html',
  styleUrls: ['./dynamic-base-page.component.scss']
})
export class DynamicBasePageComponent implements OnInit {

  protected form = new FormGroup({});
  protected model :any = { };
  protected jsonData : any = {};
  protected pageConfig!: PageConfig ;
  protected fields!: FormlyFieldConfig[] ;
  protected options!: FormlyFormOptions;
  protected routePath!:string

  // Pre and Post Field Group are non form elements, which doesnt participate in the Form data submission, 
  // Those component are only used to view info message and links which take user to different webpage etc.

  //PostFieldGroup
  protected postForm = new FormGroup({});
  protected postModel :any = { };
  protected postFields!: FormlyFieldConfig[] ;

  @BlockUI() blockUI!: NgBlockUI;

  constructor(public dynamicService:DynamicConfigService, 
    public context: ContextService, public router: Router,
    public dynamicContext: DynamicContextService
    ){
  }

  ngOnInit(): void {

    this.routePath = this.router.url.replace(/\//g, "");
    this.jsonData = this.context.claimContext;
    if(!this.pageConfig){
      this.blockUI.start("Please wait...");
      of("initalizing").pipe(
        mergeMap(res =>{
          return this.dynamicContext.hasPageForRoute(this.routePath) ? of(this.previousPage()) : 
          this.retrievePageConfig( {destinationPageKey : this.dynamicContext.getStartingPageByRoute(this.routePath)} );
        })
      ).subscribe(
        {next:res=>{
          
          this.blockUI.stop();
        },
        error:error=>{
          console.log(error);
          this.blockUI.stop();
        }
      }
      );
    }
    
  }


  /**
   * Get Page Config to initialize formly components.
   * Page Config expects either the destinationPageKey or (sourcePageKey and sourcePageAttributesJson) as the payload.
   * @param payload 
   * @returns 
   */
  retrievePageConfig( payload:any ){
    return this.dynamicService.getPageConfig(payload)
    .pipe(

        mergeMap( res =>{
          return this.executeHideFieldsFunction(res, payload)
        }),

        map(
          res=>{

            //Setting the UniqueKey level during the Page retrieve 
            let pageStackSize = this.dynamicContext.getPageStack(this.routePath) ?
                                  this.dynamicContext.getPageStack(this.routePath).length : 0;
            if(res['props']['uniqueKey']){
              res['props']['uniqueKey'] = 'level'+(pageStackSize+1);
            }

            this.fields = [res];

            this.postFields = res['postFieldGroup'];
            this.pageConfig = res;
            this.options = this.pageConfig?.props['options'];
            this.form = new FormGroup({})
            return res;
          }
        ),
        mergeMap(res=>{
          return  this.pageConfig.props?.renderType == 'MANUAL' ? this.navigateToManualPage ():  this.retrievePageData();
        }),
        // tap(res=>{
        //   this.processPreservePreviousPath();
        // })
    )
  }

  /**
   * This function reads each hideFieldFunction String which is in JsonAta format and 
   * evaluate to extract any fields needed to be added to hideFields on the Page Config.
   * @param res 
   * @param payload 
   */
   executeHideFieldsFunction(config: any, payload:any) {

    const { hideFieldsFunction, hideFields } = config.props;
    if (!hideFieldsFunction || hideFieldsFunction.length === 0) {
      return of(config);
    }

   return from(hideFieldsFunction)
    .pipe(
      mergeMap ( (hideFunction:any) =>{
        return from(jsonata(hideFunction).evaluate(payload))
      }),
      reduce((acc: any[], res: any) => [...acc, ...res], []),
      map((res:any) =>{
        config.props.hideFields = hideFields ? [...res, ...hideFields] : res;
        return config;

      })

    );

  }

  /**
   * Implement Me - This is a placeholder to retrieve the page data,
   * @returns 
   */
  retrievePageData(){
    return of('test').pipe(
      map(res =>{
        let pageData = this.jsonData[this.pageConfig.key];
        this.model[this.pageConfig.key] = pageData ? pageData : {};
        //console.log('retrievePageData');
        return this.model;
      })
    );
  }

  /**
   * When user save&Continue to next screen and if next screen is Manual, route it to Manual page.
   * @returns 
   */
  navigateToManualPage(){
    return this.router.navigate(['/'+this.pageConfig.props.routePath]);
  }

  /**
   * On Submitting the Page, Following steps are handled.
   * 1) Check and process Unique Key constraints
   * 2) Save the Page Data 
   * 3) Navigate to Next Page, if one exist.
   * @param model 
   */
   onSubmit(model:any) {
    
    //Add Model(pageData) to JsonData( which is all page data) , so all page data are stored during save.
    this.jsonData[this.pageConfig.key] = this.model[this.pageConfig.key];
    
    this.blockUI.start("saving.. Please wait...");
     from(this.handleUniqueKeyConstraint(this.jsonData))
     .pipe(
      tap(res=>{
        this.nullifyHiddenFields();
      }),
      mergeMap(res=>{
        return this.savePage();
      })
      ,
      mergeMap(res =>{
        this.dynamicContext.pushPage(this.pageConfig, this.routePath);
        return this.nextPage()
      })
     ).subscribe({
        
        next: res=>{
          console.log('OnSubmit success', res);
          this.blockUI.stop();
        },
        error:error=>{
          console.log('OnSubmit error', error);
          this.blockUI.stop();
        }
    }
     );
  }

  /**
   * This method is responsible to save the page data based on the page config. 
   * In general it saves the Main Json, and any other additional save data
   * @returns 
   */
  savePage(){
    return this.dynamicService.saveData( this.jsonData, this.pageConfig)
    .pipe(
      tap(res =>{
        if(res['main_response']){
          this.jsonData[this.pageConfig.key]['response'] = res['main_response'];
        }
        if(res['requests']){
          this.jsonData[this.pageConfig.key]['requests'] = res['requests'];
        }
        console.log("Save Info", res);
      })
    );
  }

  /**
   * This method is resposiblie for retriving the next page config based on the current page key and data
   * @returns 
   */
  nextPage(){
    let key = this.pageConfig.key;

     let pageStack:any = this.dynamicContext.getPageStack(this.routePath)
     .map( pageCfg =>{
       return  this.jsonData[pageCfg.key];
     }).reverse();

     let payload = {
      sourcePageKey: key,
      sourcePageAttributesJson: pageStack
    }

    console.log("Stack Payload" ,payload) ; 
    return this.retrievePageConfig(payload);
  }

  /**
   * This method is responsible to navigate to previous page, by looking into pageconfig stack
   * When user save&Continue, pageconfig is added to stack and 
   * when user clicks back, pageconfig is poped out of stack and used as current pageconfig
   * Also this method resets to newly poped pageconfig fields and forms.
   * 
   */
  previousPage(){
    
    if(this.dynamicContext.hasPageForRoute(this.routePath)){
        this.pageConfig = Object.assign({} as PageConfig ,
        this.dynamicContext.popPage(this.routePath));
        this.fields  = [this.pageConfig];
        this.form = new FormGroup({})
        this.model[this.pageConfig.key] = this.jsonData[this.pageConfig.key];  

      }else {
        console.log("No previous Page");
      }
  }



  /**
   * This method is used to check if uniqueKey exist on the Page Config, 
   * If exist, then only one instance of Object can exist at a time, 
   * So removing the older type based on the unique key property and add the new one
   * @param model 
   */
  async handleUniqueKeyConstraint(data: any) {
    //Add  Commmon Data to Model
    data[this.pageConfig.key]['insertTimeStamp'] = Date.now();
    data[this.pageConfig.key]['pageKey'] = this.pageConfig.key;
    data[this.pageConfig.key]['uniqueKey'] = this.pageConfig.props['uniqueKey'];
    data[this.pageConfig.key]['preserveUniqueKeyData'] = this.pageConfig.props['preserveUniqueKeyData'];

    //Handle UniqueKey
    if(this.pageConfig.props['uniqueKey']){

      const match = this.pageConfig.props['uniqueKey'].match(/([^0-9]*)(\d+)/);
      const stringPart = match && match[1];
      let numberPart = (match && match[2]) ? parseInt(match[2], 10) : 10;
      let found = false;
      while (numberPart <= 10) {
        const deleteObjects = await jsonata('[ $keys() ~> $filter(function ($key) {  $lookup($$, $key).uniqueKey = "'+ stringPart+numberPart +'"  })  ]').evaluate(data);
        deleteObjects.forEach( (deleteObj:any) => {
          if( deleteObj != this.pageConfig.key && data[deleteObj] && !data[deleteObj]['preserveUniqueKeyData']){
            delete data[deleteObj];
            found = true;
          }
        });

        //If found, continue to delete all the other sub child levels, else skip (100)
        found?  numberPart++ : numberPart = 100;
      }
      
    }
  }





  // /**
  //  * This method is responsible to check if the Page Data needs to be preserved or cleared based on the previousPath in the Stack.
  //  * If the previousPath in the PageData matches against the latest in the Stack page key, then preserve , else clear the data 
  //  */
  // processPreservePreviousPath() {
  //   let pageData = this.model[this.pageConfig.key];
  //   if(this.pageConfig.props.preservePreviousPath 
  //       && pageData 
  //       && pageData.preservePreviousPath){
  //     let previousPage = this.dynamicContext.peekPreviousPage(this.routePath);
  //     if(previousPage?.key == pageData.preservePreviousPath || 
  //         previousPage?.props.renderType =='MANUAL'){
  //       // preserve the data

  //     }else {
  //       this.model[this.pageConfig.key] = {};
  //     }

  //   }
  // }

  // savePreservePreviousPath(){
  //   if(this.pageConfig.props.preservePreviousPath){
  //     let previousPage = this.dynamicContext.peekPreviousPage(this.routePath);
  //     this.model[this.pageConfig.key]['preservePreviousPath'] = previousPage?.key;
  //   } 
  // }

  /**
   * This method is responsible to blank out the fields in the Page, if PageConfig has included those fields in the Hidden Property 
   * Ex : efnol_ho_damagePage Hidden fields ["damageDetail"] when navigated from efnol_ho_sewer
  */
  nullifyHiddenFields(){
    if(this.pageConfig.props.hideFields && 
      this.pageConfig.props.hideFields.length > 0
      ){
        this.pageConfig.props.hideFields.forEach(field =>{
          this.jsonData[this.pageConfig.key][field] = undefined;
        })
      }
  }
  
}


