<div class="home-col home-left-col">
    <h1 class="page-title" *ngIf="wrapupHeader != 'WrapupPage'">{{wrapupHeader}}</h1>
    <form #wrapupForm="ngForm">
        <div class="grid p-fluid form-group">
            <ng-container *ngIf="wrapupNote != 'WrapupPage'">
                <div class="col-12  mt-2">
                    <label><strong innerHTML="{{wrapupNote}}"></strong></label>
                </div>
            </ng-container>
            <div class="gray-box col-12">
                <div class="flex flex-column pt-3 pb-3">
                    <label class="text-2xl"><strong>Claim Details</strong></label>
                    <label class="pt-2 pb-2"><strong>Number: </strong>{{claimNumber}}</label>
                    <label class="pt-2"><strong>Adjuster: </strong>{{adjusterName}}</label>
                    <ng-container *ngIf="adjusterEMail.length > 0">
                        <label class="link-success">{{adjusterEMail}}</label>
                    </ng-container>
                    <ng-container *ngIf="adjusterPhNum.length > 0">
                        <label class="lbl-call"><a ng-show="showTelLink" class="link-success pb-3" href="tel:+1{{adjusterPhNum}}">{{adjusterPhNum}}</a></label>
                    </ng-container>
                    <ng-container *ngIf="wrapupEserviceNote != 'WrapupPage'">
                        <label class="pt-2 pb-2" innerHTML="{{wrapupEserviceNote}}"></label>
                    </ng-container>
                    <!-- <ng-container *ngIf="wrapupTragicLossHeader != 'WrapupPage'">
                        <div class="col-12 tragic-loss-title">
                            <label class="text-1xl tragic-loss-title"><strong>{{wrapupTragicLossHeader}}</strong></label>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="wrapupTragicLossNote != 'WrapupPage'">
                        <div class="col-12" style="background-color: #fff;">
                            <label class="text-1xl"><strong>{{wrapupTragicLossNote}}</strong></label>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="wrapupTragicLossPhNum != 'WrapupPage'">
                        <div class="col-12" style="background-color: #fff; text-align: center;">
                            <label class="text-1xl" innerHTML="{{wrapupTragicLossPhNum}}"></label>
                        </div>
                    </ng-container> -->
                </div>
            </div>
            <ng-container *ngIf="wrapupWrapupWtrDmgClmDenyNote === 'WrapupPage' && wrapupSettleClaimToggle">
                <div class="flex col-12 ">
                    <div class="flex flex-column pt-3 pb-3">
                        <label class="text-2xl pt-2" *ngIf="wrapupSettleClaimHeader != 'WrapupPage'"><strong innerHTML="{{wrapupSettleClaimHeader}}"></strong></label>
                        <label class="pt-2 pb-2" *ngIf="wrapupSettleClaimNote != 'WrapupPage'" innerHTML="{{wrapupSettleClaimNote}}"></label>
                        <div class="d-grid gap-3">
                            <button type="button" class="btn btn-primary" innerHTML="{{wrapupSettleClaimButtonText}}" (click)="submitWrapupPage()"></button>
                        </div>
                        <label class="pt-2 pb-2" *ngIf="wrapupSettleClaimLaterNote != 'WrapupPage'" innerHTML="{{wrapupSettleClaimLaterNote}}"></label>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="wtrDmgClmDenyInd === 'Y' && wrapupWrapupWtrDmgClmDenyNote != 'WrapupPage'">
                <div class="col-12 ">
                    <label innerHTML="{{wrapupWrapupWtrDmgClmDenyNote}}"></label>
                </div>
            </ng-container>
        </div>
    </form>
    <div class="col-12">
        <!-- <div class="mobile-message">
            <label><b>Auto and Home Claims</b></label>
            <div>
                <label class="lbl-call">
                    <a ng-show="showTelLink" class="link-success" href="tel:+1844-346-1225" >844-346-1225</a> 
                </label>
            </div>
        </div> -->
    </div>
</div>
<div class="home-col home-right-col">
    <!-- <div class="sidebar-title" >
        <span class="">Auto and Home Claims
            <div class="sidebar-box-blue">
                <label class="lbl-call">
                    <a ng-show="showTelLink" class="link-success" href="tel:+1844-346-1225" >844-346-1225</a> 
                </label>
            </div>
        </span>
    </div> -->
</div>


<p-confirmDialog  [breakpoints]="{'2060px': '30vw', '960px': '50vw', '640px': '80vw'}" 
    key="wrapupPopup" position="center" [acceptVisible]="false" [rejectVisible]="false" >

    <ng-template pTemplate="footer">
        <p class="text-center">
        <label class="text-1xl " innerHTML="{{wrapupTragicLossPhNum}}"></label>
    </p>
    </ng-template>

</p-confirmDialog>