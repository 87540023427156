
<div class="grid pb-3">
    <div class="col-12 ">
        <label class="required"><strong>{{props.label}}</strong></label>
    </div>
    <div [class]="choice.className " class="claim-choice" *ngFor="let choice of props['choices']">
        <label class="btn btn-outline-success ">
         <input type="radio" [id]="choice.label" [name]="key+''"  [value]="choice.value" [formControl]="formControl" [formlyAttributes]="field">
            <span>{{choice.label}}</span>
        </label>


        <!-- <label class="claim-choice " >
            <input type="radio" [id]="choice.label" [name]="key+''"  [value]="choice.value" [formControl]="formControl" [formlyAttributes]="field">
            <p-button  [label]="choice.label" styleClass="p-button p-button-text p-button-raised"></p-button>
        </label>  -->

        <!-- <p-radioButton [id]="choice.label" [name]="key+''"  [value]="choice.value" [formControl]="formControl" [formlyAttributes]="field">
            <ng-template pTemplate="label">
              <i class="pi pi-plus-circle"></i> {{choice.label}}
            </ng-template>
          </p-radioButton> -->
    </div>
</div>



