<p-dialog [(visible)]="submissionToggle" [modal]="true" [closable]="false" [breakpoints]="{'960px': '60vw', '640px': '95vw'}" [style]="{'width': '55vw'}" maskStyleClass="mask-color">
    <ng-template pTemplate="header">
        <div class="flex justify-content-center w-100">
            <div class="flex align-items-center"><label *ngIf="submissionTitle != 'SubmissionTitle'" class="text-3xl"><strong>{{submissionTitle | uppercase }}</strong></label></div>
        </div>
    </ng-template>
    <form #submissionForm="ngForm">
        <div class="grid p-fluid form-group">
            <ng-container *ngIf="submissionHeader != 'SubmissionPage'">
                <div class="flex align-items-center justify-content-center pt-3 pb-3 ps-3">
                    <label class="text-2xl" innerHTML="{{submissionHeader}}"></label>
                </div>
            </ng-container>
            <ul class="ms-3">
                <ng-container *ngIf="submissionSubmitClaimNote != 'SubmissionPage'">
                    <div class="flex align-items-center">
                        <li innerHTML="{{submissionSubmitClaimNote}}"></li>
                    </div>
                </ng-container>
                <ng-container *ngIf="submissionBackNote != 'SubmissionPage'">
                    <div class="flex align-items-center">
                        <li innerHTML="{{submissionBackNote}}"></li>
                    </div>
                </ng-container>
                <ng-container *ngIf="submissionSaveClaimNote != 'SubmissionPage'">
                    <div class="flex align-items-center">
                        <li innerHTML="{{submissionSaveClaimNote}}"></li>
                    </div>
                </ng-container>
            </ul>
            <ng-container *ngIf="submissionConcernsNote != 'SubmissionPage'">
                <div class="d-grid gap-3 ps-3">
                    <label class="near-bold" innerHTML="{{submissionConcernsNote}}"></label>
                    <textarea pInputTextarea name="anyConcernsTxt" [(ngModel)]="submissionData.anyConcernsTxt" [autoResize]="true" rows="3" cols="30" maxlength="1000" placeholder="Optional"></textarea>
                </div>
            </ng-container>
            <div class="flex flex-column col-12">
                <p-divider type="solid"></p-divider>
            </div>
            <div class="d-grid gap-2 col-8 mx-auto">
                <div class="flex align-items-center justify-content-center">
                    <span><button type="button" class="btn btn-outline-secondary mr-2"(click)="goBack()">Back</button></span>
                    <span><button type="button" class="btn btn-primary" [disabled]="submissionForm.control.invalid" (click)="submitSubmissionPage()">Submit Claim</button></span>
                </div>
                <ng-container *ngIf="submissionSaveClaimNote != 'SubmissionPage'">
                    <div class="flex align-items-center justify-content-center">
                        <span><p-button label="Save Claim Without Submitting" styleClass="p-button-link" class="btn" (click)="goLogin()"></p-button></span>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</p-dialog>