import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, filter } from 'rxjs';
import { ContextService } from '../utils/context.service';



@Injectable({
  providedIn: 'root'
})
export class CanActivateGuard implements CanActivate {
  
  constructor(private _router:Router,private contextService:ContextService ) {
  }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      let result = !this.contextService.isClaimSubmitted();

      if(!result ){
        this._router.navigateByUrl("/wrapup");
      }


      // this._router.events.pipe(filter(event => event instanceof NavigationEnd))
      // .subscribe((urlAfterRedirects) => {
      //  console.log(urlAfterRedirects);
      // });

    return result;
  }
  
}
