import { AfterViewInit, Component, HostListener, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {Location} from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { DynamicConfigService } from './efnol/formly/services/dynamic-config.service';
import { DynamicContextService } from './efnol/formly/services/dynamic-context.service';
import { DataEventService } from './utils/data-event.service';
import { SessionStorageService } from 'ngx-webstorage';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ClaimCommonService } from './services/claim-common.service';
import { ContextService } from './utils/context.service';
import { environment } from 'src/environments/environment';
import { LookupService } from './services/lookup.service';
import { Subject, filter, forkJoin, fromEvent, takeUntil } from 'rxjs';

//declare let ga: any;
declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements AfterViewInit {
  title = 'efnol';
  private queryParams:any 
  public showRoute = false;
  public appRefresh = false;
  @BlockUI() blockUI!: NgBlockUI;
  private browserBackUnsub : Subject<void> = new Subject<void>();

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public location:Location, 
    private dynamicContext:DynamicContextService,
    private eventService: DataEventService,
    private lookupService: LookupService,
    private dynamicConfigService: DynamicConfigService,
    private sessionStorage : SessionStorageService,
    private contextService : ContextService,
    private claimsCommonService : ClaimCommonService,
    private cookieService: CookieService,) { 
    this.queryParams = this.router.parseUrl(this.location.path()).queryParams;


    //If ClaimId not in the query, Fetch from the Session Storage.  
    if(!this.queryParams["claimId"]){
      this.appRefresh = true;
      this.queryParams = this.sessionStorage.retrieve('query');
    }


  }
  ngAfterViewInit(): void {
    // Initalize Google analytics 
     this.initalizeGA4();
  }

  ngOnInit(): void {

    // Comment below line, its only for testing
   // this.showRoute = true;

    this.initalizeFullStory()
    this.handleBrowseBack();

    let claimId = this.queryParams["claimId"];
    let ht = this.queryParams["ht"];

    if(!ht || !claimId ){
      console.error("invalid query string");
      this.router.navigate(["/error"]);
    }else {
      this.saveSessionInfo();
      this.blockUI.start("initializing.. Please wait...");
      forkJoin([this.lookupService.getScripts('efnolrelationshiptoinsured'),
      this.dynamicConfigService.initializeApp('efnol_2.0', this.queryParams)]).subscribe( 
        {
          next : result =>{
            this.blockUI.stop();  
            this.showRoute = true;

            if(this.appRefresh)
            {
              this.claimsCommonService.renewUser().subscribe();
            }


            //publish event
            this.eventService.sendData( result[1], "app-initialized");
            this.handleNavigation(result[1]);
            this.setFullstoryUserData();

          },
          error : error => {console.error(error);
            this.showRoute = true;
            this.blockUI.stop();
          }
        }
      );
    }
 
  }
  
  // Storing Query params into SessionStorage, So it can be read again , if user refresh the page.
  saveSessionInfo() {

    this.sessionStorage.store('query', this.queryParams)
    let ht = this.queryParams["ht"];
    const kid = this.cookieService.get('kid');
    if(ht && !this.appRefresh){
      this.cookieService.set("kid",ht);
      this.cookieService.set("kidTime",Date.now().toString());
    }
  }

/**
 * This is called when a App data is fully initialized .
 * This method is responsible to navigate user to appropriate route based on the data and App config.
 * @param appData 
 */
  handleNavigation(appData:any){

    // If Claims is already submitted, navigate to wrap up page
    if(appData.claimSubmitInd == "Y"){
      this.router.navigate(['/wrapup']);
    } else 

    //Navigate the app to appropriate Starting Route & Page based on the AppConfig def
    if(this.router.url.startsWith('/claim')) {
      let startingRoute = this.dynamicContext.appConfig.startingRoute;
      this.router.navigate(['/'+startingRoute])
      console.log(appData);
   }

  }

  // Global Confirm Popup , if user leaves the site.
  @HostListener('window:beforeunload', ['$event'])
  beforeUnload($event: any) {
    //If going to Old EFNOL , then pass thru without Confirm Pop
    // Exception pages, where user can still navigate to other sites ex : wrapup page after claim submission  
    if ((<any>window)['toOldEfnol'] ||  $event.currentTarget.location.hash.indexOf('wrapup') != -1) {
    
    // For all other website navigation, show the confirm pop
    }else {
       $event.preventDefault();
      $event.returnValue  =   "You have not submitted this report to the company yet. "+
      "If you want to present a claim please complete the report or come back later to finish and submit the report.";
    }
  }

  //Full Story initialize
  initalizeFullStory() {
    if ( environment.envName === 'prod') {
      (<any>window)['_fs_debug'] = false;
      (<any>window)['_fs_host'] = 'fullstory.com';
      (<any>window)['_fs_script'] = 'edge.fullstory.com/s/fs.js';
      (<any>window)['_fs_org'] = '1DXG8';
      (<any>window)['_fs_namespace'] = 'efnol_2.0_FS';
      // @ts-expect-error
      (function(m:any,n:any,e:any,t:any,l:any,o:any,g:any,y:any){
          if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
          g=m[e]=function(a:any,b:any,s:any){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
          o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+(<any>window)['_fs_script'];
          y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
          g.identify=function(i:any,v:any,s:any){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v:any,s:any){g(l,v,s)};g.event=function(i:any,v:any,s:any){g('event',{n:i,p:v},s)};
          g.anonymize=function(){g.identify(!!0)};
          g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
          g.log = function(a:any,b:any){g("log",[a,b])};
          g.consent=function(a:any){g("consent",!arguments.length||a)};
          g.identifyAccount=function(i:any,v:any){o='account';v=v||{};v.acctId=i;g(o,v)};
          g.clearUserCookie=function(){};
          g.setVars=function(n:any, p:any){g('setVars',[n,p]);};
          g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
          if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
          g._v="1.3.0";
      })(window,document,(<any>window)['_fs_namespace'],'script','user');
    }
  }


  setFullstoryUserData(){
    if((<any>window)['efnol_2.0_FS']){
      (<any>window)['efnol_2.0_FS'].identify('efnol-2.0-' + this.contextService.claimContext?.policyNumber, {
        displayName: 'efnol-2.0-' + this.contextService.claimContext?.policyNumber
      })
    }
  }


  // Google Analytics 

  initalizeGA4(){
    gtag('config', environment.GOOGLE_ANALYTICS_4_ID);
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event : any) => {
        let data:any = this.activatedRoute?.firstChild?.data;
        gtag('event', 'page_view', {
          page_title: data?._value?.['ga4Title'],
          page_path: event.urlAfterRedirects,
          page_location: window.location.href,
          event_label : 'ClaimId:' +this.contextService?.claimContext?.claimId,
          category: event.urlAfterRedirects,
          label: 'ClaimId:' + this.contextService?.claimContext?.claimId
        })
    });

  }

  /**
   * Disabling the Browser back button and forcing user to pick the back or save&continue option.
   */
  handleBrowseBack(){
    history.pushState(null, '');
    fromEvent(window, 'popstate').pipe(
      takeUntil(this.browserBackUnsub)
    ).subscribe((res) => {
      history.pushState(null,'');
    });
  }

  ngOnDestroy(): void {
    this.browserBackUnsub.next();
    this.browserBackUnsub.complete();
  }

}
