import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CookieService } from 'ngx-cookie-service';
import { catchError, EMPTY, filter, forkJoin, from, map, merge, mergeMap, Observable, of, toArray } from 'rxjs';
import { ContextService } from 'src/app/utils/context.service';
import { ExceptionService } from 'src/app/utils/exception.service';
import { UtilService } from 'src/app/utils/util.service';
import { environment } from 'src/environments/environment';
import jsonata from 'jsonata';

import { Router } from '@angular/router';
import { Action, ActionStep, Api, AppConfig, DataStorage, FileUploadApi, Transform } from '../model/AppConfig';
import { DynamicContextService } from './dynamic-context.service';
import { SessionStorageService } from 'ngx-webstorage';


@Injectable({
  providedIn: 'root'
})
export class DynamicConfigService {


  url: any = {
    pageConfigUrl: environment.configBaseUrl + 'config/fetchDestinationPageConfig',
    appConfigUrl : environment.configBaseUrl + 'config/fetchApplicationConfig?applicationName={{appName}}',
  }

  constructor(private httpClient: HttpClient, private util: UtilService, private sessionStorage : SessionStorageService,
    private contextService : ContextService,private router: Router, private dynamicContext:DynamicContextService,
    private cookieService: CookieService,private exceptionService:ExceptionService) { }

  getPageConfig(payload:any){
    return this.invokeHTTP( payload , 
      {api : {method:'POST', baseUrl: this.url.pageConfigUrl, path:''}} as ActionStep);
  }

  getAppConfig(appName : String){

    return this.invokeHTTP( {appName} , 
      {api : {method:'GET', baseUrl: this.url.appConfigUrl, path:''}} as ActionStep);

  }

  initializeApp(appName:string, payload:any){
    return this.getAppConfig(appName)
    .pipe(
      // Save the AppConfig in PageContext
      map((appConfig:AppConfig) =>{
        this.dynamicContext.appConfig = appConfig;
        
        return appConfig;
      }),

      //Retrieve App initial Data , Page Json Data and apply data transformation by overriding some values in Json data from initial data. 
      mergeMap( (appConfig:AppConfig) =>{
        return this.retrieveAppDataToInitalize(payload, appConfig);
      }),

      //Save the Page Json Data into Session Variable
      map((appData:any) =>{
        this.contextService.claimContext= appData
        return appData;
      })

      )
  }


  /**
   * This method will refresh the application data based on the app config provided in the session.
   *  Add a logic to Save the Data as well.
   * @returns 
   */
  refreshAppContext(){
    let queryParams = this.sessionStorage.retrieve('query');
    return this.retrieveAppDataToInitalize(queryParams, this.dynamicContext.appConfig)
    .pipe(

       //Save the Page Json Data into Session Variable
       map((appData:any) =>{
        this.contextService.claimContext= appData
        return appData;
      }),

        mergeMap(appData =>{
          return this.performAction(appData, this.dynamicContext.appConfig.save);
        })

    );
  }


  saveData(payload:any, pageConfig:any){
    let dataStrorage:DataStorage = pageConfig.props["dataStorage"];
    // const main =  dataStrorage?.save?.main;
    // const additionals =  dataStrorage?.save?.additional ? dataStrorage.save?.additional : [];

    return this.performAction(this.util.cloneDeep(payload), dataStrorage?.save);

  //  // first call the  Main API to Persist Json 
  //  let mainHttp = main ? this.invokeHTTP (main.api.baseUrl+main.api.path, main.api.method, payload) : of(' No Main');

  //   // Check if any additional API needs to be called, if any then Fork the stream
  //   return mainHttp.pipe(
  //     mergeMap((res:any) =>{
  //       return from(additionals);
  //     }),
  //     map(additional =>{
  //       return this.executeActionStep(payload,additional)
  //     }),
  //     toArray(),
  //     mergeMap(arrays => arrays.length > 0 ? forkJoin(arrays ) : of('No Additional'))

  //   );
  }

  processFile(payload:any, config:any, fileAction : string){

    let action:Action = config[fileAction];
    action.main.logResponse = true; 
    switch(fileAction){
      case 'addFile' : return this.processUploadFile(payload, action);
      default : return this.performAction(payload, action);
    }
   
  }

  private processUploadFile(payload:any, action:Action){
    return this.performMainAction(payload, action).pipe(
      mergeMap((res:any)=>{
        return from(payload['main_response'])
      }),
      map((eachFile:any) =>{
        let signedAction: ActionStep = {api:<Api>{method:"PUT",baseUrl:eachFile.preSignedUrl,path:''}}
        
        return this.executeActionStep( payload.fileUpload
                                          .find((file:any)=> 
                                              file.fileName == eachFile.orginalFileName), 
                                      signedAction)
      }),
      toArray(),
      mergeMap(arrays => arrays.length > 0 ? forkJoin(arrays ) : of('No Signed URL')),
      mergeMap((res:any) =>{
        return this.performAdditionalAction(payload,action);
      })
    )
  }

/**
 * 
 * @param payload 
 * @param action 
 * @returns 
 */
  private performAction(payload:any, action:Action){

    return this.performMainAction(payload, action).pipe(
      mergeMap((res:any) =>{
        return this.performAdditionalAction(payload,action);
      })
    )

    // let obs = action.main ? this.executeActionStep(payload, action.main) : of(payload);
    // let additionals = action.additional ? action.additional : [];
    // return obs.pipe(
    //   map(res => {
    //     if(action.main.logResponse){
    //       payload['main_response'] = res;
    //     }
    //     return res;
    //   }),
    //   mergeMap((res:any) =>{
    //     return from(additionals);
    //   }),
    //   map(additional =>{
    //     return this.executeActionStep(payload,additional)
    //   }),
    //   toArray(),
    //   mergeMap(arrays => arrays.length > 0 ? forkJoin(arrays ) : of('No Additional')),
    //   map(res => {
        
    //     payload['additional_response'] = res;
    //     return payload;
    //   }),

    // );
    
  }

  private performMainAction(payload:any, action:Action){
    let obs = action.main ? this.executeActionStep(payload, action.main) : of(payload);
    return obs.pipe(
      map(res => {
        if(action.main.logResponse){
          payload['main_response'] = res;
        }
        return res;
      })
    )
  }

  private performAdditionalAction(payload:any, action:Action){
    let additionals = action.additional ? action.additional : [];
    return from(additionals).pipe(
      map(additional =>{
        return this.executeActionStep(payload,additional)
      }),
      toArray(),
      mergeMap(arrays => arrays.length > 0 ? forkJoin(arrays ) : of('No Additional')),
      map(res => {
        payload['additional_response'] = res;
        return payload;
      }),

    );
  }

  private executeActionStep(payload:any , actionStep: ActionStep){
    return actionStep?  this.invokeHTTP(payload, actionStep) : of("no Action Step");
  }

  private tranformData(inputData: any, inputTransformConfig:Transform){
      return  from(jsonata(inputTransformConfig.jsonTransConfig).evaluate(inputData));
  }

  private invokeHTTP(payload:any, actionStep: ActionStep){


    let url:any = actionStep.api.baseUrl+ actionStep.api.path;
    let method:any = actionStep.api.method;
    let preTransformConfig:any =   actionStep.preTransform
    let postTransformConfig:any = actionStep.postTransform


    //Execute Resolve URL - to replace dynamic path, query string
    //if PreTransform exist , then transform request payload as needed or use the same payload
    return forkJoin([ this.resolveURL(url, payload) , 
                      preTransformConfig ? this.tranformData(payload , preTransformConfig): of(payload)]
    ).pipe(

      // Used for Debug Logging the transfrom request.
      map(results =>{
        
        if(preTransformConfig && actionStep.logRequest){
          if(!payload['requests']){
            payload['requests'] = [];
          }
          payload['requests'].push({url:results[0], request:results[1]})
        }
        
        return results;
      }),

    // Invoke the HttpClient
      mergeMap(results =>{
        let obser:Observable<Object>;
        switch(method){
          case 'POST_FORM_DATA' : obser = this.postFormData(results[0], results[1], payload , actionStep); break;
          case 'POST' : obser = this.httpClient.post(results[0], results[1]) ; break;
          case 'PUT' : obser = this.httpClient.put(results[0], results[1]) ; break;
          case 'DELETE' : obser = this.httpClient.delete(results[0], {body:results[1]}) ; break;
          case 'GET' : obser = this.httpClient.get(results[0]) ; break;
          default : obser = this.httpClient.post(results[0], results[1]) ; break;
        }

        return obser;

      }),

      //If any Post Transform Config exist , apply before sending the response
      mergeMap(response =>{
        if(response == null || response == undefined){
          response = {};
        }
        return postTransformConfig ? this.tranformData({output:response, input:payload},postTransformConfig ) : of(response);
      }),

      catchError((error: any) => {
          return this.exceptionService.handleError(error);
       })
    );
  }

  postFormData(url: any, payload : any, orginalPayload : any, actionStep : ActionStep ){

    let formData:FormData = new FormData();
    
    if(payload['formData'] && payload['formData'].length > 0){
      payload['formData'].forEach( (item:any) =>{
        formData.append(Object.keys(item)[0],item[Object.keys(item)[0]] );
      });

      payload['formData'] = undefined;
    }

    formData.append('payload', JSON.stringify(payload));

   return this.httpClient.post(url, formData) ;
  }

  // This method is responsible to retrieve application data.
  // There are 2 possible data retriever . 1) application specific data, 2) Json data, where Page can use 
  // Once both data are retrieved, apply the transformation by overriding the Json data using application data
  private retrieveAppDataToInitalize(payload:any, appConfig:AppConfig){

    const initalizeApi = appConfig.retrieve.initalizeApi;
    const jsonStoredData = appConfig.retrieve.storedData;

    // retrieve Initalize API 
   return this.invokeHTTP( payload , {api:initalizeApi} as ActionStep)
    .pipe(
      
      //retrieve JSON Stored Data 
      mergeMap( apiResponse =>{
        return this.invokeHTTP(apiResponse, jsonStoredData)
      }),

      catchError((error: any) => {
          return this.exceptionService.handleError(error);
       })

    )

    
  }

 private resolveURL(url:string, payload:any) {
    const pattern = /{{(.*?)}}/g;
    const matches = url.match(pattern);
    if (matches) {
      return from(matches).pipe(
        mergeMap((match) => {
          return from(
            jsonata(match.substring(2, match.length - 2)).evaluate(payload)
          ).pipe(
            map((val) => {
              url = url.replace(match, val);
              return url;
            })
          );
        })
      );
    }
    return of(url);
  }


}
