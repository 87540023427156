import { ChangeDetectorRef, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { DynamicConfigService } from '../../services/dynamic-config.service';
import { ContextService } from 'src/app/utils/context.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-multi-checkbox',
  templateUrl: './multi-checkbox.component.html',
  styleUrls: ['./multi-checkbox.component.scss']
})
export class MultiCheckboxComponent extends FieldType<FieldTypeConfig>{



  constructor(private dynamicService: DynamicConfigService, public context: ContextService,private cdr: ChangeDetectorRef) { 
    super();

   }

   ngOnInit() {

    let additionalProps = this.field?.parent?.props?.['fieldGroup']?.[''+this.key];
    if(additionalProps && additionalProps.hideFields){
    
      this.props['multiCheckbox'] = this.props['multiCheckbox'].filter( (checkbox:any) => 
          checkbox.value && !additionalProps.hideFields.includes(checkbox.value)
      );
    
    }


    if( this.field.parent?.formControl instanceof FormGroup &&
      !(this.field.parent?.formControl)?.controls?.[this.key+'']){
       this.field.parent?.formControl.addControl(this.key+'', this.formControl);
   }

   }

}
