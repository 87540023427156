import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { ContextService } from 'src/app/utils/context.service';
import { DataEventService } from 'src/app/utils/data-event.service';
import { ClaimCommonService } from 'src/app/services/claim-common.service';
import { DynamicConfigService } from '../../formly/services/dynamic-config.service';
import { map, mergeMap } from 'rxjs';
import { DynamicContextService } from '../../formly/services/dynamic-context.service';
import { AppConfig } from '../../formly/model/AppConfig';

@Component({
  selector: 'app-claim-initialize',
  templateUrl: './claim-initialize.component.html',
  styleUrls: ['./claim-initialize.component.scss']
})
export class ClaimInitializeComponent {



  constructor(private router: Router,
    private eventService: DataEventService,
    private dynamicContext:DynamicContextService,
  ) { 
    
  }

  ngOnInit(): void {
    let startingRoute = this.dynamicContext.appConfig.startingRoute;
    this.router.navigate(['/'+startingRoute])

  }

}
