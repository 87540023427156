import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SessionStorageService } from 'ngx-webstorage';
import { catchError, map, mergeMap, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ContextService } from '../utils/context.service';
import { ExceptionService } from '../utils/exception.service';
import { UtilService } from '../utils/util.service';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  url: any = {
    lookupUrl: environment.efnolBaseUrl + 'lookup/',
    scriptsUrl: environment.efnolBaseUrl + 'scripts/',
  }

  constructor(private sessionStorage : SessionStorageService,private httpClient: HttpClient, private util: UtilService, private contextService : ContextService,
    private cookieService: CookieService,private exceptionService:ExceptionService) { }

  getLookupByType(lookupType:string) : Observable<any>{
    return this.getPostCacheOrServiceData( 
      'get', 
      lookupType, 
      this.url.lookupUrl + lookupType
    );
  }

  getScripts(scriptName:any, companyCode?:any, promptId?:any, polStCd?:any) : Observable<any>{
    //getScripts(scriptName:string, companyCode?:string, promptId?:string, polStCd?:string) : any{
    //return this.getPostCacheOrServiceData(
    //  'get', 
    //  'scripts',
    //  this.url.scriptsUrl
    //);
    let byNameAndCompany: boolean = (companyCode) ? true : false;
    let byNameAndCompanyAndPrompt: boolean  = (companyCode && promptId) ? true : false;
    let byNameAndPrompt: boolean = (promptId) ? true : false;
    let byName: boolean = (byNameAndPrompt || byNameAndCompanyAndPrompt || byNameAndCompany) ? false: true;
    let byPolStCd: boolean = (polStCd) ? true : false;

    return this.getPostCacheOrServiceData(
      'get', 
      'scripts',
      this.url.scriptsUrl
    ).pipe(
      map(response => {
        let script = response.scriptList.filter(function(script: any){
          if(byNameAndCompanyAndPrompt){
            if(byPolStCd){
              return scriptName == script.scriptName
                && companyCode == script.companyCode
                && promptId == script.promptId
                && (polStCd == script.polStCd || script.polStCd=="LL")
            }else{
              return scriptName == script.scriptName
              && companyCode == script.companyCode
              && promptId == script.promptId
            }
          }else if (byNameAndCompany){
            if(byPolStCd){
              return scriptName == script.scriptName
              && companyCode == script.companyCode
              && (polStCd == script.polStCd || script.polStCd=="LL")
            }else{
              return scriptName == script.scriptName
              && companyCode == script.companyCode
            }
          }else if(byNameAndPrompt){
            if(byPolStCd){
              return scriptName == script.scriptName
              && promptId == script.promptId
              && (polStCd == script.polStCd || script.polStCd=="LL")
            }else{
              return scriptName == script.scriptName
              && promptId == script.promptId
            }
          }else if(byName){
            if(byPolStCd){
              return scriptName == script.scriptName
              && (polStCd == script.polStCd || script.polStCd=="LL")
            }else{
              return scriptName == script.scriptName
            }
          }
          return
        })[0]
        if(script != undefined){
          return script.scriptText;
        }else{
          return scriptName;
        }
      })
    );
  }

  getPostCacheOrServiceData(method : string , cacheKey: any , url : string , payload?:any ){
 
    let obser = 'post' == method ? this.httpClient.post(url, payload) 
                                  : this.httpClient.get(url);
    obser = obser.pipe(
      tap(res => {
        this.sessionStorage.store(cacheKey, res);
        return res;
      }),
      catchError((error: any) => {
        this.sessionStorage.clear(cacheKey);
        return this.exceptionService.handleError(error);
      })
    );

    return of(this.sessionStorage.retrieve(cacheKey)).pipe(
      mergeMap(val => {
        return val == null ?  obser : of(val) ;
      })
    )
  }
}