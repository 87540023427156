import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';
import { ClaimCommonService } from 'src/app/services/claim-common.service';
import { LookupService } from 'src/app/services/lookup.service';
import { ContextService } from 'src/app/utils/context.service';
import { environment } from 'src/environments/environment';
import { DynamicConfigService } from '../../formly/services/dynamic-config.service';
import { mergeMap } from 'rxjs';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-submission',
  templateUrl: './submission.component.html',
  styleUrls: ['./submission.component.scss'],
  encapsulation : ViewEncapsulation.None
})

export class SubmissionComponent implements OnInit{
  @BlockUI() blockUI!: NgBlockUI;
  
  submissionTitle: string = "SubmissionTitle"; // Submission Title
  submissionHeader: string = "SubmissionPage"; // Submission Page Header
  submissionSubmitClaimNote: string = "SubmissionPage"; // Submission Page Submit Claim Button Note
  submissionNotificationNote: string = "SubmissionPage"; // Submission Page Notification Email Note
  submissionBackNote: string = "SubmissionPage"; // Submission Page Back Button Note
  submissionSaveClaimNote: string = "SubmissionPage"; // Submission Page Save Claim Without Submitting Note
  submissionConcernsNote: string = "SubmissionPage"; // Submission Page Any Concerns Note
  
  claimSourceCd: string = ""; // Claim Source Code
  notificationOptInInd: string = ""; // Notification Opt In Indicator
  notificationEmail: string = ""; // Notification Email

  submissionToggle: boolean = true;

  submissionData : {
    anyConcernsTxt?: string, // Any Concerns Text
  } = {};

  constructor(private lookupService: LookupService, 
    private claimCommonService: ClaimCommonService, 
    private contextService: ContextService, private dynamicService : DynamicConfigService,
    private router: Router,
    private sessionStorage: SessionStorageService){ }

  ngOnInit(): void {
    this.submissionToggle = true;
    const claimId = this.contextService.claimContext.claimId;
    console.log("SubmissionComponent: claimId="+claimId);

    if(claimId){
      this.blockUI.start("Please wait...");

      this.claimSourceCd = this.contextService.claimContext.claimSourceCd;
      console.log("SubmissionComponent: claimSourceCd="+this.claimSourceCd);
  
      this.claimCommonService.getSubmissionPageData(claimId).subscribe({
        next: (submissionPage: any) => {
           if(submissionPage){
            // Save Preference Page data for Submit processing
            this.contextService.claimContext.submissionPage = submissionPage;
            // Get Any Concerns Text
            this.submissionData.anyConcernsTxt = submissionPage.anyConcernsTxt;
            // Get Email Contact for Scripts
            var contactParty: any;
            if(this.claimSourceCd === "AI"){
              // Use Primary Named Insured Party
              if(submissionPage.namedInsuredParty){
                contactParty = submissionPage.namedInsuredParty;
              }
            }else{
              // User Caller Party
              if(submissionPage.submitterParty){
                contactParty = submissionPage.submitterParty;
              }
            }
            if(contactParty){ // Contact Party
              if(contactParty.partyDetailList.length > 0){
                this.notificationOptInInd = contactParty.partyDetailList[0].notificationOptInInd;
              }
              if(contactParty.partyContactList.length > 0){
                var contact = contactParty.partyContactList.filter(
                  (contact: any) => contact.contactTypeCd === "prsn_email"
                )[0];
                if(contact){
                  this.notificationEmail = contact.contactTxt;
                }
              }
            }
          }
          this.lookupService.getScripts("SubmissionPage", this.contextService.claimContext.companyCd, 3).subscribe(response => {
            if(response != "SubmissionPage" && this.notificationEmail.length > 0){
              this.submissionNotificationNote = response.replace("{{notificationEmail}}",this.notificationEmail);
            }else{
              this.submissionNotificationNote = "";
            }
            this.lookupService.getScripts("SubmissionPage", this.contextService.claimContext.companyCd, 2).subscribe(response => {
              this.submissionSubmitClaimNote = response.replace("{{submissionNotificationNote}}",this.submissionNotificationNote);
            });
          });
          this.blockUI.stop();
        },
        error: err => {
          console.log("claimCommonService.getSubmissionPageData failed", err);
          this.blockUI.stop();
          this.router.navigate(["/error"]);
        }
      });
      //* Submission Title Script
      this.lookupService.getScripts("SubmissionTitle", this.contextService.claimContext.companyCd, 1).subscribe(response => {
        this.submissionTitle = response;
      });
      //* Submission Page Scripts
      this.lookupService.getScripts("SubmissionPage", this.contextService.claimContext.companyCd, 1).subscribe(response => {
        this.submissionHeader = response;
      });
      this.lookupService.getScripts("SubmissionPage", this.contextService.claimContext.companyCd, 4).subscribe(response => {
        this.submissionBackNote = response;
      });
      this.lookupService.getScripts("SubmissionPage", this.contextService.claimContext.companyCd, 5).subscribe(response => {
        this.submissionSaveClaimNote = response;
      });
      this.lookupService.getScripts("SubmissionPage", this.contextService.claimContext.companyCd, 6).subscribe(response => {
        this.submissionConcernsNote = response;
      });
    }
  }

  submitSubmissionPage(){
    this.blockUI.start("Please wait...");
    console.log("SubmissionComponent: submissionData="+JSON.stringify(this.submissionData));

    let query = this.sessionStorage.retrieve("query");
    console.log("SubmissionComponent: query="+JSON.stringify(query));
  
    const submitSubmissionPage: any = {};
    submitSubmissionPage.claimId = this.contextService.claimContext.claimId;
    submitSubmissionPage.anyConcernsTxt = this.submissionData.anyConcernsTxt;
    submitSubmissionPage.uid = query.uid;

    // Save Submission Page
    console.log("SubmissionComponent: submitSubmissionPage="+JSON.stringify(submitSubmissionPage));

    //Adding a Logic to Refresh the Dynamic Data and store the results.
    this.claimCommonService.saveSubmissionPageData(submitSubmissionPage).pipe(
      mergeMap(appData =>{
          return  this.dynamicService.refreshAppContext() ;
      })
    )
    .subscribe({
      next: () => {
        this.blockUI.stop();
        this.router.navigate(['/wrapup']);
      },
      error: err => {
        console.log("claimCommonService.saveSubmissionData failed", err);
        this.blockUI.stop();
        //this.router.navigate(["/error"]);
      }
    });
  }

  goBack(){
    if(this.contextService.claimContext.internalUser){
      this.router.navigate(['/assignment']);
    }else{
      this.router.navigate(['/preference']);
    }
    
  }

  goLogin(){
    (<any>window)['toOldEfnol'] = true;
    window.location.href = (environment.efnol1URL+'login');
  }
}