import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';
import { filter, Observable } from 'rxjs';
import { ClaimCommonService } from 'src/app/services/claim-common.service';
import { LookupService } from 'src/app/services/lookup.service';
import { ContextService } from 'src/app/utils/context.service';

@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.scss']
})

export class PreferenceComponent implements OnInit{
  @BlockUI() blockUI!: NgBlockUI;

  // Yes/No Radio Button Options
  optionsYesNo = [{label: 'Yes', value: 'Y'}, {label: 'No', value: 'N'}];
  
  preferenceTitle: string = "PreferenceTitle"; // Customer Preferences Title
  notificationOptInHeader: string = "PreferenceNotification"; // Notification Opt In Header
  notificationOptInQuestion: string = "PreferenceNotification"; // Notification Opt In Question
  notificationOptInTypeQuestion: string = "PreferenceNotification"; // Notification Opt In Type Question
  notificationOptInTypeList: any; // Notification Opt In Type List
  paymentOptInHeader: string = "PreferencePayment"; // Payment Opt In Header
  paymentOptInQuestion: string = "PreferencePayment"; // Payment Opt In Question
  paymentOptInNotes: string = "PreferencePayment"; // Payment Opt In Notes
  contactHeader: string = "PreferenceContact"; // Contact Information Header
  contactNotes: string = "PreferenceContact"; // Contact Information Notes
  contactAgentNotes: string = "PreferenceContact"; // Agent Contact Information Notes
  notificationOptInAgentQuestion: string = "PreferenceNotification"; // Agent Notification Opt In Question
  structuralOptInHeader: string = "PreferenceStructural"; // Structural Claim Opt In Header
  structuralOptInQuestion: string = "PreferenceStructural"; // Structural Claim Opt In Question
  estDmdContrOptInHeader: string = "PreferenceContractor" // Estimate Demand Contractor Opt In Header
  estDmdContrOptInQuestion: string = "PreferenceContractor" // Estimate Demand Contractor Opt In Question

  notificationOptInTypeToggle: boolean = false; // Toggle to Show/Hide Notification Type Question
  paymentOptInTypeAchToggle: boolean = false; // Toggle to Show/Hide Electronic Transfer Account List
  paymentOptInTypeAchAddNewToggle: boolean = false; // Toggle to Show/Hide Electronic Transfer Add New Account
  paymentOptInTypeAchUseExistingToggle: boolean = false; // Toggle to Show/Hide Electronic Transfer Use Existing Account
  paymentOptInTypeZelleToggle: boolean = false; // Toggle to Show/Hide Zelle Account List
  paymentOptInTypeZelleAddNewToggle: boolean = false; // Toggle to Show/Hide Electronic Transfer Add New Account
  paymentOptInTypeZelleUseExistingToggle: boolean = false; // Toggle to Show/Hide Electronic Transfer Use Existing Account
  contactHomePhNumToggle: boolean = false; // Toggle to Show/Hide Contact Home Phone Number
  contactWorkPhNumToggle: boolean = false; // Toggle to Show/Hide Contact Work Phone Number
  estDmdContrOptInToggle: boolean = false; // Toggle to Show/Hide Estimate Demand Contractor Contact Information
  
  claimSourceCd: string = ""; // Claim Source Code
  insCallerInd: string = "N"; // Is Insured Caller Indicator
  otherCallerInd: boolean = false; // Other 3rd party caller excluding insured, family and IA source
  showPaymentPreferences: boolean = false; // Show Payment Preferences
  achAccountList: any; // Electronic Transfer Account List
  achAccountDropDownList: any; // Electronic Transfer Account Drop Down List
  zelleAccountList: any; // Zelle Account List
  zelleAccountDropDownList: any; // Zelle Account Drop Down List
  isEserviceUser: boolean = false; // Is eService User
  waterMitigationEligibleInd: string = ""; // Water Mitigation Claim Eligible Indicator
  structuralEligibleInd: string = ""; // Structural Claim Opt-In/Opt-Out Eligible (Interior/Exterior Vendor) Indicator
  estimateDemandEligibleInd: string = ""; // Estimate Demand Eligible Indicator
  advancePaymentEligibleInd: string=""; // Advance Payment Eligible Indicator

  achAccountNumberConfirm?: any; // Electronic Transfer Confirm Account # (eService)
  achRoutingNumberConfirm?: any; // Electronic Transfer Confirm Routing # (eService)
  zelleEmailConfirm?: any; // Zelle Confirm Email (eService)
  zellePhoneNumberConfirm?: any; // Zelle Confirm Phone Number (eService)

  preferenceData : {
    notificationOptInInd?: string, // Notification Opt In Indicator
    notificationTypeCd?: string, // Nofication Type Code
    contactFirstName?: string, // Contact First Name
    contactLastName?: string, // Contact Last Name
    contactEMail?: string, // Contact Email Address
    contactCellPhNum?: string, // Contact Cell Phone Number
    contactHomePhNum?: string, // Contact Home Phone Number
    contactWorkPhNum?: string, // Contact Work Phone Number
    agentFirstName?: string, // Agent First Name
    agentLastName?: string, // Agent Last Name
    agentEMail?: string, // Agent Email Address
    agentWorkPhNum?: string, // Agent Work Phone Number
    paymentTypeCd?: string, // Payment Type Code
    achAccount?: any, // Electronic Transfer Account
    achAccountAddedInd?: any, // Electronic Transfer Account Added Indicator (eService)
    achAccountType?: any, // Electronic Transfer Account Type (eService)
    achAccountNumber?: any, // Electronic Transfer Account # (eService)
    achRoutingNumber?: any, // Electronic Transfer Routing # (eService)
    zelleAccount?: any, // Zelle Account
    zelleAccountAddedInd?: any, // Zelle Account Added Indicator (eService)
    zelleAccountType?: any, // Zelle Account Type (eService)
    zelleEmail?: any, // Zelle Email (eService)
    zellePhoneNumber?: any, // Zelle Phone Number (eService)
    structuralOptInInd?: any, // Structural Claim Opt In Indicator
    estDmdContrOptInInd?: string, // Estimate Demand Contractor Opt In Indicator
    estDmdContrNm?: string, // Estimate Demand Contractor Name
    estDmdContrEMail?: string,  // Estimate Demand Contractor Email Address
  } = {};

  constructor(private lookupService: LookupService, 
    private claimCommonService: ClaimCommonService, 
    private contextService: ContextService,
    private router: Router){ }

  ngOnInit(): void {
    const claimId = this.contextService.claimContext.claimId;
    console.log("PreferenceComponent: claimId="+claimId);

    if(claimId){
      this.blockUI.start("Please wait...");

      this.claimSourceCd =  this.contextService.claimContext.claimSourceCd;
      console.log("PreferenceComponent: claimSourceCd="+this.claimSourceCd);
      this.insCallerInd = this.contextService.claimContext.insCallerInd;
      this.otherCallerInd = this.insCallerInd == 'N' && (this.contextService.claimContext.relTypeCd && this.contextService.claimContext.relTypeCd != null);
      console.log("PreferenceComponent: otherCallerInd="+this.otherCallerInd);

      this.claimCommonService.getPreferencePageData(claimId).subscribe({
        next: (preferencePage: any) => {
          // Save Preference Page data for Submit processing
          this.contextService.claimContext.preferencePage = preferencePage;
          // Get Party for Notification Settings & Contact Information
          if(preferencePage){
            if(preferencePage.parties){
              // Get Party Information
              let contactParty = preferencePage.parties
                .filter((party: any) => party.partyTypeCd === (this.claimSourceCd === "AI"?"PRINMINS":"CALLER"))[0];
              let insuredParty = preferencePage.parties
                .filter((party: any) => party.partyTypeCd === "PRINMINS")[0];
              let callerParty = preferencePage.parties
                .filter((party: any) => party.partyTypeCd === "CALLER")[0];
              
              if(contactParty){ // Contact Party
                if(contactParty.partyFirstName.toLocaleLowerCase( ) === "efnol"){
                  if(contactParty.partyLastName.toLocaleLowerCase( ) === "insured"){
                    this.preferenceData.contactFirstName = insuredParty.partyFirstName;
                    this.preferenceData.contactLastName = insuredParty.partyLastName;
                  }
                }else{
                  this.preferenceData.contactFirstName = contactParty.partyFirstName;
                  this.preferenceData.contactLastName = contactParty.partyLastName;
                }
                if(contactParty.partyDetailList.length > 0){
                  this.preferenceData.notificationOptInInd = contactParty.partyDetailList[0].notificationOptInInd;
                  this.preferenceData.notificationTypeCd = contactParty.partyDetailList[0].notificationTypeCd;
                  this.toggleNotificationOptInType(this.preferenceData.notificationOptInInd);
                }
                if(contactParty.partyContactList.length > 0){
                  var contact = contactParty.partyContactList.filter(
                    (contact: any) => contact.contactTypeCd === "prsn_email"
                  )[0];
                  if(contact){
                    this.preferenceData.contactEMail = contact.contactTxt;
                  }else if(contactParty.partyLastName.toLocaleLowerCase( ) === "insured"){
                    var contact = insuredParty.partyContactList.filter(
                      (contact: any) => contact.contactTypeCd === "prsn_email"
                    )[0];
                    if(contact){
                      this.preferenceData.contactEMail = contact.contactTxt;
                    }
                  }
                  var contact = contactParty.partyContactList.filter(
                    (contact: any) => contact.contactTypeCd === "cell_phn"
                  )[0];
                  if(contact){
                    this.preferenceData.contactCellPhNum = contact.contactTxt;
                  }else if(contactParty.partyLastName.toLocaleLowerCase( ) === "insured"){
                    var contact = insuredParty.partyContactList.filter(
                      (contact: any) => contact.contactTypeCd === "cell_phn"
                    )[0];
                    if(contact){
                      this.preferenceData.contactCellPhNum = contact.contactTxt;
                    }
                  }
                  var contact = contactParty.partyContactList.filter(
                    (contact: any) => contact.contactTypeCd === "hm_phn"
                  )[0];
                  if(contact){
                    this.preferenceData.contactHomePhNum = contact.contactTxt;
                    this.toggleContactPhNum("hm_phn");
                  }else if(contactParty.partyLastName.toLocaleLowerCase( ) === "insured"){
                    var contact = insuredParty.partyContactList.filter(
                      (contact: any) => contact.contactTypeCd === "hm_phn"
                    )[0];
                    if(contact){
                      this.preferenceData.contactHomePhNum = contact.contactTxt;
                      this.toggleContactPhNum("hm_phn");
                    }
                  }
                  var contact = contactParty.partyContactList.filter(
                    (contact: any) => contact.contactTypeCd === "busn_phn"
                  )[0];
                  if(contact){
                    this.preferenceData.contactWorkPhNum = contact.contactTxt;
                    this.toggleContactPhNum("busn_phn");
                  }else if(contactParty.partyLastName.toLocaleLowerCase( ) === "insured"){
                    var contact = insuredParty.partyContactList.filter(
                      (contact: any) => contact.contactTypeCd === "busn_phn"
                    )[0];
                    if(contact){
                      this.preferenceData.contactWorkPhNum = contact.contactTxt;
                      this.toggleContactPhNum("busn_phn");
                    }
                  }
                }
              }
              if(callerParty && this.claimSourceCd === "AI"){
                if(callerParty.partyFirstName.toLocaleLowerCase( ) != "efnol"){
                  this.preferenceData.agentFirstName = callerParty.partyFirstName;
                  this.preferenceData.agentLastName = callerParty.partyLastName;
                }
                if(callerParty.partyDetailList.length > 0){
                  this.preferenceData.notificationOptInInd = callerParty.partyDetailList[0].notificationOptInInd;
                  if(this.preferenceData.notificationOptInInd == undefined){
                    this.preferenceData.notificationOptInInd = "N";
                  }
                }
                if(callerParty.partyContactList.length > 0){
                  var contact = callerParty.partyContactList.filter(
                    (contact: any) => contact.contactTypeCd === "prsn_email"
                  )[0];
                  if(contact) this.preferenceData.agentEMail = contact.contactTxt;
                  var contact = callerParty.partyContactList.filter(
                    (contact: any) => contact.contactTypeCd === "busn_phn"
                  )[0];
                  if(contact){
                    this.preferenceData.agentWorkPhNum = contact.contactTxt;
                  }
                }
              }
            }
            // Get Payment Information
            let paymentDetails = preferencePage.paymentDetails;
            if(paymentDetails != undefined){
              this.isEserviceUser =  paymentDetails.eserviceUser;
              // Rules to Show Payment Preferences
              // 1) Insured is Caller
              // 2) Electronic Payments are Active
              // 3) Claim Not Eligible for Automated Payments
              // 4) Banking Information Exists for Policy OR eService User is True
              if(this.insCallerInd === "Y" && paymentDetails.ruleStatus === "active" && paymentDetails.automatedPayment === "N"
              && (paymentDetails.bankingInfoList != null || paymentDetails.eserviceUser === "true")){
                  this.showPaymentPreferences = true; // Show Payment Preferences
              }
              if(paymentDetails.paymentPreference != null){
                this.preferenceData.paymentTypeCd = paymentDetails.paymentPreference.paymentType;
                if(this.preferenceData.paymentTypeCd === "ex_ach" || this.preferenceData.paymentTypeCd === "zelle"){
                  this.togglePaymentOptInType(this.preferenceData.paymentTypeCd);
                }
              }
              if(paymentDetails.bankingInfoList != null){
                this.achAccountList = paymentDetails.bankingInfoList.filter(
                  (bank: any) =>
                    bank.accountType === "checking" || bank.accountType === "saving" || bank.accountType === "ach"
                );
                console.log("PreferenceComponent: achAccountList="+JSON.stringify(this.achAccountList));
                this.achAccountDropDownList = this.achAccountList.map(
                  (bank: any) => {
                    return {code: bank.accountType, name: bank.accountNumber}
                  }
                );
                console.log("PreferenceComponent: achAccountDropDownList="+JSON.stringify(this.achAccountDropDownList));
                // Set Default For New/Existing Account
                if(this.isEserviceUser){
                  if(this.achAccountDropDownList.length > 0){
                    this.preferenceData.achAccountAddedInd = "N";
                  }else{
                    this.preferenceData.achAccountAddedInd = "Y";
                  }
                }
                this.zelleAccountList = paymentDetails.bankingInfoList.filter(
                  (bank: any) =>
                    bank.accountType === "email" || bank.accountType === "phone" || bank.accountType === "zelle"
                );
                console.log("PreferenceComponent: zelleAccountList="+JSON.stringify(this.zelleAccountList));
                this.zelleAccountDropDownList = this.zelleAccountList.map(
                  (bank: any) => {
                    return {code: bank.accountType, name: bank.accountNumber}
                  }
                );
                console.log("PreferenceComponent: zelleAccountDropDownList="+JSON.stringify(this.zelleAccountDropDownList));
                if(this.isEserviceUser){
                  if(this.zelleAccountDropDownList.length > 0){
                    this.preferenceData.zelleAccountAddedInd = "N";
                  }else{
                    this.preferenceData.zelleAccountAddedInd = "Y";
                  }
                }
              }
            }
            // Get Home Automation Information
            let homeAutomate = preferencePage.homeAutomate;
            if(homeAutomate != undefined){
              // Get Water Mitigation Eligible Indicator
              this.waterMitigationEligibleInd = homeAutomate.waterMitigationEligibleInd;
              // Get Structural Claim Eligible Indicator
              this.structuralEligibleInd = homeAutomate.structuralEligibleInd;
              // Get Estimate Demand Eligible Indicator
              this.estimateDemandEligibleInd = homeAutomate.estimateDemandEligibleInd;
              // Get Advance Payment Eligible Indicator
              this.advancePaymentEligibleInd = homeAutomate.advancePaymentEligibleInd;
            }
            if(this.structuralEligibleInd === "Y"){
              this.lookupService.getScripts("PreferenceStructural", this.contextService.claimContext.companyCd, 1).subscribe(response => {
                this.structuralOptInHeader = response;
              });
              this.lookupService.getScripts("PreferenceStructural", this.contextService.claimContext.companyCd, 2).subscribe(response => {
                this.structuralOptInQuestion = response;
              });
            }
            if(this.estimateDemandEligibleInd === "Y"){
              this.preferenceData.structuralOptInInd = homeAutomate.structuralOptInInd;
              this.preferenceData.estDmdContrOptInInd = homeAutomate.estimateDemandContractorOptInInd;
              this.preferenceData.estDmdContrNm = homeAutomate.estimateDemandContractorName;
              this.preferenceData.estDmdContrEMail = homeAutomate.estimateDemandContractorEmail;
              this.lookupService.getScripts("PreferenceContractor", this.contextService.claimContext.companyCd, 1).subscribe(response => {
                this.estDmdContrOptInHeader = response;
              });
              this.lookupService.getScripts("PreferenceContractor", this.contextService.claimContext.companyCd, 2).subscribe(response => {
                this.estDmdContrOptInQuestion = response;
              });
              this.toggleEstimateDemandContractor(this.preferenceData.estDmdContrOptInInd);
            }
            this.blockUI.stop();
          }
        },
        error: err => {
          console.log("claimCommonService.getPreferenceData failed", err);
          this.blockUI.stop();
          this.router.navigate(["/error"]);
        }
      });
      this.blockUI.start("Please wait...");
      //* Customer Preferences Title Script
      this.lookupService.getScripts("PreferenceTitle", this.contextService.claimContext.companyCd, 1).subscribe(response => {
        this.preferenceTitle = response;
      });
      //* Notification Preferences Scripts
      if(this.claimSourceCd === "AI"){
        this.lookupService.getScripts("PreferenceNotification", this.contextService.claimContext.companyCd, 6).subscribe(response => {
          this.notificationOptInQuestion = response;
        });
      }else{
        this.lookupService.getScripts("PreferenceNotification", this.contextService.claimContext.companyCd, 3).subscribe(response => {
          this.notificationOptInHeader = response;
        });
        this.lookupService.getScripts("PreferenceNotification", this.contextService.claimContext.companyCd, 4).subscribe(response => {
          this.notificationOptInQuestion = response;
        });
        this.lookupService.getScripts("PreferenceNotification", this.contextService.claimContext.companyCd, 5).subscribe(response => {
          this.notificationOptInTypeQuestion = response;
        });
        this.lookupService.getLookupByType("notificationType").subscribe(response => {
          this.notificationOptInTypeList = response.lookupList;
        });
      }
      //*
      //* Payment Preferences Scripts
      this.lookupService.getScripts("PreferencePayment", this.contextService.claimContext.companyCd, 1).subscribe(response => {
        this.paymentOptInHeader = response;
      });
      this.lookupService.getScripts("PreferencePayment", this.contextService.claimContext.companyCd, 2).subscribe(response => {
        this.paymentOptInQuestion = response;
      });
      this.lookupService.getScripts("PreferencePayment", this.contextService.claimContext.companyCd, 3).subscribe(response => {
        this.paymentOptInNotes = response;
      });
      //*
      //* Contact Information Scripts
      this.lookupService.getScripts("PreferenceContact", this.contextService.claimContext.companyCd, 1).subscribe(response => {
        this.contactHeader = response;
      });
      if(this.claimSourceCd === "AI"){
          this.lookupService.getScripts("PreferenceContact", this.contextService.claimContext.companyCd, 3).subscribe(response => {
            this.contactNotes = response;
          });
          this.lookupService.getScripts("PreferenceContact", this.contextService.claimContext.companyCd, 4).subscribe(response => {
            this.contactAgentNotes = response;
          });
        }else{
          this.lookupService.getScripts("PreferenceContact", this.contextService.claimContext.companyCd, 2).subscribe(response => {
            this.contactNotes = response;
          });
        }
      //*
      this.blockUI.stop();
    }
  }

  toggleNotificationOptInType(notificationOptInInd?: string){
    if(notificationOptInInd === "Y"){
      if(!this.notificationOptInTypeToggle){
        this.notificationOptInTypeToggle = !this.notificationOptInTypeToggle;
      }
    }else if(notificationOptInInd === "N"){
      if(this.notificationOptInTypeToggle){
        this.notificationOptInTypeToggle = !this.notificationOptInTypeToggle;
      }
    }
  }

  toggleContactPhNum(contactTypeCd?: string){
    if(contactTypeCd === "hm_phn"){
      this.contactHomePhNumToggle = !this.contactHomePhNumToggle;
    }else if(contactTypeCd === "busn_phn"){
      this.contactWorkPhNumToggle = !this.contactWorkPhNumToggle;
    }else if(!this.contactHomePhNumToggle){
      this.contactHomePhNumToggle = !this.contactHomePhNumToggle;
    }else if(!this.contactWorkPhNumToggle){
      this.contactWorkPhNumToggle = !this.contactWorkPhNumToggle;
    }
  }

  togglePaymentOptInType(paymentTypeCd?: String){
    switch(paymentTypeCd){
      case "ex_ach":
        if(!this.paymentOptInTypeAchToggle){
          this.paymentOptInTypeAchToggle = !this.paymentOptInTypeAchToggle;
          if(this.isEserviceUser){
            if(this.preferenceData.achAccountAddedInd === "Y"){
              this.paymentOptInTypeAchAddNewToggle = !this.paymentOptInTypeAchAddNewToggle;
            }else if(this.preferenceData.achAccountAddedInd === "N"){
              this.paymentOptInTypeAchUseExistingToggle = !this.paymentOptInTypeAchUseExistingToggle;
            }
          }else{
            if(!this.paymentOptInTypeAchUseExistingToggle){
              this.paymentOptInTypeAchUseExistingToggle = !this.paymentOptInTypeAchUseExistingToggle;
            }
          }
        }
        if(this.paymentOptInTypeZelleToggle){
          this.paymentOptInTypeZelleToggle = !this.paymentOptInTypeZelleToggle;
          if(this.paymentOptInTypeZelleAddNewToggle){
            this.paymentOptInTypeZelleAddNewToggle = !this.paymentOptInTypeZelleAddNewToggle;
          }
          if(this.paymentOptInTypeZelleUseExistingToggle){
            this.paymentOptInTypeZelleUseExistingToggle = !this.paymentOptInTypeZelleUseExistingToggle;
          }
        }
        break;
      case "zelle":
        if(!this.paymentOptInTypeZelleToggle){
          this.paymentOptInTypeZelleToggle = !this.paymentOptInTypeZelleToggle;
          if(this.isEserviceUser){
            if(this.preferenceData.zelleAccountAddedInd === "Y"){
              this.paymentOptInTypeZelleAddNewToggle = !this.paymentOptInTypeZelleAddNewToggle;
            }else if(this.preferenceData.zelleAccountAddedInd === "N"){
              this.paymentOptInTypeZelleUseExistingToggle = !this.paymentOptInTypeZelleUseExistingToggle;
            }
          }else{
            if(!this.paymentOptInTypeZelleUseExistingToggle){
              this.paymentOptInTypeZelleUseExistingToggle = !this.paymentOptInTypeZelleUseExistingToggle;
            }
          }
        }
        if(this.paymentOptInTypeAchToggle){
          this.paymentOptInTypeAchToggle = !this.paymentOptInTypeAchToggle;
          if(this.paymentOptInTypeAchAddNewToggle){
            this.paymentOptInTypeAchAddNewToggle = !this.paymentOptInTypeAchAddNewToggle;
          }
          if(this.paymentOptInTypeAchUseExistingToggle){
            this.paymentOptInTypeAchUseExistingToggle = !this.paymentOptInTypeAchUseExistingToggle;
          }
        }
        break;
      case "check":
        if(this.paymentOptInTypeAchToggle){
          this.paymentOptInTypeAchToggle = !this.paymentOptInTypeAchToggle;
          if(this.paymentOptInTypeAchAddNewToggle){
            this.paymentOptInTypeAchAddNewToggle = !this.paymentOptInTypeAchAddNewToggle;
          }
          if(this.paymentOptInTypeAchUseExistingToggle){
            this.paymentOptInTypeAchUseExistingToggle = !this.paymentOptInTypeAchUseExistingToggle;
          }
        }
        if(this.paymentOptInTypeZelleToggle){
          this.paymentOptInTypeZelleToggle = !this.paymentOptInTypeZelleToggle;
          if(this.paymentOptInTypeZelleAddNewToggle){
            this.paymentOptInTypeZelleAddNewToggle = !this.paymentOptInTypeZelleAddNewToggle;
          }
          if(this.paymentOptInTypeZelleUseExistingToggle){
            this.paymentOptInTypeZelleUseExistingToggle = !this.paymentOptInTypeZelleUseExistingToggle;
          }
        }
        break;
      default:
        break;
    }
  }

  toggleAddNewAccount(paymentTypeCd?: String, newAccountInd?: String){
    switch(paymentTypeCd){
      case "ex_ach":
        if(newAccountInd === "Y"){
          if(!this.paymentOptInTypeAchAddNewToggle){
            this.paymentOptInTypeAchAddNewToggle = !this.paymentOptInTypeAchAddNewToggle;
            if(this.paymentOptInTypeAchUseExistingToggle){
              this.paymentOptInTypeAchUseExistingToggle = !this.paymentOptInTypeAchUseExistingToggle;
            }
          }
        }else if(newAccountInd === "N"){
          if(!this.paymentOptInTypeAchUseExistingToggle){
            this.paymentOptInTypeAchUseExistingToggle = !this.paymentOptInTypeAchUseExistingToggle;
            if(this.paymentOptInTypeAchAddNewToggle){
              this.paymentOptInTypeAchAddNewToggle = !this.paymentOptInTypeAchAddNewToggle;
            }
          }
        }
        break;
      case "zelle":
        if(newAccountInd === "Y"){
          if(!this.paymentOptInTypeZelleAddNewToggle){
            this.paymentOptInTypeZelleAddNewToggle = !this.paymentOptInTypeZelleAddNewToggle;
            if(this.paymentOptInTypeZelleUseExistingToggle){
              this.paymentOptInTypeZelleUseExistingToggle = !this.paymentOptInTypeZelleUseExistingToggle;
            }
          }
        }else if(newAccountInd === "N"){
          if(!this.paymentOptInTypeZelleUseExistingToggle){
            this.paymentOptInTypeZelleUseExistingToggle = !this.paymentOptInTypeZelleUseExistingToggle;
            if(this.paymentOptInTypeZelleAddNewToggle){
              this.paymentOptInTypeZelleAddNewToggle = !this.paymentOptInTypeZelleAddNewToggle;
            }
          }
        }
        break;
      default:
        break;
    }
  }

  toggleEstimateDemandContractor(estDmdContrOptInInd?: string){
    if(estDmdContrOptInInd === "Y"){
      if(!this.estDmdContrOptInToggle){
        this.estDmdContrOptInToggle = !this.estDmdContrOptInToggle;
      }
    }else if(estDmdContrOptInInd === "N"){
      if(this.estDmdContrOptInToggle){
        this.estDmdContrOptInToggle = !this.estDmdContrOptInToggle;
      }
    }
  }  

  submitPreferencePage(){
    this.blockUI.start("Please wait...");
    console.log("PreferenceComponent: preferenceData="+JSON.stringify(this.preferenceData));
    
    const submitPreferencePage: any = {};
    submitPreferencePage.claimId = this.contextService.claimContext.claimId;
    
    let callerParty = this.contextService.claimContext.preferencePage.parties
      .filter((party: any) => party.partyTypeCd === "CALLER")[0];
    let insuredParty = this.contextService.claimContext.preferencePage.parties
      .filter((party: any) => party.partyTypeCd === "PRINMINS")[0];

    if(this.claimSourceCd === "AI"){
      // Agent Party (Save as Caller)
      callerParty.partyFirstName = this.preferenceData.agentFirstName;
      callerParty.partyLastName = this.preferenceData.agentLastName;
      callerParty.partyDetailList[0].notificationOptInInd = this.preferenceData.notificationOptInInd;
      var partyContactList: any = []; var idx: number = 0;
      if(this.preferenceData.agentEMail != null && this.preferenceData.agentEMail != ""){
        var partyContact: any = {};
        partyContact.contactTypeCd = "prsn_email";
        partyContact.contactTxt = this.preferenceData.agentEMail;
        partyContactList[idx] = partyContact;
        idx += 1;
      }
      if(this.preferenceData.agentWorkPhNum != null && this.preferenceData.agentWorkPhNum != ""){
        var partyContact: any = {};
        partyContact.contactTypeCd = "busn_phn";
        partyContact.contactTxt = this.preferenceData.agentWorkPhNum;
        partyContactList[idx] = partyContact;
        idx += 1;
      }
      callerParty.partyContactList = partyContactList;
      submitPreferencePage.submitterParty = callerParty;
      // Insured Party (Contact Only)
      var partyContactList: any = []; var idx: number = 0;
      if(this.preferenceData.contactEMail != null && this.preferenceData.contactEMail != ""){
        var partyContact: any = {};
        partyContact.contactTypeCd = "prsn_email";
        partyContact.contactTxt = this.preferenceData.contactEMail;
        partyContactList[idx] = partyContact;
        idx += 1;
      }
      if(this.preferenceData.contactCellPhNum != null && this.preferenceData.contactCellPhNum != ""){
        var partyContact: any = {};
        partyContact.contactTypeCd = "cell_phn";
        partyContact.contactTxt = this.preferenceData.contactCellPhNum;
        partyContactList[idx] = partyContact;
        idx += 1;
      }
      if(this.preferenceData.contactHomePhNum != null && this.preferenceData.contactHomePhNum != ""){
        var partyContact: any = {};
        partyContact.contactTypeCd = "hm_phn";
        partyContact.contactTxt = this.preferenceData.contactHomePhNum;
        partyContactList[idx] = partyContact;
        idx += 1;
      }
      if(this.preferenceData.contactWorkPhNum != null && this.preferenceData.contactWorkPhNum != ""){
        var partyContact: any = {};
        partyContact.contactTypeCd = "busn_phn";
        partyContact.contactTxt = this.preferenceData.contactWorkPhNum;
        partyContactList[idx] = partyContact;
        idx += 1;
      }
      insuredParty.partyContactList = partyContactList;
      submitPreferencePage.namedInsuredParty = insuredParty;
    }else if(this.insCallerInd === "Y" || this.otherCallerInd){
      callerParty.partyFirstName = this.preferenceData.contactFirstName;
      callerParty.partyLastName = this.preferenceData.contactLastName;
      callerParty.partyDetailList[0].notificationOptInInd = this.preferenceData.notificationOptInInd;
      callerParty.partyDetailList[0].notificationTypeCd = this.preferenceData.notificationTypeCd;
      var partyContactList: any = []; var idx: number = 0;
      if(this.preferenceData.contactEMail != null){
        var partyContact: any = {};
        partyContact.contactTypeCd = "prsn_email";
        partyContact.contactTxt = this.preferenceData.contactEMail;
        partyContactList[idx] = partyContact;
        idx += 1;
      }
      if(this.preferenceData.contactCellPhNum != null){
        var partyContact: any = {};
        partyContact.contactTypeCd = "cell_phn";
        partyContact.contactTxt = this.preferenceData.contactCellPhNum;
        partyContactList[idx] = partyContact;
        idx += 1;
      }
      if(this.preferenceData.contactHomePhNum != null){
        var partyContact: any = {};
        partyContact.contactTypeCd = "hm_phn";
        partyContact.contactTxt = this.preferenceData.contactHomePhNum;
        partyContactList[idx] = partyContact;
        idx += 1;
      }
      if(this.preferenceData.contactWorkPhNum != null){
        var partyContact: any = {};
        partyContact.contactTypeCd = "busn_phn";
        partyContact.contactTxt = this.preferenceData.contactWorkPhNum;
        partyContactList[idx] = partyContact;
        idx += 1;
      }
      callerParty.partyContactList = partyContactList;
      submitPreferencePage.submitterParty = callerParty;
      submitPreferencePage.namedInsuredParty = {};
    }
    // Payment Preferences
    if(this.showPaymentPreferences){
      let paymentPreference: any = {};
      paymentPreference.claimId = this.contextService.claimContext.claimId;
      paymentPreference.paymentType = this.preferenceData.paymentTypeCd;
      if(this.preferenceData.paymentTypeCd === "ex_ach" || this.preferenceData.paymentTypeCd === "zelle"){
        let account: any = {};
        if(this.preferenceData.achAccountAddedInd === "Y"){
          account.accountType = this.preferenceData.achAccountType;
          account.accountNumber = this.preferenceData.achAccountNumber;
          account.routingNumber = this.preferenceData.achRoutingNumber;
        }else if(this.preferenceData.zelleAccountAddedInd === "Y"){
          account.accountType = this.preferenceData.zelleAccountType;
          if(account.accountType === "email"){
            account.accountNumber = this.preferenceData.zelleEmail;
          }else{
            account.accountNumber = this.preferenceData.zellePhoneNumber;
          }
        }else{
          if(this.preferenceData.paymentTypeCd === "ex_ach"){
            account = this.achAccountList.filter((account: any) =>
              account.accountNumber === this.preferenceData.achAccount.name && account.accountType === this.preferenceData.achAccount.code
            )[0];
          }else if(this.preferenceData.paymentTypeCd === "zelle"){
            account = this.zelleAccountList.filter((account: any) =>
            account.accountNumber === this.preferenceData.zelleAccount.name && account.accountType === this.preferenceData.zelleAccount.code
            )[0];
          }
        }
        if(this.preferenceData.paymentTypeCd === "ex_ach"){
          account = this.achAccountList.filter((account: any) =>
            account.accountNumber === this.preferenceData.achAccount.name && account.accountType === this.preferenceData.achAccount.code
          )[0];
        }else if(this.preferenceData.paymentTypeCd === "zelle"){
          account = this.zelleAccountList.filter((account: any) =>
          account.accountNumber === this.preferenceData.zelleAccount.name && account.accountType === this.preferenceData.zelleAccount.code
          )[0];
        }
        paymentPreference.paymentAccountId = account.accountNumber;
        paymentPreference.paymentAccountType = account.accountType;
        paymentPreference.paymentAccountRoutingId = account.routingNumber;
        if(this.contextService.claimContext.preferencePage.paymentDetails.paymentPreference != null){
          paymentPreference.preferenceId = this.contextService.claimContext.preferencePage.paymentDetails.paymentPreference.preferenceId;
        }
      }
      submitPreferencePage.paymentDetails = {};
      submitPreferencePage.paymentDetails.paymentPreference = paymentPreference;
    }
    // Structural Claim or Estimate Demand Contractor Preferences
    let homeAutomate: any = {};
    if(this.structuralEligibleInd === "Y" || this.estimateDemandEligibleInd === "Y"){
      homeAutomate.structuralOptInInd = this.preferenceData.structuralOptInInd;
      homeAutomate.estimateDemandContractorOptInInd = this.preferenceData.estDmdContrOptInInd;
      homeAutomate.estimateDemandContractorName = this.preferenceData.estDmdContrNm;
      homeAutomate.estimateDemandContractorEmail = this.preferenceData.estDmdContrEMail;
    }
    // Set Estimate Demand & Advance Payment Eligible Indicators for Update Mapper
    homeAutomate.estimateDemandEligibleInd = this.estimateDemandEligibleInd; // Estimate Demand Eligible Indicator
    homeAutomate.advancePaymentEligibleInd = this.advancePaymentEligibleInd; // Advance Payment Eligible Indicator
    submitPreferencePage.homeAutomate = homeAutomate;
    // Save Preferences
    console.log("PreferenceComponent: submitPreferencePage="+JSON.stringify(submitPreferencePage));
    this.claimCommonService.savePreferencePageData(submitPreferencePage).subscribe({
      next: () => {
        this.blockUI.stop();
        if(this.waterMitigationEligibleInd === "Y" && this.insCallerInd === "Y"){
          this.router.navigate(['/vendor']);
        }else{
          this.router.navigate(['/assignment']);
        }
      },
      error: err => {
        console.log("claimCommonService.savePreferenceData failed", err);
        this.blockUI.stop();
        //this.router.navigate(["/error"]);
      }
    });
  }

  goBack(){
    this.router.navigate(['/ho-loss']);
  }
}