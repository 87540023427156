<p-confirmDialog #cd [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}" 
    key="confirmPopup" position="top" icon="pi pi-check-circle">
    <!-- <ng-template pTemplate="header">
        <div class="col-12">
            <div class="p-dialog-header dialog-header ">
                <span class="p-dialog-title ">{{cd.confirmation.header}}</span>
                <div class="p-dialog-header-icons dialog-header ng-tns-c55-5">
                    <button type="button"
                        class="ng-tns-c55-5 p-dialog-header-icon p-dialog-header-close p-link ng-star-inserted"
                        ng-reflect-ng-class="[object Object]">
                        <span class="pi pi-times ng-tns-c55-5"></span>
                    </button>
                </div>
            </div>
            
        </div>



    </ng-template> -->
</p-confirmDialog>