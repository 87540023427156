import { Component, OnInit } from '@angular/core';
import { DynamicBasePageComponent } from '../../formly/dynamic-base-page/dynamic-base-page.component';

@Component({
  selector: 'app-mvp-estimate',
  templateUrl: './mvp-estimate.component.html',
  styleUrls: ['./mvp-estimate.component.scss']
})
export class MvpEstimateComponent extends DynamicBasePageComponent implements OnInit {


  override ngOnInit(): void {

    this.dynamicService.initializeApp("mvp_estimate_1.0", {claimId: this.context.claimContext['claimId']})
    .subscribe(
      ()=>{
        super.ngOnInit();
      }
    )
    
  }

}
