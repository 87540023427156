import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';
import { ClaimCommonService } from 'src/app/services/claim-common.service';
import { LookupService } from 'src/app/services/lookup.service';
import { ContextService } from 'src/app/utils/context.service';
import { forkJoin } from 'rxjs';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-wrapup',
  templateUrl: './wrapup.component.html',
  styleUrls: ['./wrapup.component.scss'],
  encapsulation:ViewEncapsulation.None
})

export class WrapupComponent implements OnInit{
  @BlockUI() blockUI!: NgBlockUI;
  
  wrapupHeader: string = "WrapupPage"; // Wrapup Page Header
  wrapupNote: string = "WrapupPage"; // Wrapup Page Note
  wrapupSettleClaimHeader: string = "WrapupPage"; // Wrapup Page Settle Claim Header
  wrapupSettleClaimNote: string = "WrapupPage"; // Wrapup Page Settle Claim Note
  wrapupSettleClaimButtonText: string = "WrapupPage"; // Wrapup Page Settle Claim Button
  wrapupSettleClaimLaterNote: string = "WrapupPage"; // Wrapup Page Settle Claim Later
  wrapupEserviceNote: string = "WrapupPage"; // Wrapup Page eService Note
  wrapupWrapupWtrDmgClmDenyNote: string = "WrapupPage"; // Wrapup Page Water Damage Claim Denied Note
  wrapupTragicLossHeader: string = "WrapupPage"; // Wrapup Page Tragic Loss Header
  wrapupTragicLossNote: string = "WrapupPage"; // Wrapup Page Tragic Loss Note
  wrapupTragicLossPhNum: string = "WrapupPage"; // Wrapup Page Tragic Loss Phone Number
  
  claimSourceCd: string = ""; // Claim Source Code
  insCallerInd: string = "N"; // Is Insured Caller Indicator
  agentWithInsuredInd: string = ""; // Agent With Insured Indicator
  claimNumber: string = "N/A"; // Claim Number
  contentEligibleInd: string = ""; // Contents Opt-In/Opt-Out Eligible Indicator
  adjusterName: string = ""; // Adjuster Name
  adjusterEMail: string = ""; // Adjuster Email Address
  adjusterPhNum: string = ""; // Adjuster Phone Number
  eserviceLinkUrl: string = "https://www.plymouthrock.com/mypolicy"; // eService Link URL (w/Default)
  eserviceLinkStsCd: string = ""; // eService Link Status Code
  wtrDmgClmDenyInd: string = ""; // Water Damage Claim Denied Indicator
  callerFirstName: string = ""; // Caller First Name
  callerLastName: string = ""; // Caller Last Name
  selfSettleClaimUrl: string = ""; // Self Settle Claim URL
  selfSettleClaimStsCd: string = ""; // Self Settle Claim Status Code
  sewerBackupToolEligibleInd: string = ""; // Sewer Backup Tool Opt-In/Opt-Out Eligible Indicator
  estimateDemandEligibleInd: string = ""; // Estimate Demand Opt-In/Opt-Out Eligible Indicator
  advancePaymentEligibleInd: string = ""; // Advance Payment Opt-In/Opt-Out Eligible Indicator
  hoTragicLossInd: string = ""; // Home Tragic Loss Indicator
  isPriceToolActive: boolean = false; // Is Price Tool Active Flag
  isSewerBackupToolActive: boolean = false; // Is Sewer Backup Tool Active Flag
  isEstimateDemandActive: boolean = false; // Is Estimate Demand Active Flag
  isDemandPortalActive: boolean = false; // Is Estimate Demand Portal Link Active Flag
  isAdvancePaymentActive: boolean = false; // Is Advance Payment Active Flag
  isHighSeverityLossRulesActive: boolean = false; // Is High Severity Loss Rules Active Flag
  wrapupSettleClaimToggle: boolean = false; // Toggle to Show/Hide Self Service Scripts

  wrapupData : {
    anyConcernsTxt?: string, // Any Concerns Text
  } = {};

  constructor(private lookupService: LookupService, 
    private claimCommonService: ClaimCommonService, 
    private contextService: ContextService,
    private confirmationService: ConfirmationService,
    private router: Router){ }

  ngOnInit(): void {
    const claimId = this.contextService.claimContext.claimId;
    console.log("WrapupComponent: claimId="+claimId);

    if(claimId){
      this.blockUI.start("Please wait...");

      this.claimSourceCd = this.contextService.claimContext.claimSourceCd;
      console.log("WrapupComponent: claimSourceCd="+this.claimSourceCd);
      this.insCallerInd = this.contextService.claimContext.insCallerInd;
      console.log("WrapupComponent: insCallerInd="+this.insCallerInd);
      this.agentWithInsuredInd = this.contextService.claimContext.agentWithInsuredInd;
      console.log("WrapupComponent: agentWithInsuredInd="+this.agentWithInsuredInd);
      this.claimNumber = this.contextService.claimContext.claimNumber;
      console.log("WrapupComponent: claimNumber="+this.claimNumber);

      forkJoin({
        wrapupPage: this.claimCommonService.getWrapupPageData(claimId),
        chatbotUrlData: this.claimCommonService.getClaimUrlDataWithRetry(claimId,"chatbot"),
        eserviceUrlData: this.claimCommonService.getClaimUrlDataWithRetry(claimId,"eservice"),
      }).subscribe({
        next: (result: any) => {
          //console.log("WrapupComponent: result="+JSON.stringify(result));
          if(result.wrapupPage){
            //console.log("WrapupComponent: wrapupPage="+JSON.stringify(wrapupPage));
            // Save Wrapup Page data for Submit processing
            this.contextService.claimContext.wrapupPage = result.wrapupPage;

            // Get Feature Flags
            this.isPriceToolActive = result.wrapupPage.isPriceToolActive;
            console.log("WrapupComponent: isPriceToolActive="+this.isPriceToolActive);
            this.isSewerBackupToolActive = result.wrapupPage.isSewerBackupToolActive;
            console.log("WrapupComponent: isSewerBackupToolActive="+this.isSewerBackupToolActive);
            this.isEstimateDemandActive = result.wrapupPage.isEstimateDemandActive;
            console.log("WrapupComponent: isEstimateDemandActive="+this.isEstimateDemandActive);
            this.isDemandPortalActive = result.wrapupPage.isDemandPortalActive;
            console.log("WrapupComponent: isDemandPortalActive="+this.isDemandPortalActive);
            this.isAdvancePaymentActive = result.wrapupPage.isAdvancePaymentActive;
            console.log("WrapupComponent: isAdvancePaymentActive="+this.isAdvancePaymentActive);
            this.isHighSeverityLossRulesActive = result.wrapupPage.isHighSeverityLossRulesActive;
            console.log("WrapupComponent: isHighSeverityLossRulesActive="+this.isHighSeverityLossRulesActive);

            // Get Home Automation Information
            let homeAutomate = result.wrapupPage.homeAutomate;
            if(homeAutomate != undefined){
              //console.log("WrapupComponent: homeAutomate="+JSON.stringify(homeAutomate));
              this.contentEligibleInd = homeAutomate.contentEligibleInd;
              console.log("WrapupComponent: contentEligibleInd="+this.contentEligibleInd);
              this.sewerBackupToolEligibleInd = homeAutomate.sewerBackupToolEligibleInd;
              console.log("WrapupComponent: sewerBackupToolEligibleInd="+this.sewerBackupToolEligibleInd);
              this.estimateDemandEligibleInd = homeAutomate.estimateDemandEligibleInd;
              console.log("WrapupComponent: estimateDemandEligibleInd="+this.estimateDemandEligibleInd);
              this.advancePaymentEligibleInd = homeAutomate.advancePaymentEligibleInd;
              console.log("WrapupComponent: advancePaymentEligibleInd="+this.advancePaymentEligibleInd);
              this.hoTragicLossInd = homeAutomate.hoTragicLossInd;
              console.log("WrapupComponent: hoTragicLossInd="+this.hoTragicLossInd);
            }

            // Get Unit Assignment Information
            let assignments = result.wrapupPage.assignments;
            if(assignments != undefined){
              //console.log("WrapupComponent: assignments="+JSON.stringify(assignments));
              let primaryUnitAssignment = assignments
              .filter((assignment: any) => assignment.primaryIndicator === "Y")[0];
              //console.log("WrapupComponent: primaryUnitAssignment="+JSON.stringify(primaryUnitAssignment));
              if(primaryUnitAssignment){
                this.adjusterName = primaryUnitAssignment.systemAssignedAdjusterName;
                if(primaryUnitAssignment.systemAssignedAdjusterEmailAddr != null){
                  this.adjusterEMail = primaryUnitAssignment.systemAssignedAdjusterEmailAddr;
                }
                if(primaryUnitAssignment.systemAssignedAdjusterPhoneNum != null){
                  this.adjusterPhNum = primaryUnitAssignment.systemAssignedAdjusterPhoneNum.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
                }
              }
            }

            // Get Party Information
            let parties = result.wrapupPage.parties;
            if(parties != undefined){
              let callerParty = parties
                .filter((party: any) => party.partyTypeCd === "CALLER")[0];
              if(callerParty){
                this.callerFirstName = callerParty.partyFirstName;
                this.callerLastName = callerParty.partyLastName;
              }
            }
            // Project NMC-21-0224: HO - Flood Chatbot
            this.wtrDmgClmDenyInd = this.contextService.claimContext.wtrDmgClmDenyInd;
            console.log("WrapupComponent: wtrDmgClmDenyInd="+this.wtrDmgClmDenyInd);
            if(this.wtrDmgClmDenyInd === "Y"){ // Water Damage Claim is Denied
              if(this.claimSourceCd === "AI"){
                // Get Wrapup Page Water Damage Claim Denied Note for Agent
                this.lookupService.getScripts("WrapupPage", this.contextService.claimContext.companyCd, 9).subscribe(response => {
                  this.wrapupWrapupWtrDmgClmDenyNote = response;
                });
              }else{
                // Get Wrapup Page Water Damage Claim Denied Note for Insured
                this.lookupService.getScripts("WrapupPage", this.contextService.claimContext.companyCd, 8).subscribe(response => {
                  this.wrapupWrapupWtrDmgClmDenyNote = response;
                });
              }
              this.wrapupWrapupWtrDmgClmDenyNote = this.wrapupWrapupWtrDmgClmDenyNote
                .replaceAll("{{claimNumber}}",this.contextService.claimContext.claimNumber)
                .replaceAll("{{callerFirstName}}",this.callerFirstName)
                .replaceAll("{{callerLastName}}",this.callerLastName)
                .replaceAll("{{claimHandlerFullName}}",this.adjusterName)
                .replaceAll("{{claimHandlerPhone}}",(this.adjusterPhNum.length>0?this.adjusterPhNum:"N/A"))
                .replaceAll("{{scriptCompanyNm}}",this.contextService.claimContext.scriptCompanyNm);
            }
          }
          if(result.chatbotUrlData){
            //console.log("WrapupComponent: chatbotUrlData="+JSON.stringify(result.chatbotUrlData));
            this.selfSettleClaimStsCd = result.chatbotUrlData.claimUrlStsCd;
            console.log("WrapupComponent: selfSettleClaimStsCd="+this.selfSettleClaimStsCd);
            if(this.selfSettleClaimStsCd === "completed"){
              this.selfSettleClaimUrl = result.chatbotUrlData.claimUrl;
            }
            console.log("WrapupComponent: selfSettleClaimUrl="+this.selfSettleClaimUrl);
          }
          if(result.eserviceUrlData){
            //console.log("WrapupComponent: eserviceUrlData="+JSON.stringify(result.eserviceUrlData));
            this.eserviceLinkStsCd = result.eserviceUrlData.claimUrlStsCd;
            console.log("WrapupComponent: eserviceLinkStsCd="+this.eserviceLinkStsCd);
            if(this.eserviceLinkStsCd === "completed"){
              this.eserviceLinkUrl = result.eserviceUrlData.claimUrl;
            }
            console.log("WrapupComponent: eserviceLinkUrl="+this.eserviceLinkUrl);
            // Get eService Wrapup Page Script
            this.lookupService.getScripts("WrapupPage", this.contextService.claimContext.companyCd, 7).subscribe(response => {
              this.wrapupEserviceNote = response.replace("{{eserviceLinkUrl}}",this.eserviceLinkUrl); // eService Note
            });
          }
          // NMC-22-0145: HO - Demand Estimate Portal
          // Set Toggle to Show Self Service Scripts
          this.toggleWrapupSettleClaim();
          // NMC-22-0143: HO - Advance Pay Automation
          // Wrapup Page Settle Claim Scripts
          if(this.isAdvancePaymentActive && this.advancePaymentEligibleInd === "Y"){
            // Settle Claim w/Advance Payment Scripts
            this.lookupService.getScripts("WrapupPage", this.contextService.claimContext.companyCd, 11).subscribe(response => {
              this.wrapupSettleClaimHeader = response;
            });
            this.lookupService.getScripts("WrapupPage", this.contextService.claimContext.companyCd, 12).subscribe(response => {
              this.wrapupSettleClaimNote = response;
            });
            this.lookupService.getScripts("WrapupPage", this.contextService.claimContext.companyCd, 13).subscribe(response => {
              this.wrapupSettleClaimButtonText = response;
            });
          }else{
            // Settle Claim w/o Advance Payment Scripts
            this.lookupService.getScripts("WrapupPage", this.contextService.claimContext.companyCd, 3).subscribe(response => {
              this.wrapupSettleClaimHeader = response;
            });
            this.lookupService.getScripts("WrapupPage", this.contextService.claimContext.companyCd, 4).subscribe(response => {
              this.wrapupSettleClaimNote = response;
            });
            this.lookupService.getScripts("WrapupPage", this.contextService.claimContext.companyCd, 5).subscribe(response => {
              this.wrapupSettleClaimButtonText = response;
            });
          }
          // NMC-23-0090: HO eFNOL Day 2
          // Wrapup Page Tragic Loss Scripts
          if(this.isHighSeverityLossRulesActive && this.hoTragicLossInd === "Y"){
            this.lookupService.getScripts("WrapupPage", this.contextService.claimContext.companyCd, 14).subscribe(response => {
              this.wrapupTragicLossHeader = response;
            });
            this.lookupService.getScripts("WrapupPage", this.contextService.claimContext.companyCd, 15).subscribe(response => {
              this.wrapupTragicLossNote = response;
            });
            this.lookupService.getScripts("WrapupPage", this.contextService.claimContext.companyCd, 16).subscribe(response => {
              this.wrapupTragicLossPhNum = response;
            });

            this.confirmationService.confirm({
              key: 'wrapupPopup',
              message: this.wrapupTragicLossNote,
              header: this.wrapupTragicLossHeader,
              
              accept: () => {
                  //Actual logic to perform a confirmation
              }
          });

          }
          this.blockUI.stop();
        },
        error: err => {
          console.log("WrapupComponent Initialize Failed", err);
          this.blockUI.stop();
          this.router.navigate(["/error"]);
        }
      });
      //* Wrapup Page Scripts
      this.lookupService.getScripts("WrapupPage", this.contextService.claimContext.companyCd, 1).subscribe(response => {
        this.wrapupHeader = response;
      });
      this.lookupService.getScripts("WrapupPage", this.contextService.claimContext.companyCd, 2).subscribe(response => {
        this.wrapupNote = response;
      });
      //* Wrapup Page Settle Claim Later Script
      this.lookupService.getScripts("WrapupPage", this.contextService.claimContext.companyCd, 6).subscribe(response => {
        this.wrapupSettleClaimLaterNote = response;
      });
    }
  }

  toggleWrapupSettleClaim(){
    // Show Self Service Scripts when Insured is Caller & Claim is Eligble for Price Tool, Sewer Backup Tool or Estimate Demand
    if(this.insCallerInd === "Y"){
      if(this.isPriceToolActive && this.contentEligibleInd === "Y" && this.selfSettleClaimStsCd === "completed"){
        this.wrapupSettleClaimToggle = true;
      }else if(this.isSewerBackupToolActive && this.sewerBackupToolEligibleInd === "Y" && this.selfSettleClaimStsCd === "completed"){
        this.wrapupSettleClaimToggle = true;
      }else if(this.isEstimateDemandActive && this.estimateDemandEligibleInd === "Y"){
        this.wrapupSettleClaimToggle = true;
      }else if(this.isDemandPortalActive && this.estimateDemandEligibleInd === "Y" && this.selfSettleClaimStsCd === "completed"){
        this.wrapupSettleClaimToggle = true;
      }
    // Show Self Service Scripts when Agent is Reporting & Claim is Eligble for Estimate Demand
    }else if(this.claimSourceCd === "AI"){
      if(this.isEstimateDemandActive && this.estimateDemandEligibleInd === "Y"){
        this.wrapupSettleClaimToggle = true;
      }else if(this.isDemandPortalActive && this.estimateDemandEligibleInd === "Y" && this.selfSettleClaimStsCd === "completed"){
        this.wrapupSettleClaimToggle = true;
      }
    }
    console.log("WrapupComponent: wrapupSettleClaimToggle="+this.wrapupSettleClaimToggle);
  }


  submitWrapupPage(){
    console.log("WrapupComponent: wrapupData="+JSON.stringify(this.wrapupData));

    //* NMC-22-0145: HO – Demand Estimate Portal
    //* If Estimate Demand Active Flag is Active, Then Navigate to MVP Estimate Demand
    if(this.isEstimateDemandActive && this.estimateDemandEligibleInd === "Y"){
      this.router.navigate(['/ho-estimate']);
    }else{
      this.blockUI.start("Navigating... Please wait...");
      window.location.href = (this.selfSettleClaimUrl);
    }
    //*
  }

  canDeactivate(){
    return !this.contextService.isClaimSubmitted();
  }
}