<div class="grid">
    <div class="col-12">
        <label class="required"><strong>{{props.label}}</strong></label>
    </div>
    <div class="col-6 md:col-2 " *ngFor="let icon of props['icons']">
        <label class="claim-icon " >
            <input type="radio" name="claimIcon" [value]="icon.value" [formControl]="formControl" [formlyAttributes]="field" (click)="submitForm(icon.value)">
            <img class="svg-icon" [src]="'./assets/svg/'+icon.img"
                >
            <p class="text-center">{{icon.label}}</p>
        </label>

    </div>
    <!-- <div class="col-6 md:col-2">
        <label class="claim-icon " >
            <input type="radio" name="claimIcon" value="01_Claim Type 2-Weather" [formControl]="formControl" [formlyAttributes]="field" (click)="submitForm()">
            <img class="svg-icon" src="./assets/svg/01_Claim Type 2-Weather.svg"
               >
            <p class="text-center">Weather or Natural Disaster</p>
        </label>
    </div>
    <div class="col-6 md:col-2">
        <label class="claim-icon ">
            <input type="radio" name="claimIcon" value="01_Claim Type 3-Fire" [formControl]="formControl" [formlyAttributes]="field" (click)="submitForm()">
            <img class="svg-icon" src="./assets/svg/01_Claim Type 3-Fire.svg" >
            <p class="text-center">Fire</p>
        </label>
    </div>
    <div class="col-6 md:col-2">
        <label class="claim-icon " >
            <input type="radio" name="claimIcon" value="01_Claim Type 4-Theft Vandalism" [formControl]="formControl" [formlyAttributes]="field" (click)="submitForm()">
            <img type="submit" class="svg-icon" src="./assets/svg/01_Claim Type 4-Theft Vandalism.svg"
                >
            <p class="text-center">Theft or Vandalism</p>
        </label>
    </div>
    <div class="col-6 md:col-2">
        <label class="claim-icon " >
            <input type="radio" name="claimIcon" value="01_Claim Type 5-Injury" [formControl]="formControl" [formlyAttributes]="field" (click)="submitForm()">
            <img class="svg-icon" src="./assets/svg/01_Claim Type 5-Injury.svg"
                >
            <p class="text-center">Injury</p>
        </label>
    </div>
    <div class="col-6 md:col-2">
        <label class="claim-icon " >
            <input type="radio" name="claimIcon" value="01_Claim Type 6-Something Else" [formControl]="formControl" [formlyAttributes]="field" (click)="submitForm()">
            <img class="svg-icon" src="./assets/svg/01_Claim Type 6-Something Else.svg"
                >
            <p class="text-center">Something Else</p>
        </label>
    </div> -->
    <div>
        <button #submitButton style="display: none;" type="submit"></button>
    </div>
</div>