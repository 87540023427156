<div>
    <div *ngIf="props['title']"><b> <span class="p-card-title ">{{props['title']}}</span></b></div>
    <p class="m-0" *ngIf="props['htmlMessage']" [innerHTML]="getResolvedMessage(props['htmlMessage'])">
    </p>

    <p *ngIf="props['messageParts']">
        <ng-container *ngFor="let messagePart of props['messageParts']">
            <a *ngIf="messagePart.link" [href]='(messagePart.link)' role='button'>{{messagePart.label}}</a>
            <span *ngIf="messagePart.message" >{{getResolvedMessage(messagePart.message)}}</span>
        </ng-container>
    </p>
   

<div class="bg-white inline-block" *ngIf="props['buttons']">
    <ng-container  *ngFor="let button of props['buttons']">
        <a [class]="button.class"   [href]="button.link" role="button">{{button.label}}</a>
    </ng-container>
</div>
</div>
