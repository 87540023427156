
<div class="grid pb-3">
    <div class="col-12 md:col-7" *ngIf="field.props.headerLabel">
        <b> {{field.props.headerLabel}}</b>
    </div>
    <div class="col-12 md:col-7 ml-2">
        <a [routerLink]="" class="link-success cursor-pointer " (click)="showFileUpload()"><i class="pi pi-plus-circle align-middle" style="font-size: 2rem" ></i><b> {{field.props.label}}</b></a>
    </div>
    <div class="col-12 md:col-6 ml-2" *ngIf=" this.model  && this.model[this.field.key+''] ">
        <div class="grid" >
            <ng-container *ngFor="let file of this.model[this.field.key+'']; let i = index;" >
                <div class="col-6">{{ file.fileName }}</div>
                <div class="col-2">{{ formatSize(file.size) }}</div>
                <div class="col-4">
                    <button type="button" icon="pi pi-image" class="mr-2" pButton style="font-size: .75rem" (click)="download(i)"></button>
                    <button type="button" icon="pi pi-trash" pButton style="font-size: .75rem" (click)="remove(i)"></button>
                </div>
            </ng-container>
        </div>
        
        
    </div>
</div>


<p-dialog [header]="'fileUpload'" [(visible)]="fileUploadFlag" *ngIf="fileUploadFlag" [modal]="true" [breakpoints]="{ '960px': '100vw' }" [style]="{ width: '50vw' }">        

    <p-fileUpload #fileUpload1 
        [showUploadButton]="false" 
        [showCancelButton]="false"  
        (onSelect)="uploadDisabled = false;" 
        (onClear)="uploadDisabled = true;"  
        [customUpload]="true" 
        [multiple]="this.props.multiple"
        (uploadHandler)="uploadFile($event)"
        [accept]="this.props.accept" [maxFileSize]="this.props.maxFileSize">
        <ng-template pTemplate="toolbar">
            <div>Please select and upload </div>
        </ng-template>
        <ng-template let-file pTemplate="file">
            <div class="p-fileupload-row" >
                <div class="p-1"><img [src]="file.objectURL" *ngIf="fileUpload1.isImage(file)" width="90" /></div>
                <div class="p-fileupload-filename p-1">{{ file.name }}</div>
                <div class="p-1" style="max-width: 50px;">{{ fileUpload1.formatSize(file.size) }}</div>
                <div class="p-0" style="max-width: 50px;">
                    <button type="button" icon="pi pi-times" pButton (click)="uploadDisabled = true;fileUpload1.remove($event, 0)" [disabled]="fileUpload1.uploading" [class]="fileUpload1.removeStyleClass"></button>
                </div>
            </div>
        </ng-template>            

    </p-fileUpload>

    <ng-template pTemplate="footer">
        <!-- <p-button label="Upload" icon="pi pi-upload" iconPos="right"  (onClick)="uploadFile()"></p-button> -->
        <button pButton  type="button" [disabled]="uploadDisabled" icon="pi pi-upload" class="p-button " (click)="fileUpload1.upload()" label="Upload" >  </button>
        <button pButton  type="button" icon="pi pi-times" class="p-button p-button-secondary p-button-outlined" (click)="cancel()" label="Cancel"></button>
        <!-- <p-button label="Cancel" icon="pi pi-times" iconPos="right"  class="p-button p-button-secondary"  (onClick)="cancel()"></p-button> -->
    </ng-template>

</p-dialog>



