<div class="grid pb-3">
    <div class="col-12 pb-0 align-middle" *ngIf="props.label">
        <label class="required" > <strong>{{props.label}}</strong></label>
    </div>
    <div   class=" col-12 md:col-8  gray-box ">
        <ng-container *ngFor="let checkbox of props['multiCheckbox']">
            <div class="field-checkbox m-0">
                <p-checkbox [value]="checkbox.value" [name]="key+''" [inputId]="key+''" [formControl]="formControl" [formlyAttributes]="field" ></p-checkbox>
                <label  [for]="key"><strong>{{ checkbox.label }}</strong></label><br>
                
            </div>
            <div class="pl-5 pb-2" *ngIf="checkbox.description">
                {{ checkbox.description }}
            </div>
        </ng-container>
           
    </div>
</div>