<div class="home-col home-left-col">
    <h1 class="page-title" *ngIf="preferenceTitle != 'PreferenceTitle'">{{preferenceTitle}}</h1>
    <form #preferenceForm="ngForm">
        <div class="grid p-fluid form-group">
            <!--Notification Preferences-->
            <ng-container *ngIf="notificationOptInHeader != 'PreferenceNotification' && insCallerInd === 'Y'">
                <div class="col-12 mt-2">
                    <h4>{{notificationOptInHeader}}</h4>
                    <label for="notificationOptInInd" class="pb-0 required"><strong innerHTML="{{notificationOptInQuestion}}"></strong></label>
                    <div>
                        <div class="input-group mb-3">
                            <p-selectButton name="notificationOptInInd" [options]="optionsYesNo" [(ngModel)]="preferenceData.notificationOptInInd" optionLabel="label" optionValue="value" class="button w-50" required (click)="toggleNotificationOptInType(preferenceData.notificationOptInInd)"></p-selectButton>
                        </div>
                        <div class="text-red-500" *ngIf="preferenceForm.controls.notificationOptInInd?.invalid && (preferenceForm?.dirty || preferenceForm?.touched)">
                            <em *ngIf="preferenceForm.controls.notificationOptInInd?.errors?.['required']">Select 'Yes' to receive future electronic notifications about your claim or else select 'No'.</em>
                        </div>
                        <ng-container *ngIf="notificationOptInTypeQuestion != 'PreferenceNotification' && notificationOptInTypeToggle">
                            <div>
                                <label for="notificationType" class="pb-2 required"><strong>{{notificationOptInTypeQuestion}}</strong></label>
                                <div class="col-12 py-0"  *ngFor="let notificationType of notificationOptInTypeList">
                                    <input type="radio" [id]="notificationType.value" name="notificationType"  [value]="notificationType.value" [(ngModel)]="preferenceData.notificationTypeCd" required>
                                    <label [for]="notificationType.value" class="radio">{{notificationType.text}}</label>
                                </div>                        
                            </div>
                            <div class="text-red-500" *ngIf="preferenceForm.controls.notificationType?.invalid  && (preferenceForm?.dirty || preferenceForm?.touched)">
                                <em *ngIf="preferenceForm.controls.notificationType?.errors?.['required']">Select how would you like to receive these notifications.</em>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <!--EXP-22-0199: Inclusion of PLNAR and HOVER with eClaims-->
            <!--Structural Claim Preferences-->
            <ng-container *ngIf="structuralOptInHeader != 'PreferenceStructural' && !otherCallerInd">
                <div class="col-12 mt-2">
                    <h4>{{structuralOptInHeader}}</h4>
                    <label for="structuralOptInInd" class="pb-0 required"><strong innerHTML="{{structuralOptInQuestion}}"></strong></label>
                    <div>
                        <div class="input-group mb-3">
                            <p-selectButton name="structuralOptInInd" [options]="optionsYesNo" [(ngModel)]="preferenceData.structuralOptInInd" optionLabel="label" optionValue="value" class="button w-50" required></p-selectButton>
                        </div>
                        <div class="text-red-500" *ngIf="preferenceForm.controls.structuralOptInInd?.invalid && (preferenceForm?.dirty || preferenceForm?.touched)">
                            <em *ngIf="preferenceForm.controls.structuralOptInInd?.errors?.['required']">Select 'Yes' if you would like to opt in for structural claim self-report or else select 'No'.</em>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!--Payment Preferences-->
            <ng-container *ngIf="showPaymentPreferences">
                <div class="col-12 mt-2">
                    <h4 *ngIf="paymentOptInHeader != 'PreferencePayment'">{{paymentOptInHeader}}</h4>
                    <label for="paymentType" class="pb-2 required"><strong>{{paymentOptInQuestion}}</strong></label>
                    <ng-container *ngIf="(achAccountList != undefined && achAccountList?.length > 0) || isEserviceUser">
                        <div class="col-12 py-0">
                            <input type="radio" id="ex_ach" name="paymentType" value="ex_ach" [(ngModel)]="preferenceData.paymentTypeCd" required (click)="togglePaymentOptInType('ex_ach')">
                            <label for="ex_ach" class="radio">Electronic Transfer</label>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="paymentOptInTypeAchToggle">
                        <div class="flex flex-column col-12 md:col-10 gray-box mb-2">
                            <ng-container *ngIf="paymentOptInTypeAchToggle && isEserviceUser">
                                <div class="col-12 py-0">
                                    <input type="radio" id="achAccountAddedN" name="achAccountAdded" value="N" [(ngModel)]="preferenceData.achAccountAddedInd" [disabled]="this.achAccountDropDownList.length == 0" (click)="toggleAddNewAccount('ex_ach','N')">
                                    <label for="achAccountAddedN" class="radio"><b>Use Existing Account</b></label>
                                    <input type="radio" id="achAccountAddedY" name="achAccountAdded" value="Y" [(ngModel)]="preferenceData.achAccountAddedInd" (onClick)="toggleAddNewAccount('ex_ach','Y')">
                                    <label for="achAccountAddedY" class="radio"><b>Add Bank Account</b></label>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="paymentOptInTypeAchToggle && paymentOptInTypeAchUseExistingToggle">
                                <div class="flex justify-content-start flex-wrap card-container col-10">
                                    <label class="ml-2 mr-5 align-items-center"><strong>To account ending in</strong></label>
                                    <p-dropdown name="achAccount" [options]="achAccountDropDownList" [(ngModel)]="preferenceData.achAccount" optionLabel="name"></p-dropdown>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="paymentOptInTypeAchToggle && paymentOptInTypeAchAddNewToggle">
                                <div class="gray-box col-12 md:col-10  pb-3">
                                    <div class="flex flex-column col-8">
                                        <label class=""><b>Bank Account Type</b></label>
                                        <div class="col-12 py-0">
                                            <input type="radio" id="saving" name="achAccountType" value="saving" [(ngModel)]="preferenceData.achAccountType" required>
                                            <label for="saving" class="radio">Saving</label>
                                            <input type="radio" id="checking" name="achAccountType" value="checking" [(ngModel)]="preferenceData.achAccountType" required>
                                            <label for="checking" class="radio">Checking</label>
                                        </div>
                                        <div class="text-red-500" *ngIf="preferenceForm.controls.achAccountType?.invalid && (preferenceForm.controls.achAccountNumber?.dirty || preferenceForm.controls.achAccountNumber?.touched)">
                                            <em *ngIf="preferenceForm.controls.achAccountType?.errors?.['required']">Bank Account Type is required.</em>
                                        </div>
                                    </div>
                                    <div class="flex flex-column col-12">
                                        <label class=" required"><b>Bank Account #</b></label>
                                        <input pInputText name="achAccountNumber" inputId="achAccountNumber" [(ngModel)]="preferenceData.achAccountNumber" [pKeyFilter]="'int'" maxlength="17" required #achAccountNumber/>
                                        <div class="text-red-500" *ngIf="preferenceForm.controls.achAccountNumber?.invalid && (preferenceForm.controls.achAccountNumber?.dirty || preferenceForm.controls.achAccountNumber?.touched)">
                                            <em *ngIf="preferenceForm.controls.achAccountNumber?.errors?.['required']">Bank Account # is required.</em>
                                        </div>
                                    </div>
                                    <div class="flex flex-column col-12">
                                        <label class=" required"><b>Confirm Bank Account #</b></label>
                                        <input pInputText name="achAccountNumberConfirm" [(ngModel)]="achAccountNumberConfirm" [pKeyFilter]="'int'" maxlength="17" required/>
                                        <div class="text-red-500" *ngIf="preferenceForm.controls.achAccountNumberConfirm?.invalid && (preferenceForm.controls.achAccountNumberConfirm?.dirty || preferenceForm.controls.achAccountNumberConfirm?.touched)">
                                            <em *ngIf="preferenceForm.controls.achAccountNumberConfirm?.errors?.['required']">Confirm Bank Account # is required.</em>
                                        </div>
                                        <div class="text-red-500" *ngIf="!preferenceForm.controls.achAccountNumberConfirm?.invalid">
                                            <em *ngIf="achAccountNumber.value != achAccountNumberConfirm">Bank Account Numbers do not match.</em>
                                        </div>
                                    </div>
                                    <div class="flex flex-column col-12">
                                        <label class=" required"><b>Bank Rounting #</b></label>
                                        <input pInputText name="achRoutingNumber" [(ngModel)]="preferenceData.achRoutingNumber" [pKeyFilter]="'int'" minlength="9" maxlength="9" required #achRoutingNumber/>
                                        <div class="text-red-500" *ngIf="preferenceForm.controls.achRoutingNumber?.invalid && (preferenceForm.controls.achRoutingNumber?.dirty || preferenceForm.controls.achRoutingNumber?.touched)">
                                            <em *ngIf="preferenceForm.controls.achRoutingNumber?.errors?.['required']">Bank Routing # is required.</em>
                                            <em *ngIf="preferenceForm.controls.achRoutingNumber?.errors?.['minlength']">Bank Routing # must be 9 digits.</em>
                                        </div>
                                    </div>
                                    <div class="flex flex-column col-12">
                                        <label class="required"><b>Confirm Bank Rounting #</b></label>
                                        <input pInputText name="achRoutingNumberConfirm" inputId="achRoutingNumberConfirm" [(ngModel)]="achRoutingNumberConfirm" [pKeyFilter]="'int'" minlength="9" maxlength="9" required/>
                                        <div class="text-red-500" *ngIf="preferenceForm.controls.achRoutingNumberConfirm?.invalid && (preferenceForm.controls.achRoutingNumberConfirm?.dirty || preferenceForm.controls.achRoutingNumberConfirm?.touched)">
                                            <em *ngIf="preferenceForm.controls.achRoutingNumberConfirm?.errors?.['required']">Confirm Bank Routing # is required.</em>
                                            <em *ngIf="preferenceForm.controls.achRoutingNumberConfirm?.errors?.['minlength']">Confirm Bank Routing # must be 9 digits.</em>
                                        </div>
                                        <div class="text-red-500" *ngIf="!preferenceForm.controls.achRoutingNumberConfirm?.invalid">
                                            <em *ngIf="achRoutingNumber.value != achRoutingNumberConfirm">Bank Routing Numbers do not match.</em>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="(zelleAccountList != undefined && zelleAccountList?.length > 0) || isEserviceUser">
                        <div class="col-12 py-0">
                            <input type="radio" id="zelle" name="paymentType" value="zelle" [(ngModel)]="preferenceData.paymentTypeCd" required (click)="togglePaymentOptInType('zelle')">
                            <label for="zelle" class="radio">Zelle</label>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="paymentOptInTypeZelleToggle">
                        <div class="flex flex-column col-12 gray-box md:col-10  mb-2">
                            <ng-container *ngIf="paymentOptInTypeZelleToggle && isEserviceUser">
                                <div class="col-12 py-0">
                                    <input type="radio" id="zelleAccountAddedN" name="zelleAccountAdded" value="N" [(ngModel)]="preferenceData.zelleAccountAddedInd" [disabled]="this.zelleAccountDropDownList.length == 0" (click)="toggleAddNewAccount('zelle','N')">
                                    <label for="zelleAccountAddedN" class="radio"><b>Use Existing Account</b></label>
                                    <input type="radio" id="zelleAccountAddedY" name="zelleAccountAdded" value="Y" [(ngModel)]="preferenceData.zelleAccountAddedInd" (click)="toggleAddNewAccount('zelle','Y')">
                                    <label for="zelleAccountAddedY" class="radio"><b>Add Zelle Account</b></label>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="paymentOptInTypeZelleToggle && paymentOptInTypeZelleUseExistingToggle">
                                <div class="flex justify-content-start flex-wrap card-container col-10">
                                    <label class="ml-2 mr-5 align-items-center"><strong>To account ending in</strong></label>
                                    <p-dropdown name="zelleAccount" [options]="zelleAccountDropDownList" [(ngModel)]="preferenceData.zelleAccount" optionLabel="name"></p-dropdown>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="paymentOptInTypeZelleToggle && paymentOptInTypeZelleAddNewToggle">
                                <div class="gray-box col-12 md:col-10  pb-3">
                                    <div class="flex flex-column col-8">
                                        <label class="mt-2"><b>Zelle Account Type</b></label>
                                        <div class="col-12 py-0">
                                            <input type="radio" id="email" name="zelleAccountType" value="email" [(ngModel)]="preferenceData.zelleAccountType">
                                            <label for="email" class="radio">Email</label>
                                            <input type="radio" id="phone" name="zelleAccountType" value="phone" [(ngModel)]="preferenceData.zelleAccountType">
                                            <label for="phone" class="radio">Phone</label>
                                        </div>                                        
                                    </div>
                                    <ng-container *ngIf="preferenceData.zelleAccountType === 'email'">
                                        <div class="flex flex-column col-12">
                                            <label class=" required"><b>Email</b></label>
                                            <input pInputText name="zelleEmail" inputId="zelleEmail" [(ngModel)]="preferenceData.zelleEmail" required email #zelleEmail/>
                                            <div class="text-red-500" *ngIf="preferenceForm.controls.zelleEmail?.invalid && (preferenceForm.controls.zelleEmail?.dirty || preferenceForm.controls.zelleEmail?.touched)">
                                                <em *ngIf="preferenceForm.controls.zelleEmail?.errors?.['required']">Email is required.</em>
                                                <em *ngIf="preferenceForm.controls.zelleEmail?.errors?.['email']">Email is invalid.</em>
                                            </div>
                                        </div>
                                        <div class="flex flex-column col-12">
                                            <label class=" required"><b>Confirm Email</b></label>
                                            <input pInputText name="zelleEmailConfirm" inputId="zelleEmailConfirm" [(ngModel)]="zelleEmailConfirm" required email/>
                                            <div class="text-red-500" *ngIf="preferenceForm.controls.zelleEmailConfirm?.invalid && (preferenceForm.controls.zelleEmailConfirm?.dirty || preferenceForm.controls.zelleEmailConfirm?.touched)">
                                                <em *ngIf="preferenceForm.controls.zelleEmailConfirm?.errors?.['required']">Confirm Email is required.</em>
                                                <em *ngIf="preferenceForm.controls.zelleEmailConfirm?.errors?.['email']">Confirm Email is invalid.</em>
                                            </div>
                                            <div class="text-red-500" *ngIf="!preferenceForm.controls.zelleEmailConfirm?.invalid">
                                                <em *ngIf="zelleEmail.value != zelleEmailConfirm">Emails do not match.</em>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="preferenceData.zelleAccountType === 'phone'">
                                        <div class="flex flex-column col-12">
                                            <label class=" required"><b>Phone Number</b></label>
                                            <p-inputMask name="zellePhoneNumber" mask="(999) 999-9999" [(ngModel)]="preferenceData.zellePhoneNumber" placeholder="(999) 999-9999" [required]="true" #zellePhoneNumber [unmask]="true"/>
                                            <div class="text-red-500" *ngIf="preferenceForm.controls.zellePhoneNumber?.invalid && (preferenceForm.controls.zellePhoneNumber?.dirty || preferenceForm.controls.zellePhoneNumber?.touched)">
                                                <em *ngIf="preferenceForm.controls.zellePhoneNumber?.errors?.['required']">Phone Number is required.</em>
                                            </div>
                                        </div>
                                        <div class="flex flex-column col-12">
                                            <label class=" required"><b>Confirm Phone Number</b></label>
                                            <p-inputMask name="zellePhoneNumberConfirm" mask="(999) 999-9999" [(ngModel)]="zellePhoneNumberConfirm" placeholder="(999) 999-9999" [required]="true" [unmask]="true" />
                                            <div class="text-red-500" *ngIf="preferenceForm.controls.zellePhoneNumberConfirm?.invalid && (preferenceForm.controls.zellePhoneNumberConfirm?.dirty || preferenceForm.controls.zellePhoneNumberConfirm?.touched)">
                                                <em *ngIf="preferenceForm.controls.zellePhoneNumberConfirm?.errors?.['required']">Confirm Phone Number is required.</em>
                                                <em *ngIf="zellePhoneNumber.value != zellePhoneNumberConfirm">Phone Numbers do not match.</em>
                                            </div>
                                            <div class="text-red-500" *ngIf="!preferenceForm.controls.zellePhoneNumberConfirm?.invalid">
                                                <em *ngIf="zellePhoneNumber.value != zellePhoneNumberConfirm">Phone Numbers do not match.</em>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <div class="col-12 py-0">
                        <input type="radio" id="check" name="paymentType" value="check" [(ngModel)]="preferenceData.paymentTypeCd" required (click)="togglePaymentOptInType('check')">
                        <label for="check" class="radio">Check</label>
                    </div>
                    <div class="flex field-checkbox align-items-center pt-2 text-red-500" *ngIf="preferenceForm.controls.paymentType?.invalid && (preferenceForm?.dirty || preferenceForm?.touched)">
                        <em *ngIf="preferenceForm.controls.paymentType?.errors?.['required']">Select how would you like to receive your claim payment.</em>
                    </div>
                    <ng-container *ngIf="paymentOptInNotes != 'PreferencePayment'">
                        <div class="col-12 more-info">
                            <div innerHTML="{{paymentOptInNotes}}"></div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <!--NMC-22-0145: HO – Demand Estimate Portal-->
            <!--Estimate Demand Contractor Preferences-->
            <ng-container *ngIf="estDmdContrOptInHeader != 'PreferenceContractor' && estimateDemandEligibleInd === 'Y' && (insCallerInd === 'Y' || claimSourceCd === 'AI')">
                <div class="col-12 mt-2">
                    <h4>{{estDmdContrOptInHeader}}</h4>
                    <div>
                        <ng-container *ngIf="estDmdContrOptInQuestion != 'PreferenceContractor'">
                            <label for="estDmdContrOptInInd" class="pb-0 required"><strong innerHTML="{{estDmdContrOptInQuestion}}"></strong></label>
                            <div class="input-group mb-3">
                                <p-selectButton name="estDmdContrOptInInd" [options]="optionsYesNo" [(ngModel)]="preferenceData.estDmdContrOptInInd" optionLabel="label" optionValue="value" class="button w-50" required (click)="toggleEstimateDemandContractor(preferenceData.estDmdContrOptInInd)"></p-selectButton>
                            </div>
                            <div class="text-red-500" *ngIf="preferenceForm.controls.estDmdContrOptInInd?.invalid && (preferenceForm?.dirty || preferenceForm?.touched)">
                                <em *ngIf="preferenceForm.controls.estDmdContrOptInInd?.errors?.['required']">Select 'Yes' if you have an estimate from a contractor you prefer to use or else select 'No'.</em>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="estDmdContrOptInToggle">
                            <div class="gray-box col-12 md:col-10">
                                <div class="flex flex-column col-10" *ngIf="insCallerInd === 'Y' || this.otherCallerInd">
                                    <label class="required"><b>Contractor Name</b></label>
                                    <input pInputText name="estDmdContrNm" [(ngModel)]="preferenceData.estDmdContrNm" maxlength="30" required/>
                                    <div class="text-red-500" *ngIf="preferenceForm.controls.estDmdContrNm?.invalid && (preferenceForm?.dirty || preferenceForm?.touched)">
                                        <em *ngIf="preferenceForm.controls.estDmdContrNm?.errors?.['required']">Contractor Name is required.</em>
                                    </div>
                                </div>
                                <div class="flex flex-column col-10">
                                    <label class="required"><b>Contractor Email</b></label>
                                    <input pInputText name="estDmdContrEMail" class="form-control" [(ngModel)]="preferenceData.estDmdContrEMail" email required/>
                                    <div class="text-red-500" *ngIf="preferenceForm.controls.estDmdContrEMail?.invalid">
                                        <em *ngIf="preferenceForm.controls.estDmdContrEMail?.errors?.['required']">Contractor Email is required.</em>
                                        <em *ngIf="preferenceForm.controls.estDmdContrEMail?.errors?.['email']">Contractor Email is invalid.</em>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <!--Contact Preferences-->
            <ng-container *ngIf="contactHeader != 'PreferenceContact'">
                <div class="col-12 mt-2">
                    <h4>{{contactHeader}}</h4>
                    <!--Insured Contact Information-->
                    <label *ngIf="contactNotes != 'PreferenceContact'"><strong>{{contactNotes}}</strong></label>
                    <div class="gray-box col-12 md:col-10">
                        <div class="flex flex-column col-10" *ngIf="insCallerInd === 'Y' || this.otherCallerInd">
                            <label class="required"><b>First Name</b></label>
                            <input pInputText name="contactFirstName" [(ngModel)]="preferenceData.contactFirstName" maxlength="80" required/>
                            <div class="text-red-500" *ngIf="preferenceForm.controls.contactFirstName?.invalid && (preferenceForm.controls.contactFirstName?.dirty || preferenceForm.controls.contactFirstName?.touched)">
                                <em *ngIf="preferenceForm.controls.contactFirstName?.errors?.['required']">First Name is required.</em>
                            </div>
                        </div>
                        <div class="flex flex-column col-10" *ngIf="insCallerInd === 'Y' || this.otherCallerInd">
                            <label class="required"><b>Last Name</b></label>
                            <input pInputText name="contactLastName" class="form-control" [(ngModel)]="preferenceData.contactLastName" maxlength="80" required/>
                            <div class="text-red-500" *ngIf="preferenceForm.controls.contactLastName?.invalid && (preferenceForm.controls.contactLastName?.dirty || preferenceForm.controls.contactLastName?.touched)">
                                <em *ngIf="preferenceForm.controls.contactLastName?.errors?.['required']">Last Name is required.</em>
                            </div>
                        </div>
                        <div class="flex flex-column col-10">
                            <label *ngIf="insCallerInd === 'Y' || this.otherCallerInd"><b>Email</b></label>
                            <label *ngIf="claimSourceCd === 'AI'"><b>Customer Email</b></label>
                            <input pInputText name="contactEMail" class="form-control" [(ngModel)]="preferenceData.contactEMail" email [required]="(preferenceData.notificationOptInInd=='Y' && (preferenceData.notificationTypeCd=='email'||preferenceData.notificationTypeCd=='both'))"/>
                            <div class="text-red-500" *ngIf="preferenceForm.controls.contactEMail?.invalid">
                                <em *ngIf="(insCallerInd === 'Y' ||  this.otherCallerInd) && preferenceForm.controls.contactEMail?.errors?.['required']">Email is required.</em>
                                <em *ngIf="claimSourceCd === 'AI' && preferenceForm.controls.contactEMail?.errors?.['required']">Customer Email is required.</em>
                                <em *ngIf="(insCallerInd === 'Y' ||  this.otherCallerInd) && preferenceForm.controls.contactEMail?.errors?.['email']">Email is invalid.</em>
                                <em *ngIf="claimSourceCd === 'AI' && preferenceForm.controls.contactEMail?.errors?.['email']">Customer Email is invalid.</em>
                            </div>
                        </div>
                        <div class="flex flex-column col-10">
                            <div class="flex flex-column">
                                <label><b>Cell Phone</b></label>
                                <div class="w-full">
                                    <p-inputMask name="contactCellPhNum"  mask="(999) 999-9999" [(ngModel)]="preferenceData.contactCellPhNum" placeholder="(999) 999-9999" [required]="(preferenceData.notificationOptInInd=='Y' && (preferenceData.notificationTypeCd=='text'||preferenceData.notificationTypeCd=='both')) || preferenceData.structuralOptInInd=='Y'" [unmask]="true"/>
                                </div>
                                <div class="text-red-500" *ngIf="preferenceForm.controls.contactCellPhNum?.invalid">
                                    <em *ngIf="preferenceForm.controls.contactCellPhNum?.errors?.['required']">Cell Phone is required.</em>
                                </div>
                            </div>
                            <ng-container *ngIf="contactHomePhNumToggle">
                                <div class="flex flex-column mt-2">
                                    <label><b>Home Phone</b></label>
                                    <div class="w-full">
                                        <p-inputMask name="contactHomePhNum" [style]="{'width':'74%'}"  mask="(999) 999-9999" [(ngModel)]="preferenceData.contactHomePhNum" placeholder="(999) 999-9999" [unmask]="true"/>
                                        <p-button icon="pi pi-minus-circle" styleClass="p-button-link" class="btn align-items-center" (click)="toggleContactPhNum('hm_phn')"></p-button>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="contactWorkPhNumToggle">
                                <div class="flex flex-column mt-2">
                                    <label><b>Business Phone</b></label>
                                    <div class="w-full">
                                        <p-inputMask name="contactWorkPhNum" [style]="{'width':'74%'}"mask="(999) 999-9999" [(ngModel)]="preferenceData.contactWorkPhNum" placeholder="(999) 999-9999" [unmask]="true"/>
                                        <p-button icon="pi pi-minus-circle" styleClass="p-button-link" class="btn align-items-center" (click)="toggleContactPhNum('busn_phn')"></p-button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <ng-container *ngIf="!contactHomePhNumToggle || !contactWorkPhNumToggle">
                            <div class="flex flex-column mt-2">
                                <span class="justify-content-left"><p-button label="Add another phone number" icon="pi pi-plus-circle" styleClass="p-button-link" class="btn mr-2" (click)="toggleContactPhNum()"></p-button></span>
                            </div>
                        </ng-container>
                    </div>
                    <!--Agent Contact Information-->
                    <ng-container *ngIf="claimSourceCd === 'AI'">
                        <div class="col-12 mt-2">
                            <label *ngIf="contactAgentNotes != 'PreferenceContact'"><strong>{{contactAgentNotes}}</strong></label>
                            <div class="gray-box col-12 md:col-10" *ngIf="claimSourceCd === 'AI'">
                                <div class="flex flex-column col-10">
                                    <label class="required"><b>My First Name</b></label>
                                    <input pInputText name="agentFirstName" class="form-control" [(ngModel)]="preferenceData.agentFirstName" required/>
                                    <div class="text-red-500" *ngIf="preferenceForm.controls.agentFirstName?.invalid && (preferenceForm.controls.agentFirstName?.dirty || preferenceForm.controls.agentFirstName?.touched)">
                                        <em *ngIf="preferenceForm.controls.agentFirstName?.errors?.['required']">First Name is required.</em>
                                    </div>
                                </div>
                                <div class="flex flex-column col-10">
                                    <label class="required"><b>My Last Name</b></label>
                                    <input pInputText name="agentLastName" class="form-control" [(ngModel)]="preferenceData.agentLastName" required/>
                                    <div class="text-red-500" *ngIf="preferenceForm.controls.agentLastName?.invalid && (preferenceForm.controls.agentLastName?.dirty || preferenceForm.controls.agentLastName?.touched)">
                                        <em *ngIf="preferenceForm.controls.agentLastName?.errors?.['required']">Last Name is required.</em>
                                    </div>
                                </div>
                                <div class="flex flex-column col-10">
                                    <label><b>My Email</b></label>
                                    <input pInputText name="agentEMail" class="form-control" [(ngModel)]="preferenceData.agentEMail" [required]="preferenceData.notificationOptInInd === 'Y'" email/>
                                    <div class="text-red-500" *ngIf="preferenceForm.controls.agentEMail?.invalid && (preferenceForm.controls.agentEMail?.dirty || preferenceForm.controls.agentEMail?.touched)">
                                        <em *ngIf="preferenceForm.controls.agentEMail?.errors?.['required']">Email is required.</em>
                                        <em *ngIf="preferenceForm.controls.agentEMail?.errors?.['email']">Email is invalid.</em>
                                    </div>
                                </div>
                                <div class="flex flex-column col-10">
                                    <label><b>My Phone Number</b></label>
                                    <div class="w-full">
                                        <p-inputMask name="agentWorkPhNum" mask="(999) 999-9999" [(ngModel)]="preferenceData.agentWorkPhNum" placeholder="(999) 999-9999" [unmask]="true"/>
                                    </div>
                                </div>
                                <ng-container *ngIf="notificationOptInQuestion != 'notificationOptInQuestion'">
                                    <div class="flex flex-column col-12">
                                        <label for="notificationOptInInd required"><strong innerHTML="{{notificationOptInQuestion}}"></strong></label>
                                        <div class="input-group mb-3">
                                            <p-selectButton name="notificationOptInInd" [options]="optionsYesNo" [(ngModel)]="preferenceData.notificationOptInInd" optionLabel="label" optionValue="value" class="button w-50" required></p-selectButton>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <div class="col-12 mt-2">
                <span><button type="button" class="btn btn-outline-secondary mr-2" (click)="goBack()">< Back</button></span>
                <span><button type="button" class="btn btn-primary" [disabled]="preferenceForm.control.invalid" (click)="submitPreferencePage()">Save & Continue</button></span>
            </div>
        </div>
    </form>
    <!-- <div class="col-12">
        <div class="mobile-message">
            <label><b>Auto and Home Claims</b></label>
            <div>
                <label class="lbl-call">
                    <a ng-show="showTelLink" class="link-success" href="tel:+1844-346-1225" >844-346-1225</a> 
                </label>
            </div>
        </div>
    </div> -->
</div>
<!-- <div class="home-col home-right-col">
    <div class="sidebar-title">
        <span class="">Auto and Home Claims
            <div class="sidebar-box-blue">
                <label class="lbl-call">
                    <a ng-show="showTelLink" class="link-success" href="tel:+1844-346-1225" >844-346-1225</a> 
                </label>
            </div>
        </span>
    </div>
</div> -->