
<form #formlyform [formGroup]="form" (ngSubmit)="onSubmit(model)">
    <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>

  </form>
  <div class="col-12 md:col-3 d-grid p-0">
    <button *ngIf="pageConfig?.props?.showBackButton == true"  class="btn btn-outline-secondary mr-2" (click)="previousPage()"> &lt; Back</button>
    <button  *ngIf="pageConfig?.props?.showSubmitButton == true" [disabled]="!form.valid" type="submit" class="btn btn-primary mb-2"
    (click)="onSubmit(model)"
    >{{pageConfig.props.submitButtonLabel}}</button>
  </div>

<formly-form [form]="postForm" [fields]="postFields" ></formly-form>
