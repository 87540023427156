import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { delay } from 'rxjs';
import { ClaimCommonService } from 'src/app/services/claim-common.service';
import { LookupService } from 'src/app/services/lookup.service';
import { ContextService } from 'src/app/utils/context.service';

@Component({
  selector: 'app-incident',
  templateUrl: './incident.component.html',
  styleUrls: ['./incident.component.scss']
})
export class IncidentComponent implements OnInit{

  @BlockUI() blockUI!: NgBlockUI;

   incidentData : {incidentDate?: Date, incidentTime?: String } = {}

  value1: string = "AM";
  stateOptions = [{label: 'AM', value: 'AM'}, {label: 'PM', value: 'PM'}];

  constructor(private lookupService:LookupService, 
              private claimsCommonService:ClaimCommonService, 
              private claimsContext: ContextService){

  }

  ngOnInit(): void {
    this.blockUI.start("Please wait...");
    this.lookupService.getLookupByType('efnolrelationshiptoinsured')
    .pipe(
      delay(3000)
    )
    .subscribe({next: response =>{
      console.log(response);
      this.blockUI.stop();
    },
     error: err=>{
      this.blockUI.stop();
     }  
  })
  }

  submitData(){
    console.log(this.incidentData);
  }

}
