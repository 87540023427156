<div class="home-col home-left-col">
    <h1 class="page-title" *ngIf="vendorTitle != 'VendorTitle'">{{vendorTitle}}</h1>
    <form #vendorForm="ngForm">
        <div class="grid p-fluid form-group">
            <!--Water Mitigation Preferences-->
            <ng-container *ngIf="waterMitigationOptInQuestion != 'VendorWaterMitigation' && insCallerInd === 'Y' && waterMitigationEligibleInd === 'Y'">
                <div class="col-12 mt-2">
                    <label for="waterMitigationOptInInd" class="pb-0 required"><strong innerHTML="{{waterMitigationOptInQuestion}}"></strong></label>
                    <div class="input-group mb-3">
                        <p-selectButton name="waterMitigationOptInInd" [options]="optionsYesNo" [(ngModel)]="vendorData.waterMitigationOptInInd" optionLabel="label" optionValue="value" class="button w-50" required (click)="toggleWaterMitigationOptInNotes(vendorData.waterMitigationOptInInd)"></p-selectButton>
                    </div>
                    <div class="text-red-500" *ngIf="vendorForm.controls.waterMitigationOptInInd?.invalid && (vendorForm?.touched)">
                        <em *ngIf="vendorForm.controls.waterMitigationOptInInd?.errors?.['required']">Select 'Yes' to use one of our preferred vendors or else select 'No'.</em>
                    </div>
                </div>
                <ng-container *ngIf="waterMitigationOptInNotes != 'PreferenceWaterMitigation' && waterMitigationOptInNotesToggle">
                    <div class="col-12 mt-2">
                        <label><strong innerHTML="{{waterMitigationOptInNotes}}"></strong></label>
                    </div>
                </ng-container>
            </ng-container>
            <!--Water Mitigation Vendor Services-->
            <ng-container *ngIf="waterMitigationVendorHeader != 'VendorWaterMitigation' && waterMitigationOptInNotesToggle">
                <!--Insured Policy Address-->
                <ng-container *ngIf="waterMitigationPolicyHeader != 'VendorWaterMitigation'">
                    <div class="col-12 mt-2">
                        <label class="pb-0"><strong innerHTML="{{waterMitigationPolicyHeader}}"></strong></label>
                        <div class="flex flex-column gray-box">
                            <span><label class="pt-1 pb-0 ml-2" innerHTML="{{policyAddrLn1Txt}}"></label></span>
                            <span><label class="pt-0 pb-1 ml-2" innerHTML="{{policyCityName}},&nbsp;{{policyStateCd}}&nbsp;{{policyZipCode}}"></label></span>
                        </div>
                    </div>
                </ng-container>
                <!--Water Mitigation Vendor List-->
                <div class="col-12 mt-2">
                    <ng-container *ngIf="vendorWaterMitigationCount === '0' && waterMitigationVendorNotFoundNotes != 'VendorWaterMitigation'">
                        <label class="pb-0"><strong innerHTML="{{waterMitigationVendorNotFoundNotes}}"></strong></label>
                    </ng-container>
                    <ng-container *ngIf="vendorWaterMitigationCount != '0'">
                        <ng-container *ngIf="waterMitigationVendorNotes != 'VendorWaterMitigation'">
                            <label class="pb-0"><strong innerHTML="{{vendorWaterMitigationCount}}&NonBreakingSpace;{{waterMitigationVendorNotes}}"></strong></label>
                        </ng-container>
                        <div class="flex flex-column">
                            <p-divider type="solid"></p-divider>
                        </div>
                        <div class="flex" *ngFor="let userVendor of userVendorList; let idx = index">
                            <i class="pi pi-map-marker pt-3" style="font-size: 1rem"></i>
                            <div class="flex flex-column col-7">
                                <label class="pb-0" innerHTML="{{userVendor.fullName}}"></label>
                                <ng-container *ngIf="showVndrAddrInd === 'Y'">
                                    <label class="pb-0" innerHTML="{{userVendor.addressLine}}"></label>
                                    <label class="pb-0" innerHTML="{{userVendor.city}},&nbsp;{{userVendor.stateCode}}&nbsp;{{userVendor.zipCode}}"></label>
                                </ng-container>
                                <a ng-show="showTelLink" class="link-success" href="tel:+1{{userVendor.phoneNumber}}">{{userVendor.phoneNumber}}</a>
                            </div>
                            <div class="flex align-items-center d-grid col-4 mx-auto">
                                <p-button (click)="toggleVendorConfirmationDialog(idx)" label="Select"></p-button>
                            </div>
                        </div>
                        <div class="flex flex-column">
                            <p-divider type="solid"></p-divider>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <p-dialog header="{{vendorConfirmationHeader}}"  [modal]="true" [closable]="false" [(visible)]="waterMitigationConfirmationToggle" [style]="{width: '35vw'}" [breakpoints]="{'960px': '35vw', '640px': '95vw'}">
                <div class="flex">
                    <i class="pi pi-map-marker pt-3 mr-2" style="font-size: 1rem"></i>
                    <div class="flex flex-column">
                        <label class="pb-0 pt-2" innerHTML="{{selectedVendor?.fullName}}"></label>
                        <ng-container *ngIf="showVndrAddrInd === 'Y'">
                            <label class="pb-0" innerHTML="{{selectedVendor?.addressLine}}"></label>
                            <label class="pb-0" innerHTML="{{selectedVendor?.city}},&nbsp;{{selectedVendor?.stateCode}}&nbsp;{{selectedVendor?.zipCode}}"></label>
                        </ng-container>
                        <label class="lbl-call"><a ng-show="showTelLink" class="link-success pb-3" href="tel:+1{{selectedVendor?.phoneNumber}}">{{selectedVendor?.phoneNumber}}</a></label>
                    </div>
                </div>
                <div class="d-grid gap-3">
                    <button type="button" class="btn btn-primary mt-3" innerHTML="{{vendorConfirmationYesButtonText}}" (click)="submitVendorPage(selectedVendor?.vndrClmPartyId)"></button>
                    <button type="button" class="btn btn-outline-primary" innerHTML="{{vendorConfirmationNoButtonText}}" (click)="submitVendorPage()"></button>
                    <button type="button" class="btn btn-outline-secondary" (click)="toggleVendorConfirmationDialog()">Cancel</button>
                </div>
            </p-dialog>
            <div class="col-12 mt-2">
                <span><button type="button" class="btn btn-outline-secondary mr-2" (click)="goBack()">< Back</button></span>
                <span><button type="button" class="btn btn-primary" [disabled]="vendorForm.control.invalid || (vendorData.waterMitigationOptInInd === 'Y' && vendorWaterMitigationCount != '0')" (click)="submitVendorPage()">Save & Continue</button></span>
            </div>
        </div>
    </form>
    <div class="col-12">
        <!-- <div class="mobile-message">
            <label><b>Auto and Home Claims</b></label>
            <div>
                <label class="lbl-call">
                    <a ng-show="showTelLink" class="link-success" href="tel:+1844-346-1225">844-346-1225</a> 
                </label>
            </div>
        </div>
    </div> -->
</div>
<div class="home-col home-right-col">
    <!-- <div class="sidebar-title">
        <span class="">Auto and Home Claims
            <div class="sidebar-box-blue">
                <label class="lbl-call">
                    <a ng-show="showTelLink" class="link-success" href="tel:+1844-346-1225">844-346-1225</a> 
                </label>
            </div>
        </span>
    </div> -->
</div>