import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClaimInitializeComponent } from './efnol/common/claim-initialize/claim-initialize.component';
import { IncidentComponent } from './efnol/common/incident/incident.component';
import { PreferenceComponent } from './efnol/common/preference/preference.component';
import { VendorComponent } from './efnol/common/vendor/vendor.component';
import { SubmissionComponent } from './efnol/common/submission/submission.component';
import { WrapupComponent } from './efnol/common/wrapup/wrapup.component';
import { HomeLossComponent } from './efnol/home/home-loss/home-loss.component';
import { FormlyTestComponent } from './efnol/formly/test/test.component';
import { CanActivateGuard } from './guard/can-activate.guard';
import { CanDeactivateGuard } from './guard/can-deactivate.guard';
import { MvpEstimateComponent } from './efnol/home/mvp-estimate/mvp-estimate.component';
import { AssignmentComponent } from './efnol/common/assignment/assignment.component';

const routes: Routes = [
  //{ path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'incident', component: IncidentComponent ,canActivate:[CanActivateGuard] ,data: { ga4Title : 'Incident Page'} },
  { path: 'preference', component: PreferenceComponent ,canActivate:[CanActivateGuard] ,data: { ga4Title : 'Preference Page'}},
  { path: 'vendor', component: VendorComponent ,canActivate:[CanActivateGuard] ,data: { ga4Title : 'Vendor Service Page'}},
  { path: 'assignment', component: AssignmentComponent ,canActivate:[CanActivateGuard] ,data: { ga4Title : 'User Assignment Page'}},
  { path: 'submission', component: SubmissionComponent ,canActivate:[CanActivateGuard] ,data: { ga4Title : 'Submission Page'}},
  { path: 'wrapup', component: WrapupComponent ,canDeactivate:[CanDeactivateGuard] ,data: { ga4Title : 'Wrapup Page'}},
  { path: 'ho-loss', component: HomeLossComponent ,canActivate:[CanActivateGuard] ,data: { ga4Title : 'Home Loss Page'}},
  { path: 'ho-estimate', component: MvpEstimateComponent ,data: { ga4Title : 'Home MVP Estimate Page'}},
  { path: 'claim', component: ClaimInitializeComponent ,canActivate:[CanActivateGuard] ,data: { ga4Title : 'Claim Initalize Page'}},
  // Only used for testing Formly component
  // Don't forget to comment me out
  //{ path: 'test', component:FormlyTestComponent ,data: { ga4Title : 'Test Page'}},
  { path: '**',  redirectTo : 'ho-loss' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
