<p-dialog header="Session Expire Warning" [(visible)]="displayModal" [modal]="true" [style]="{width: '300px'}"
    [draggable]="false" [resizable]="false" [closeOnEscape]="false" [closable]="false">
    <p class="m-0">In  <span id="minutes"> {{minutes}} </span>Min
                        <span id="seconds"> {{seconds}} </span>S
                        your session is going to expire, Please click continue to extend your session.</p>
        <ng-template pTemplate="footer">
        
        <p-button  icon="pi pi-check" (click)="logout()" label="No" styleClass="p-button-text"></p-button>
        <p-button  icon="pi pi-exclamation-triangle" (click)="extendSession()" label="continue" styleClass="p-button-text"></p-button>
        </ng-template>
</p-dialog>
