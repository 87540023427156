<div class="grid pb-3">
    <div class="col-12 pb-0 align-middle">
        <label class="" > <strong>{{field.props.label}}</strong></label>
    </div>

    <div class="col-12 md:col-8 ">
        <div class="grid  gray-box justify-content-between">
            <ng-container *ngFor="let mod of model; let i = index;" >
                <ng-container *ngIf="mod.completed">
                    <div class="col-8 md:col-8  pl-3"><b><small>{{ showData(mod) }}</small></b></div>
                    <div class="col-4 md:col-3">
                        
                        <a [routerLink]="" *ngIf="index == undefined" class="link-success cursor-pointer px-3"(click)="editItem(i, mod)">{{field.props.editLinkText}}</a>
                        <a [routerLink]="" *ngIf="index != undefined" [class.disabled]="index != undefined" class=" px-3">{{field.props.editLinkText}}</a>
                        <a [routerLink]="" *ngIf="index == undefined"  class="link-success cursor-pointer " (click)="removeItem(i)">{{field.props.removeLinkText}}</a>
                        <a [routerLink]="" *ngIf="index != undefined" [class.disabled]="index != undefined" >{{field.props.removeLinkText}}</a>
                    </div>    
                </ng-container>
            </ng-container>
        </div>
    </div>

    <div class="col-12  align-middle ">
        <a [routerLink]="" class="link-success cursor-pointer " *ngIf="index == undefined" (click)="addItem()"><i class="pi pi-plus-circle align-middle" style="font-size: 2rem" ></i><b> {{field.props.addText}}</b></a>
        <a [routerLink]=""  *ngIf="index != undefined" [class.disabled]="index != undefined" ><i class="pi pi-plus-circle align-middle" style="font-size: 2rem" ></i><b> {{field.props.addText}}</b></a>
    </div>

    <div   class="col-12  md:col-8 ">
        <div class="gray-box px-2">
            <ng-container *ngFor="let item of formControl.value; let i = index;">
                <ng-container  *ngIf="editFlagList[i]">
                  <form (ngSubmit)="updateItem(i)">
                    <formly-form [form]="foorm" [fields]="getFields()" [model]="moodel"></formly-form>
                    <div class="col-12">
                        <button  class="btn btn-outline-secondary mr-2" (click)="cancelItem(i)" > {{field.props.cancelButtonText}}</button>
                        <button  type="submit" class="btn btn-primary" > {{this.editMode ? field.props.updateButtonText : field.props.addButtonText}}</button>
                      </div>
                  </form>
                </ng-container>
              </ng-container>
        </div>
    </div>
</div>
