<div class="grid pb-3">
    <div class="col-12 ">
        <label class="required"><strong>{{props.label}}</strong></label>
    </div >

    <div class="col-12 md:col-5 py-0">
        <div   *ngFor="let radio of props['radios']; let i = index">
            <div  class="col-12  py-0"  *ngIf="i<getItemSize()">
            <input type="radio" [id]="radio.label" [name]="key+''"  [value]="radio.value" [formControl]="formControl" [formlyAttributes]="field">
            <label [for]="radio.label" class="radio">{{radio.label}}</label>
            <a *ngIf="radio['infoMessage']" [routerLink]="" class="pi pi-question-circle pl-2 cursor-pointer link-success p-0" (click)="showInfo($event,radio['infoMessage'],radio['infoMessage'])"></a>
        </div>
        </div >

    </div>
    <div class="col-12 md:col-5  py-0">
        <div   *ngFor="let radio of props['radios']; let i = index">
            <div class="col-12  py-0 "  *ngIf="i>=getItemSize()" >
            <input type="radio" [id]="radio.label" [name]="key+''"  [value]="radio.value" [formControl]="formControl" [formlyAttributes]="field">
            <label [for]="radio.label" class="radio">{{radio.label}}</label>
            <a *ngIf="radio['infoMessage']" [routerLink]="" class="pi pi-question-circle pl-2 cursor-pointer link-success p-0" (click)="showInfo($event,radio['infoMessage'],radio['infoMessage'])"></a>
        </div>
        </div >
        
    </div>

</div>


<p-confirmPopup [style]="{width: '300px', 'z-index':'2025'}" [autoZIndex]="false" key="info" ></p-confirmPopup>



