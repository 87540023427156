<form #formlyform [formGroup]="form" (ngSubmit)="onSubmit(model)">
    <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>

  </form>
  <div class="col-12">
    <button *ngIf="pageConfig?.props?.showBackButton == true"  class="btn btn-outline-secondary mr-2" (click)="previousPage()"> &lt; Back</button>
    <button *ngIf="pageConfig?.props?.showSubmitButton == true" [disabled]="!form.valid" type="submit" class="btn btn-primary"
    (click)="onSubmit(model)"
    >Save and Continue</button>
  </div>

  <!-- <div *ngIf="model.efnol_ho_appliance && showLog(form)">

  </div> -->
  
