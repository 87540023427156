import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//blockui
import { BlockUIModule } from 'ng-block-ui';

//primeng
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { KeyFilterModule } from 'primeng/keyfilter';
import { DividerModule } from 'primeng/divider';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import { CardModule } from 'primeng/card';


//Google Autocomplete
//https://www.npmjs.com/package/@angular-magic/ngx-gp-autocomplete
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './efnol/layout/header/header.component';
import { FooterComponent } from './efnol/layout/footer/footer.component';
import { IncidentComponent } from './efnol/common/incident/incident.component';
import { PreferenceComponent } from './efnol/common/preference/preference.component';
import { VendorComponent } from './efnol/common/vendor/vendor.component';
import { HomeLossComponent } from './efnol/home/home-loss/home-loss.component';
import { SubmissionComponent } from './efnol/common/submission/submission.component';
import { WrapupComponent } from './efnol/common/wrapup/wrapup.component';

import { CookieService } from 'ngx-cookie-service';
import { DisableCacheInterceptorService } from './utils/disableCache.interceptor.service';
import { ExceptionService } from './utils/exception.service';
import { InterceptorService } from './utils/interceptor.service';
import { ClaimInitializeComponent } from './efnol/common/claim-initialize/claim-initialize.component';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { ClaimIconComponent } from './efnol/formly/custom-types/claim-icon/claim-icon.component';
import { ContainerComponent } from './efnol/formly/custom-wrapper/container/container.component';
import { YesNoComponent } from './efnol/formly/custom-types/yes-no/yes-no.component';
import { RadioComponent } from './efnol/formly/custom-types/radio/radio.component';
import { ConfirmPopupComponent } from './efnol/formly/custom-types/confirm-popup/confirm-popup.component';
import { MultiCheckboxComponent } from './efnol/formly/custom-types/multi-checkbox/multi-checkbox.component';
import { TextareaComponent } from './efnol/formly/custom-types/textarea/textarea.component';
import { DynamicBasePageComponent } from './efnol/formly/dynamic-base-page/dynamic-base-page.component';
import { RepeatPartyInfoComponent } from './efnol/formly/custom-array-types/repeat-object/repeat-object.component';
import { FormlyTestComponent } from './efnol/formly/test/test.component';
import { InputComponent } from './efnol/formly/custom-types/input/input.component';
import { ChoiceComponent } from './efnol/formly/custom-types/choice/choice.component';
import { FileUploadComponent } from './efnol/formly/custom-types/file-upload/file-upload.component';
import { UserSessionComponent } from './efnol/user/user-session/user-session.component';
import {  provideUserIdleConfig } from 'angular-user-idle';
import { environment } from 'src/environments/environment';
import { CanActivateGuard } from './guard/can-activate.guard';
import { CanDeactivateGuard } from './guard/can-deactivate.guard';
import { GooglePlaceComponent } from './efnol/formly/custom-types/google-place/google-place.component';
import { CardComponent } from './efnol/formly/custom-types/card/card.component';
import { MessageInfoComponent } from './efnol/formly/custom-types/message-info/message-info.component';
import { MvpEstimateComponent } from './efnol/home/mvp-estimate/mvp-estimate.component';
import { AssignmentComponent } from './efnol/common/assignment/assignment.component';




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    IncidentComponent,
    PreferenceComponent,
    VendorComponent,
    HomeLossComponent,
    SubmissionComponent,
    WrapupComponent,
    ClaimInitializeComponent,
    ClaimIconComponent,
    ContainerComponent,
    YesNoComponent,
    RadioComponent,
    ConfirmPopupComponent,
    MultiCheckboxComponent,
    TextareaComponent,
    DynamicBasePageComponent,
    RepeatPartyInfoComponent,
    FormlyTestComponent,
    InputComponent,
    ChoiceComponent,
    FileUploadComponent,
    UserSessionComponent,
    GooglePlaceComponent,
    CardComponent,
    MessageInfoComponent,
    MvpEstimateComponent,
    AssignmentComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      types: [
        { name: 'claim-card', component: CardComponent },
        { name: 'claim-icon', component: ClaimIconComponent },
        { name: 'claim-input', component: InputComponent },
        { name: 'claim-radio', component: RadioComponent},
        { name: 'claim-choice', component: ChoiceComponent},
        { name: 'claim-file-upload', component: FileUploadComponent},
        { name: 'claim-yesno', component: YesNoComponent},
        { name: 'claim-confirm-popup', component: ConfirmPopupComponent},
        { name: 'claim-message-info', component: MessageInfoComponent},
        { name: 'claim-multi-checkbox', component: MultiCheckboxComponent},
        { name: 'claim-textarea', component: TextareaComponent},
        { name:'claim-repeat-object', component:RepeatPartyInfoComponent},
        {name:'claims-google-place', component:GooglePlaceComponent},
      ],
      wrappers: [
        { name: 'titlePanel', component: ContainerComponent },
      ]
    }),
    FormlyBootstrapModule,

    AppRoutingModule,
    ConfirmDialogModule,
    CheckboxModule,
    CardModule,
    DialogModule,
    ToastModule,
    CalendarModule,
    InputMaskModule,
    InputTextareaModule,
    FileUploadModule,
    NgxGpAutocompleteModule.forRoot(
      { loaderOptions: { apiKey: 'AIzaSyD20eeu3zPAii-sfpvHOhSfiiYXnnizguo',libraries: ["places"] } 
    }),
    SelectButtonModule,
    ConfirmPopupModule,
    RadioButtonModule,
    DropdownModule,
    AutoCompleteModule,
    KeyFilterModule,
    InputTextModule,
    InputNumberModule,
    DividerModule,
    BlockUIModule.forRoot({
      //delayStart: 300,
    }),
    NgxWebstorageModule.forRoot()
  ],
  providers: [
    MessageService,
    ConfirmationService,
    ExceptionService,
    CookieService,
    CanDeactivateGuard,
    CanActivateGuard,
    provideUserIdleConfig({ idle: environment.IdleTimeMin*60, timeout: environment.WarningTimeoutMin*60, ping: 10 }),
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DisableCacheInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
