import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { CookieService } from 'ngx-cookie-service';
import { SessionStorageService } from 'ngx-webstorage';
import { Subscription, interval, map, mergeMap, timer } from 'rxjs';
import { ClaimCommonService } from 'src/app/services/claim-common.service';
import { ContextService } from 'src/app/utils/context.service';
import { DataEventService } from 'src/app/utils/data-event.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-session',
  templateUrl: './user-session.component.html',
  styleUrls: ['./user-session.component.scss']
})
export class UserSessionComponent implements OnInit{
  
  kidSubs!: Subscription;

  public displayModal = false;
  public minutes:any;
  public seconds:any;
  public interval:any;

  constructor(private contextService : ContextService, 
    private claimsCommonService : ClaimCommonService,
    private userIdle: UserIdleService,
    private router:Router,
    private cookieService: CookieService,
    private sessionStorage : SessionStorageService,
    private eventService: DataEventService){

  }
  
  ngOnInit(): void {
    this.eventService.onData("app-initialized")
    .subscribe( data =>{
      this.manageKID();
      this.monitorUserIdel();
    });
  }





  /**
   * This method is responsible to Keep tracking of Kid, and renew it before expiry
   */
  manageKID() {
    
    this.kidSubs = interval((environment.KidTimeMin  
      -1)*60*1000)
    .pipe(
      map((res)=> {
        if (this.contextService.claimContext?.policyNumber)
        {
          return this.contextService.claimContext?.policyNumber;
        }else{
          throw new Error("User Session ended");
        }
      }),
      mergeMap( () => {return this.claimsCommonService.renewUser()})
    )
    .subscribe(
      (res:any)=>{
        // this.cookieService.set("kid",res.kid);
        // this.cookieService.set("kidTime",Date.now().toString());
      }
    );
  }



  /**
   * This section is responsible for handling User Idel Logic, 
   * If user is Idel for more than specified amount of time, then a warning is prompted to extend the User Session
   * If user dont take any action on the warning, then UI would be redirected to Login page
   * If user click extend the session, then the Timer is reset and session is extended based o nthe idel timeout time.
   */

  /**
   * monitorUserIdel : This method starts 2 timer.
   *  1) Starts Idel Timer , if No user Activity
   *  2) Start Warning Timer, after Idel Activity is timedout
   */
  monitorUserIdel() {
    this.startWatchingIdel()    
    .subscribe(
          count => {
            console.log("startWatchingIdel",count);
            if(count == 1){
              this.startWarningTimer();
              this.displayModal=true
            }
      });
        
        // Start watch when time is up.
      this.startWarningTimeout()
      .subscribe(
          () => {
            console.log(' startWarningTimeouttimeout')
            this.displayModal=false;
            this.logout()
        });
}

/**
 * This Warning timer, which is showing on the Dialog Window to let user that session is expring
 */
startWarningTimer() {
  clearInterval(this.interval);
  this.interval = null;
  let timeLeft = environment.WarningTimeoutMin * 60;
  this.minutes = Math.floor((timeLeft % (60 * 60)) / (60));
  this.seconds = Math.floor((timeLeft %  60) );
  this.interval = setInterval(() => {
      if(timeLeft > 0) {
       timeLeft--;
        this.minutes = Math.floor((timeLeft % (60 * 60)) / (60));
         this.seconds = Math.floor((timeLeft %  60) );
      } 
    },1000)
  }

  //When user clicks to extend session, before warning timeout 
  extendSession(){
    this.userIdle.stopTimer();
    this.displayModal=false;
  }

  //If user clicks logout or no action is taken, then clear Data and logout
  logout(){
    this.clearData();
    this.displayModal=false;
    (<any>window)['toOldEfnol'] = true;
    window.location.href = (environment.efnol1URL+'login');
  }


 private startWatchingIdel(){
    //Start watching for user inactivity.
    this.userIdle.resetTimer();
    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    return this.userIdle.onTimerStart()
  } 

 private startWarningTimeout(){
    return this.userIdle.onTimeout();
  }

 private clearData(){
    this.eventService.sendData(undefined,"LOGOUT");
    this.kidSubs && this.kidSubs.unsubscribe();
    this.userIdle.stopTimer();
    this.userIdle.stopWatching();
    this.cookieService.delete("kid");
    this.contextService.claimContext = {};
    
   }




}
