import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private messageService: MessageService) {
  }

  merge = (target: any, ...sources: any[]) => Object.assign(target, ...sources);

  clone<T>(source: T): T {
    return Object.assign({}, source);
  }

  copyMatchingProperties(target:any, source:any){
    return Object.keys(target).forEach(key =>{
      if(source[key] !== undefined)
        target[key] = source[key];
    })
  }

  showException(error:any) {

    let err = error && error.error ? JSON.stringify( error.error) : error;

    this.messageService.add({
      key: 'app',
      severity: 'error',
      summary: 'Failure',
      //sticky: true,
      
      detail: err ? err : 'Failed to perform an action, please try again later.',
    });
  }

  showMessage(summary:any,message:any) {
    this.messageService.add({
      key: 'app',
      severity: 'info',
      summary: summary,
      detail: message
    });
  }

  cloneDeep<T>(source: T): T {
    return JSON.parse(JSON.stringify(source));
  }

  equal = (entityA: any, entityB: any) =>
    Object.keys(entityA).find((key) => entityA[key] === entityB[key]);

  deepCopy(obj: any): any {
    let copy :any;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' !== typeof obj) {
      return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = this.deepCopy(obj[attr]);
        }
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  compareValues(key:any, order = 'asc') {
    return (a:any, b:any) => {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }
}
