<div class="grid pb-3">
    <div class="col-12 pb-0 align-middle">
        <p class="d-flex align-items-baseline mb-0">
            <label class="required"> <strong>{{props.label}} </strong></label>
            <a *ngIf="props['infoMessage']" [routerLink]="" class="pi pi-question-circle pl-2 cursor-pointer link-success" (click)="showInfo($event,props['infoMessage'],props['infoMessage'])"></a>
        </p>
    </div>
    <div class="col-10 md:col-4">
        <div class="input-group ">
            <ng-container *ngFor="let yesno of props['yesno']; let i = index">
                <input type="radio" class="btn-check " [name]="key+''"   [id]="key+yesno.label" [value]="yesno.value" [formControl]="formControl" [formlyAttributes]="field">
                <label class="btn btn-outline-success form-control " [class.yesno]='true' [class.left-radius]="i==0" [for]="key+yesno.label" >{{yesno.label}}</label>
            </ng-container>
            
        </div>
    </div>
</div>

<p-confirmPopup [style]="{width: '300px', 'z-index':'2025'}" [autoZIndex]="false" key="info" ></p-confirmPopup>

