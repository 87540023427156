import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map, of, retry, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ContextService } from '../utils/context.service';
import { ExceptionService } from '../utils/exception.service';
import { UtilService } from '../utils/util.service';
import { __values } from 'tslib';

@Injectable({
  providedIn: 'root'
})
export class ClaimCommonService {
  
  url: any = {
    efnolBaseUrl: environment.efnolBaseUrl,
    fnolBaseUrl: environment.fnolBaseUrl,
    profileBaseUrl: environment.profileBaseUrl
   }

  constructor(private httpClient: HttpClient, private util: UtilService, private contextService : ContextService,
    private cookieService: CookieService,private exceptionService:ExceptionService) { }


  renewUser(){
    return this.httpClient.get(this.url.efnolBaseUrl+'claim/'+
                                      this.contextService.claimContext.claimId+'/token?userId='+
                                      this.contextService.claimContext.policyNumber).pipe(
      map((res:any) =>{
        this.cookieService.set("kid",res.access_token);
        this.cookieService.set("kidTime",Date.now().toString());
        console.log("renewed User");
        return res;
      })
    );
  }

  getClaimData(claimId: any) {
    return this.httpClient.get(this.url.efnolBaseUrl+'claims/'+claimId+'/full')
    .pipe(
      catchError((error: any) => {
        return this.exceptionService.handleError(error);
      })
    );
  }

  getPreferencePageData(claimId: any) {
    return this.httpClient.get(this.url.efnolBaseUrl+'claims/'+claimId+'/preference')
    .pipe(
      catchError((error: any) => {
        return this.exceptionService.handleError(error);
      })
    );
  }

  savePreferencePageData(preferenceData: any) {
    const claimId = this.contextService.claimContext.claimId;
    return this.httpClient.post(this.url.efnolBaseUrl+'claims/'+claimId+'/preference',preferenceData)
    .pipe(
      catchError((error: any) => {
        return this.exceptionService.handleError(error);
      })
    );
  }

  getProfileUserData(userVendor: any) {
    return this.httpClient.post(this.url.profileBaseUrl+'users',userVendor)
    .pipe(
      catchError((error: any) => {
        return this.exceptionService.handleError(error);
      })
    );
  }

  getClaimPolicyData(claimId: any) {
    return this.httpClient.get(this.url.fnolBaseUrl+'claim/'+claimId+'/policy')
    .pipe(
      catchError((error: any) => {
        return this.exceptionService.handleError(error);
      })
    );
  }

  getVendorPageData(claimId: any) {
    return this.httpClient.get(this.url.efnolBaseUrl+'claims/'+claimId+'/vendor')
    .pipe(
      catchError((error: any) => {
        return this.exceptionService.handleError(error);
      })
    );
  }

  saveVendorPageData(vendorPage: any) {
    const claimId = this.contextService.claimContext.claimId;
    return this.httpClient.post(this.url.efnolBaseUrl+'claims/'+claimId+'/vendor',vendorPage)
    .pipe(
      catchError((error: any) => {
        return this.exceptionService.handleError(error);
      })
    );
  }


  getAssignmentPageData(claimId: any) {
    return this.httpClient.get(this.url.efnolBaseUrl+'claims/'+claimId+'/assignment')
    .pipe(
      catchError((error: any) => {
        return this.exceptionService.handleError(error);
      })
    );
  }

  saveAssignmentPageData(assignmentPage: any) {
    const claimId = this.contextService.claimContext.claimId;
    return this.httpClient.post(this.url.efnolBaseUrl+'claims/'+claimId+'/assignment',assignmentPage)
    .pipe(
      catchError((error: any) => {
        return this.exceptionService.handleError(error);
      })
    );
  }


  getSubmissionPageData(claimId: any) {
    return this.httpClient.get(this.url.efnolBaseUrl+'claims/'+claimId+'/submission')
    .pipe(
      catchError((error: any) => {
        return this.exceptionService.handleError(error);
      })
    );
  }

  saveSubmissionPageData(submissionPage: any) {
    const claimId = this.contextService.claimContext.claimId;
    return this.httpClient.post(this.url.efnolBaseUrl+'claims/'+claimId+'/submission',submissionPage)
    .pipe(
      catchError((error: any) => {
        return this.exceptionService.handleError(error);
      })
    );
  }

  getWrapupPageData(claimId: any) {
    return this.httpClient.get(this.url.efnolBaseUrl+'claims/'+claimId+'/wrapup')
    .pipe(
      catchError((error: any) => {
        return this.exceptionService.handleError(error);
      })
    );
  }

  getClaimUrlData(claimId: any, urlTypeCd: any) {
    var errorResponse = {claimUrl:"",claimUrlStsCd:"failed"};
    return this.httpClient.get(this.url.efnolBaseUrl+'claims/'+claimId+'/url/'+urlTypeCd)
    .pipe(
      catchError(() => of(errorResponse))
    );
  }

  getClaimUrlDataWithRetry(claimId: any, urlTypeCd: any) {
    var errorResponse = {claimUrl:"",claimUrlStsCd:"failed"};
    return of("getClaimUrlDataWithRetry").pipe(
      switchMap(() => this.getClaimUrlData(claimId, urlTypeCd)),
      map((response: any) => {
        if(response.claimUrlStsCd === "pending"){ // Pending Status
          throw "Claim URL Status is Pending";
        }
        return response;
      }),
      retry({count: 5, delay: 2000}),
      catchError(() => of(errorResponse))
    );
  }
}