import { Component, ElementRef, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-claim-icon',
  templateUrl: './claim-icon.component.html',
  styleUrls: ['./claim-icon.component.scss']
})
export class ClaimIconComponent  extends FieldType<FieldTypeConfig>{

  @ViewChild('submitButton') submitButton! : ElementRef;

  submitForm(iconValue:string){


    //Add iconType to model, if icontype exist for the given icon.
    let iconsList = this.props['icons'].filter((icon:any)=>{
      return icon.iconType && icon.value == iconValue;
      }
    );
    this.model['iconType'] = iconsList.length > 0 ? iconsList[0].iconType : undefined;


    //If Action is submit, the submit the formly component
    if(this.props['action'] == 'submit'){
      setTimeout(() => {
        this.submitButton.nativeElement.click();
      }, 100);
    }


  }

}
