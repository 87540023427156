import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldArrayType, FieldArrayTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-repeat-object',
  templateUrl: './repeat-object.component.html',
  styleUrls: ['./repeat-object.component.scss']
})
export class RepeatPartyInfoComponent extends FieldArrayType  {

  
  fiields:any;
  moodel:any = {};
  foorm:FormGroup = new FormGroup({})

  index = undefined; 
  editMode = false;
  editFlagList:boolean[] = [];

  /**
   * This will clone the Object Fields from the Main formly and use to render the sub formly section
   * Once its cloned, we reuse the same, instead of clonning everytime
   * @returns 
   */
  getFields(){
    if(!this.fiields){
      let fld = JSON.parse(JSON.stringify(this.field.fieldGroup));
      this.fiields = [fld[0]]; //Object.assign({},fld[0].fieldGroup, fld[0].fieldGroupClassName )
    }

    return this.fiields;
  }


   addItem(){
    let idx = this.model?.length;
    if(!idx){
      idx = 0;
    }

    this.add(idx, {});
    this.editFlagList[idx] = true;
    this.index = idx;
    this.moodel[0] = {};
  }

  editItem(idx:any, mod:any){
    this.moodel[0] = Object.assign({},mod);
    this.editFlagList[idx] = true;
    this.editMode = true;
    this.index = idx;
  }

  removeItem(idx:any){
    this.remove(idx);
  }

  /**
   * Update the Main Formly model with the data captured thru sub formly
   * @param idx 
   */
  updateItem(idx:any){
    this.editFlagList[idx] = false;
    this.moodel[0].completed = true;
    this.model[idx] = this.moodel[0];
    this.editMode = false;
    this.index = undefined;
  }

  /**
   * When Cancel Button is clicked,
   *  set editFlagList to false, so edit form is not displayed
   *  if editmode, do not remove element, else remove the item.
   * @param idx 
   */
  cancelItem(idx:any){
    this.editFlagList[idx] = false;
    if(this.editMode != true){
      this.remove(idx);
    }
    this.editMode = false;
    this.index = undefined;
  }

  showData(mod:any){
    if( this.props.displayInfoKey &&  this.props.displayInfoKey.length > 0){
      let val = '';
       this.props.displayInfoKey.forEach( (key:any) => {

        val = val+ ' ' + (mod[key] ? mod[key]  : '');
      });
      return val
    } 
    
    return "";
  }

}
