import { Injectable } from '@angular/core';
import { AppConfig, PageConfig } from '../model/AppConfig';


@Injectable({
  providedIn: 'root'
})
export class DynamicContextService {

  constructor() { }

  //Page Config Context
  #pageRouteMap: {[key: string]: PageConfig[]} = {};

  public pushPage(page:any, routeName:string){
    if(!this.#pageRouteMap[routeName]){
      this.#pageRouteMap[routeName] = [];
    }
    this.#pageRouteMap[routeName].push(page);
  }
  public popPage(routeName:string){
    return this.#pageRouteMap[routeName].pop();
  }

  public peekPreviousPage(routeName:string){
    return this.#pageRouteMap[routeName].at(-1);
  }

  public peekLastPage(){
    return Object.keys(this.#pageRouteMap)[0].at(-1);
  }

  public getPageStack(routeName:string){
    return this.#pageRouteMap[routeName];
  }

  hasPageForRoute(routeName:string){
    return this.#pageRouteMap[routeName]?.length > 0
  }

  //App Config Context

  #appConfig!:AppConfig;

  set appConfig(appConfig: AppConfig){
    this.#appConfig = appConfig;
  }

  get appConfig(){
   return this.#appConfig;
  }


  getStartingPage(){
    let startingPage = '';
    this.#appConfig.routes.forEach(route=>{
      if(route.routeName == this.#appConfig.startingRoute){
          startingPage = route.startingPage;
      }
    })

    return startingPage;
  }

  getStartingPageByRoute(routeUrl:string){
    let routeStartPage = '';
    this.#appConfig.routes.forEach((route:any) => {
      if(routeUrl.includes(route.routeName) ){
        routeStartPage = route.startingPage;
      }
  });
  return routeStartPage;
  }


}
