import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ContextService } from './context.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private cookieService: CookieService, private context:ContextService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.cookieService.get('kid');

    if (token && req.headers.get('Authorization') == undefined && req.url.indexOf('.plymouthrock.com') > -1) { // 
      
      let headers = req.headers;

      //let uuid = this.context.claimContext.user?.USER_UUID;
     // headers = headers.set("uuid", uuid ? uuid : '');

      headers = headers.set('Authorization', 'Bearer ' + token)
      const tokenizedReq = req.clone({ headers:  headers});

      return next.handle(tokenizedReq);
    }
    return next.handle(req);
  }
}
