import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';
import { ClaimCommonService } from 'src/app/services/claim-common.service';
import { LookupService } from 'src/app/services/lookup.service';
import { ContextService } from 'src/app/utils/context.service';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss']
})

export class VendorComponent implements OnInit{
  @BlockUI() blockUI!: NgBlockUI;

  // Yes/No Radio Button Options
  optionsYesNo = [{label: 'Yes', value: 'Y'}, {label: 'No', value: 'N'}];

  vendorTitle: string = "VendorTitle"; // Vendor Services Title
  vendorConfirmationHeader: string = "VendorConfirmationHeader"; // Vendor Confirmation Dialog Header
  vendorConfirmationYesButtonText: string = "VendorConfirmationYesButtonText"; // Vendor Confirmation Dialog Yes Button
  vendorConfirmationNoButtonText: string = "VendorConfirmationNoButtonText"; // Vendor Confirmation Dialog Yes Button
  waterMitigationOptInQuestion: string = "VendorWaterMitigation"; // Water Mitigation Opt In Question
  waterMitigationOptInNotes: string = "VendorWaterMitigation"; // Water Mitigation Opt In Notes
  waterMitigationVendorHeader: string = "VendorWaterMitigation"; // Water Mitigation Vendor Header
  waterMitigationPolicyHeader: string = "VendorWaterMitigation"; // Water Mitigation Policy Header
  waterMitigationVendorNotes: string = "VendorWaterMitigation"; // Water Mitigation Vendor Notes
  waterMitigationVendorNotFoundNotes: string = "VendorWaterMitigation"; // Water Mitigation Vendor Not Found Notes

  waterMitigationOptInNotesToggle: boolean = false; // Toggle to Show/Hide Water Mitigation Vendor Notes
  waterMitigationConfirmationToggle: boolean = false; // Toggle to Show/Hide Water Mitigation Confirmation Pop-Up

  userVendor: {
    categoryCode?: string, // Profile Category Code
    group?: { // Profile Group Filter
      companyCode?: string, // Profile Group Company Code
      routingChannelCode?: string, // Profile Group Routing Channel Code
      policyStateCode?: string, // Profile Group Policy State Code
      groupCode?: string // Profile Group Code
    },
    assignment?: { // Profile Assignment Filter
      companyCode?: string, // Profile Assignment Company Code
      routingChannelCode?: string, // Profile Assignment Routing Channel Code
      policyStateCode?: string, // Profile Assignment Policy State Code
      assignmentType?: string, // Profile Assignment Type
    },
    region?: { // Profile Region Filter
      companyCode?: string, // Profile Region Company Code
      lineOfBusinessCode?: string, // Profile Region Line of Business Code
      routingChannelCode?: string, // Profile Region Routing Channel Code
      policyStateCode?: string, // Profile Region Policy State Code
      stateCode?: string, // Profile Region State Code
      countyCode?: string, // Profile Region County Code
      zipCode?: string, // Profile Region Postal Zip Code
    },
    coverage?: { // Profile Coverage Filter
      companyCode?: string, // Profile Coverage Company Code
      lineOfBusinessCode?: string, // Profile Coverage Line of Business Code
      routingChannelCode?: string, // Profile Coverage Routing Channel Code
      policyStateCode?: string, // Profile Coverage Policy State Code
    },
    includeAddress?: boolean, // Include Claim System Address
    includeContact?: boolean, // Include Profile User Contact List
    includeGroup?: boolean, // Include Profile User Group List
    includeStreamAssociation?: boolean, // Include Claim System Party Id
    excludeInActiveAsNow?: boolean, // Exclude User Suspended in Profile
    includeOptions?: boolean, // Include Profile User Options List
    includePolicyType?: boolean, // Include Profile User Policy Type List
    includeCoverage?: boolean, // Include Profile User Coverage List
    claimSystemRegion?: string, // Claim System Region (required)
    deleteInd?: string
  } = {};

  claimType: string = ""; // Claim Type Code
  insCallerInd: string = "N"; // Is Insured Caller Indicator
  policyAddrLn1Txt: string = ""; // Policy Street Address
  policyCityName: string = ""; // Policy City Name
  policyStateCd: string = ""; // Policy State Code
  policyZipCode: string = ""; // Policy Zip Code
  userVendorList: any = []; // UI Vendor List
  selectedVendor: any; // Vendor Selection
  showVndrAddrInd: string = "N"; // Show Vendor Address Indicator

  waterMitigationEligibleInd: string = "Y"; // Water Mitigation Claim Eligible Indicator
  vendorWaterMitigationCount: string = "0"; // Total # of Water Mitigation Vendors
  
  vendorData : {
    waterMitigationOptInInd?: string, // Water Mitigation Claim Opt-In Indicator
  } = {};

  constructor(private lookupService: LookupService, 
    private claimCommonService: ClaimCommonService, 
    private contextService: ContextService,
    private router: Router){ }

  ngOnInit(): void {
    const claimId = this.contextService.claimContext.claimId;
    console.log("VendorComponent: claimId="+claimId);

    if(claimId){
      this.blockUI.start("Please wait...");

      this.claimType = this.contextService.claimContext.claimType;
      console.log("VendorComponent: claimType="+this.claimType);
      this.insCallerInd = this.contextService.claimContext.insCallerInd;
      console.log("VendorComponent: insCallerInd="+this.insCallerInd);

      this.claimCommonService.getVendorPageData(claimId).subscribe({
        next: (vendorPage: any) => {
          this.contextService.policyContext = vendorPage.claimPolicy;
    
          // Policy Address
          this.policyAddrLn1Txt = this.contextService.policyContext.policyAddrLn1Txt;
          this.policyCityName = this.contextService.policyContext.policyCityName;
          this.policyStateCd = this.contextService.policyContext.policyStateCd;
          this.policyZipCode = this.contextService.policyContext.policyZipCode;

          // Get Vendor Information
          if(this.claimType === "HOME"){
            this.userVendor.categoryCode = "VENDOR";
          }
          this.userVendor.includeAddress = true;
          this.userVendor.includeContact = true;
          this.userVendor.includeGroup = true;
          this.userVendor.includeStreamAssociation = true;
          this.userVendor.excludeInActiveAsNow = true;
          this.userVendor.includeOptions = false;
          this.userVendor.includePolicyType = false;
          this.userVendor.includeCoverage = true;
          this.userVendor.claimSystemRegion = "nj";
          this.userVendor.group = {};
          this.userVendor.group.companyCode = this.contextService.claimContext.companyCd;
          this.userVendor.group.routingChannelCode = this.contextService.policyContext.routingChannelCd;
          this.userVendor.group.policyStateCode = this.contextService.policyContext.policyStateCd;
          if(this.claimType === "HOME"){
            this.userVendor.group.groupCode = "[AP][PS]P"; // Appraiser or PSP
          }
          this.userVendor.assignment = {};
          this.userVendor.assignment.companyCode = this.contextService.claimContext.companyCd;
          this.userVendor.assignment.routingChannelCode = this.contextService.policyContext.routingChannelCd;
          this.userVendor.assignment.policyStateCode = this.contextService.policyContext.policyStateCd;
          this.userVendor.assignment.assignmentType = "EFNOL"; // eFNOL Assignments Only
          this.userVendor.region = {};
          this.userVendor.region.companyCode = this.contextService.claimContext.companyCd;
          this.userVendor.region.lineOfBusinessCode = this.contextService.claimContext.lobCd;
          this.userVendor.region.routingChannelCode = this.contextService.policyContext.routingChannelCd;
          this.userVendor.region.policyStateCode = this.contextService.policyContext.policyStateCd;
          this.userVendor.region.countyCode = this.contextService.policyContext.policySubdName;
          this.userVendor.region.stateCode = this.contextService.policyContext.policyStateCd;
          this.userVendor.region.zipCode = this.contextService.policyContext.policyZipCode.split("-")[0];
          console.log("VendorComponent: userVendor="+JSON.stringify(this.userVendor));

          this.claimCommonService.getProfileUserData(this.userVendor).subscribe({
            next: (vendorList: any) => {
              //this.contextService.claimContext.vendorList = vendorList;
              if(vendorList){
                //console.log("VendorComponent: vendorList="+JSON.stringify(vendorList));
                if(this.claimType === "HOME" && vendorList.users.length > 0){
                  this.vendorWaterMitigationCount = vendorList.users.length;
                  var idx: number = 0;
                  for(let user of vendorList.users){
                    var vendor: any = {};
                    vendor.userId = user.userId;
                    vendor.vndrClmPartyId = user.streamAssociations[0].streamId;
                    vendor.fullName = user.fullName;
                    if(user.addresses){
                      vendor.addressLine = user.addresses[0].addressLine1;
                      if(user.addresses[0].addressLine2){
                        vendor.addressLine += " " + user.addresses[0].addressLine2;
                      }
                      vendor.city = user.addresses[0].city;
                      vendor.stateCode = user.addresses[0].stateCode;
                      vendor.zipCode = user.addresses[0].zipCode;
                    }
                    if(user.phoneNumber){
                      vendor.phoneNumber = user.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
                    }
                    this.userVendorList[idx] = vendor;
                    idx += 1;
                  }
                  console.log("VendorComponent: userVendorList="+JSON.stringify(this.userVendorList));
                }
              }
              this.blockUI.stop();
            },
            error: err => {
              console.log("claimCommonService.getVendorData failed", err);
              this.blockUI.stop();
              this.router.navigate(["/error"]);
            }
          });
          //this.blockUI.stop();
        },
        error: err => {
          console.log("claimCommonService.getClaimPolicyData failed", err);
          this.blockUI.stop();
          this.router.navigate(["/error"]);
        }
      });
      // Water Mitigation Scripts
      if(this.claimType === "HOME"){
        this.lookupService.getScripts("VendorTitle", this.contextService.claimContext.companyCd, 1).subscribe(response => {
          this.vendorTitle = response;
        });
        this.lookupService.getScripts("VendorWaterMitigation", this.contextService.claimContext.companyCd, 1).subscribe(response => {
          this.waterMitigationOptInQuestion = response;
        });
        this.lookupService.getScripts("VendorWaterMitigation", this.contextService.claimContext.companyCd, 2).subscribe(response => {
          this.waterMitigationOptInNotes = response;
        });
        this.lookupService.getScripts("VendorWaterMitigation", this.contextService.claimContext.companyCd, 3).subscribe(response => {
          this.waterMitigationVendorHeader = response;
        });
        this.lookupService.getScripts("VendorWaterMitigation", this.contextService.claimContext.companyCd, 4).subscribe(response => {
          this.waterMitigationPolicyHeader = response;
        });
        this.lookupService.getScripts("VendorWaterMitigation", this.contextService.claimContext.companyCd, 5).subscribe(response => {
          this.waterMitigationVendorNotes = response;
        });
        this.lookupService.getScripts("VendorWaterMitigation", this.contextService.claimContext.companyCd, 6).subscribe(response => {
          this.waterMitigationVendorNotFoundNotes = response;
        });
        this.lookupService.getScripts("VendorConfirmationHeader", this.contextService.claimContext.companyCd, 1).subscribe(response => {
          this.vendorConfirmationHeader = response;
        });
        this.lookupService.getScripts("VendorConfirmationYesButtonText", this.contextService.claimContext.companyCd, 1).subscribe(response => {
          this.vendorConfirmationYesButtonText = response;
        });
        this.lookupService.getScripts("VendorConfirmationNoButtonText", this.contextService.claimContext.companyCd, 1).subscribe(response => {
          this.vendorConfirmationNoButtonText = response;
        });
      }
    }
  }

  toggleWaterMitigationOptInNotes(waterMitigationOptInInd?: string){
    if(waterMitigationOptInInd === "Y"){
      if(!this.waterMitigationOptInNotesToggle){
        this.waterMitigationOptInNotesToggle = !this.waterMitigationOptInNotesToggle;
      }
    }else if(waterMitigationOptInInd === "N"){
      if(this.waterMitigationOptInNotesToggle){
        this.waterMitigationOptInNotesToggle = !this.waterMitigationOptInNotesToggle;
      }
    }
  }

  toggleVendorConfirmationDialog(idx?: number){
    this.waterMitigationConfirmationToggle = !this.waterMitigationConfirmationToggle;
    if(this.waterMitigationConfirmationToggle === true){
      this.selectedVendor = this.userVendorList.filter((vendor: any, index: any) =>
        index === idx
      )[0];
      console.log("VendorComponent: vendor="+JSON.stringify(this.selectedVendor));
    }
  }

  submitVendorPage(vndrClmPartyId?: string){
    this.blockUI.start("Please wait...");
    console.log("VendorComponent: vendorData="+JSON.stringify(this.vendorData));
    
    const submitVendorPage: any = {};

    // Home Automation
    if(this.contextService.claimContext.claimType === "HOME"){
      let homeAutomate: any = {};
      homeAutomate.claimId = this.contextService.claimContext.claimId;
      // Water Mitigation Preferences
      if(this.waterMitigationEligibleInd === "Y"){
        homeAutomate.waterMitigationOptInInd = this.vendorData.waterMitigationOptInInd;
        if(this.vendorWaterMitigationCount === "0"){
          homeAutomate.waterMitigationVndrNotFndInd = "Y";
        }else{
          homeAutomate.waterMitigationVndrNotFndInd = "N";
        }
        if(vndrClmPartyId === undefined){
          homeAutomate.waterMitigationVndrClmPartyId = "";
          homeAutomate.waterMitigationOptInInd = "N";
        }else{
          homeAutomate.waterMitigationVndrClmPartyId = vndrClmPartyId;
        }
      }else if(this.waterMitigationEligibleInd === "N"){
        homeAutomate.waterMitigationOptInInd = "";
        homeAutomate.waterMitigationVndrClmPartyId = "";
        homeAutomate.waterMitigationVndrNotFndInd = "";
      }
      submitVendorPage.homeAutomate = homeAutomate;
    }
    
    console.log("VendorComponent: submitVendorPage="+JSON.stringify(submitVendorPage));
    this.claimCommonService.saveVendorPageData(submitVendorPage).subscribe({
      next: () => {
        this.blockUI.stop();
        this.router.navigate(['/assignment']);
      },
      error: err => {
        console.log("claimCommonService.saveVendorData failed", err);
        this.blockUI.stop();
        //this.router.navigate(["/error"]);
      }
    });
  }

  goBack(){
    this.router.navigate(['/preference']);
  }
}