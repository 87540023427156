import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DisableCacheInterceptorService implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   const nextReq = req.clone({
  //     headers: req.headers.set('Cache-Control', 'no-cache, no-store, must-revalidate')
  //     .set('Pragma', 'no-cache')
  //     .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
  //     .set('If-Modified-Since', '0')
  // });

  return next.handle(req);
  }
}
