import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Observable, of, throwError } from 'rxjs';
import { UtilService } from './util.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })
export class ExceptionService {

    
  @BlockUI() blockUI!: NgBlockUI;

 constructor(private router: Router, private util:UtilService) { }

    handleError: (errorResponse: any) => Observable<any> = (errorResponse: any) => {
        let errorMsg: string;
        let serverErrorMsg: string;
        if ( errorResponse ) {
            
            serverErrorMsg = `${errorResponse.status} - ${errorResponse.message || ''} ${JSON.stringify(errorResponse.error)}`;
            errorMsg = `We are not able to process your request.  
                         Please try again later or call your Claim Representative directly.  
                         We apologize for the inconvenience. 
                         Error :  ${errorResponse.status} - ${errorResponse.message} ` ;            

            this.util.showException((environment.envName == 'prod' || environment.envName == 'mod' ) ? errorMsg : serverErrorMsg);

            if(this.router.url.indexOf('/login') > -1){
                return throwError( () => new Error(serverErrorMsg));
            }

            if (errorResponse.status === 401) {
                console.error(`ExceptionService.handleError() => ${errorResponse}`);
                 //this.router.navigate(['/unauthorize']);
                 //If user dont have oauth Token and gets 401, then redirect to efnol login page
                 (<any>window)['toOldEfnol'] = true;
                 window.location.href = (environment.efnol1URL+'login/');
                 this.blockUI.stop();
                 return of(null);
            }
            if (errorResponse.status === 500) {
                console.error(`ExceptionService.handleError() => ${errorResponse}`);
                //  this.router.navigate(['/error']);
                //  this.blockUI.stop();
                //  return of(null);
            }            

            // const error: string = errJson.error || JSON.stringify(errJson);

        }else {
            errorMsg = errorResponse.error ? errorResponse.error.error_description : errorResponse;
            serverErrorMsg = errorMsg;

            this.util.showException(serverErrorMsg);
        }
        console.error(`ExceptionService.handleError() => ${serverErrorMsg}`);

       return throwError(() => new Error(serverErrorMsg));
    }
}
