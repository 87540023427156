import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { DynamicConfigService } from '../../formly/services/dynamic-config.service';
import { JSONPath as jp} from 'jsonpath-plus';

import jsonata from 'jsonata';
import { DynamicBasePageComponent } from '../../formly/dynamic-base-page/dynamic-base-page.component';
import { ContextService } from 'src/app/utils/context.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-loss',
  templateUrl: './home-loss.component.html',
  styleUrls: ['./home-loss.component.scss']
})
export class HomeLossComponent extends DynamicBasePageComponent implements OnInit {


  //If pageConfig is the starting page, then on back , redirect to efnol page.
  override previousPage(): void {
    if(this.pageConfig && this.pageConfig.key == this.dynamicContext.getStartingPage()){
      (<any>window)['toOldEfnol'] = true;
      window.location.href = (environment.efnol1URL+'lossincident/'+this.context.claimContext['claimId']+'/');
      
    }else{
      super.previousPage();
    }
  }

}
