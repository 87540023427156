<div >
    <block-ui>
        <div>
        <div class="container   efnol-container" >
            <app-header class="sticky-top d-block" style="top:-15px"></app-header>
            <div class="grid main-body">
                <!-- <div class="col-12"> -->
                <div class="col-12" *ngIf="showRoute">
                    <router-outlet ></router-outlet>
                </div>
            </div>
            <app-footer></app-footer>
        </div>
    </div>
    </block-ui>
</div>

<!-- This component manage's user Idel activity and KID-->
<app-user-session></app-user-session>

<p-toast class="custom-toast" key="app" life="5000">
</p-toast>


<p-confirmDialog [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}" header="Confirmation"
    icon="pi pi-check-circle"></p-confirmDialog>